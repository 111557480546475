import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import ContentChecker from '../../../shared/common/ContentChecker'
import VoucherForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { showAlertFunc } from '../../../functions/showAlert';
import { setPage } from '../../../redux/actions/page'
import { getVoucherDetail, updateVoucher } from '../../../redux/actions/voucher'
import { getAllOffers } from '../../../redux/actions/offer'
import { initialState } from './State'

const EditVoucher = ({
    getVoucherDetail, updateVoucher, getAllOffers, setPage,
    voucher: { voucherDetail },
    offer: { offersList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let id = window.location.pathname.split('/')[2]

    useEffect(() => {
        getAllOffers()
        if (id) {
            getVoucherDetail(id)
        }
    }, [getAllOffers, getVoucherDetail, id])

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (voucherDetail) {
            setFormData({
                ...formData,
                id: voucherDetail?.id,
                name: voucherDetail?.name,
                code: voucherDetail?.code,
                start_datetime: new Date(voucherDetail?.start_datetime),
                end_datetime: new Date(voucherDetail?.end_datetime),
                offers: voucherDetail?.offers,
                usage: voucherDetail?.usage,
            })
        }
        // eslint-disable-next-line
    }, [voucherDetail])

    const onSubmit = e => {
        e.preventDefault()
        formData.start_datetime = new Date(formData.start_datetime)
        formData.end_datetime = new Date(formData.end_datetime)

        updateVoucher(formData, voucherDetail?.id, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Voucher updated successfully`, { type: "success" })
                }
            })

    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='voucher' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {offersList ?
                    (formData?.id && id && voucherDetail) ?
                        <VoucherForm
                            formData={formData}
                            setFormData={setFormData}
                            onSubmit={onSubmit}
                        />
                        :
                        <ContentChecker reducerName={voucherDetail} />
                    :
                    <ContentChecker reducerName={offersList} />
                }
                {offersList === 0 && showAlertFunc('Do add an offer before updating a voucher', history, setPage, 'offer')}
            </Container>
        </Container>
    )
}

EditVoucher.propTypes = {
    getVoucherDetail: PropTypes.func.isRequired,
    updateVoucher: PropTypes.func.isRequired,
    getAllOffers: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    voucher: PropTypes.object.isRequired,
    offer: PropTypes.object.isRequired,

};

export default connect(mapStateToProps, { getVoucherDetail, updateVoucher, getAllOffers, setPage, })(EditVoucher)