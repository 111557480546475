import {
    RANGE_FOUND, RANGE_NOT_FOUND,
    GET_RANGE, REMOVE_RANGE,
    DELETE_RANGE,
} from '../types'

const initialState = {
    rangesList: null,
    rangesCount: null,
    rangeDetail: null,
}

const range = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case RANGE_FOUND:
            return {
                ...state,
                rangesList: payload.rangesList,
                rangesCount: payload.rangesCount,
                rangeDetail: null,
            }
        case RANGE_NOT_FOUND:
            return {
                ...state,
                rangesList: null,
                rangesCount: 0
            }
        case DELETE_RANGE:
            let updatedList = state.rangesList.filter((item) => item.id !== payload).map(items => items);
            let count = updatedList?.length
            return {
                ...state,
                rangesList: updatedList,
                rangesCount: count,
                rangeDetail: null,
            }
        case GET_RANGE:
            return {
                ...state,
                rangeDetail: payload
            }
        case REMOVE_RANGE:
            return {
                ...state,
                rangeDetail: null
            }
        default:
            return state
    }
}

export default range