import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import ContentChecker from '../../../shared/common/ContentChecker'
import BenefitForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { navigate } from '../../../functions/navigate'
import { showAlertFunc } from '../../../functions/showAlert';
import { setPage } from '../../../redux/actions/page'
import { getAllRanges } from '../../../redux/actions/range'
import { addBenefit } from '../../../redux/actions/benefit'
import { initialState } from './State'

const Create = ({
    addBenefit, getAllRanges, setPage,
    benefit: { benefitDetail },
    range: { rangesList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();

    useEffect(() => {
        getAllRanges(1)
    }, [getAllRanges])

    const [formData, setFormData] = useState(initialState)

    const onSubmit = e => {
        e.preventDefault()

        addBenefit(formData)
            .then((success) => {
                if (success) {
                    alert.show(`Benefit added successfully`, { type: "success" })
                    navigate(history, setPage, 'benefits')
                }
                else {
                    alert.show('Cannot create benefit. Please try again later', { type: "error" })
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='benefit' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {rangesList ?
                    <BenefitForm
                        formData={formData}
                        setFormData={setFormData}
                        onSubmit={onSubmit}
                    />
                    :
                    <ContentChecker reducerName={rangesList} />
                }
                {rangesList === 0 && showAlertFunc('Do add range before adding benefits', history, setPage, 'range')}
            </Container>
        </Container>
    )
}

Create.propTypes = {
    addBenefit: PropTypes.func.isRequired,
    getAllRanges: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    benefit: PropTypes.object.isRequired,
    range: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getAllRanges, addBenefit, setPage })(Create)