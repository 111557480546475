import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core"
import SelectField from "../../../components/material/inputFields/SelectField";
import InputField from '../../../components/material/inputFields/InputField'
import Buttons from '../../../components/common/Buttons'
import { onChange } from '../../../functions/onChange'
import { navigate } from '../../../functions/navigate'
import { setPage } from '../../../redux/actions/page'

const ConditionForm = ({
    formData, setFormData, onSubmit, setPage,
    range: { rangesList },
}) => {
    const history = useHistory()

    const onCancel = () => {
        navigate(history, setPage, 'conditions')
    }

    let opt1 = 'Depends on number of items in basket that are in condition range'
    let opt2 = 'Depends on value of items in basket that are in condition range'
    let opt3 = 'Need to contain a set number of DISTINCT items for the condition range'


    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='value'
                        label='Value'
                        value={formData?.value}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='text'
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SelectField
                        name='type'
                        label='Type'
                        value={formData?.type}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={[
                            { id: 'Count', name: opt1 },
                            { id: 'Value', name: opt2 },
                            { id: 'Coverage', name: opt3 },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectField
                        name='range'
                        label='Range'
                        value={formData?.range}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={rangesList}
                    />
                </Grid>
            </Grid>
            <br />
            <Buttons onCancel={onCancel} />

        </form>
    )
}

ConditionForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    range: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(ConditionForm)