export const onChange = (e, formData, setFormData) => {
    if (e.target.checked) {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    }
    else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
}

export const filterById = (newValue, reducer) => {
    let dict = []
    reducer && reducer.filter((item) => {
        if (newValue?.includes(item.name ? item.name : item.title)) {
            dict.push(item.id)
        }
        return dict
    })
    return dict
}