import {
    OFFERS_FOUND, OFFERS_NOT_FOUND,
    GET_OFFER, REMOVE_OFFER,
    DELETE_OFFER
} from '../types'

const initialState = {
    offersList: null,
    offersCount: 0,
    offerDetail: null,
}

const offer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case OFFERS_FOUND:
            return {
                ...state,
                offersList: payload.offersList,
                offersCount: payload.offersCount,
                offerDetail: null,
            }
        case OFFERS_NOT_FOUND:
            return {
                ...state,
                offersList: null,
                offersCount: 0
            }
        case DELETE_OFFER:
            let updatedList = state.offersList.filter((item) => item.id !== payload).map(items => items);
            let count = updatedList?.length
            return {
                ...state,
                offersList: updatedList,
                offersCount: count,
                offerDetail: null,
            }
        case GET_OFFER:
            return {
                ...state,
                offerDetail: payload
            }
        case REMOVE_OFFER:
            return {
                ...state,
                offerDetail: null
            }
        default:
            return state
    }
}

export default offer