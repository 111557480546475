import React from "react"
import {
    Tooltip, Typography, Divider, Box, Grid, Table, TableHead, TableBody, TableRow
} from "@material-ui/core/"
import { AccountCircle, Email, Phone, Room } from "@material-ui/icons/"
import { useStyles, TableCell } from "../../assets/jss/pages/order"

const CustomerDetails = (props) => {
    const classes = useStyles()
    const { orderDetail } = props

    const info = [
        { icon: <AccountCircle className={classes.icon} />, head: 'Name', value: orderDetail?.full_name },
        {
            icon: <Email className={classes.icon} />,
            head: 'Email',
            value: orderDetail?.email?.toString()?.length > 15 ? orderDetail?.email?.substr(0, 15) + '...' : orderDetail?.email,
            tooltip: orderDetail?.email
        },
        { icon: <Phone className={classes.icon} />, head: 'Phone', value: orderDetail?.phone_number },
        { icon: <Room className={classes.icon} />, head: 'Post Code', value: orderDetail?.postcode },
    ]

    return (
        <>
            <Grid container spacing={2} className={classes.content}>
                {info && info.map((item, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Box className={classes.header}>
                            <div className={classes.iconBox}>
                                {item.icon}
                            </div>
                            <div>
                                <Typography className={classes.head}>{item.head}</Typography>
                                <Tooltip title={item.tooltip ? item.tooltip : item.value}>
                                    <Typography className={classes.value}>{item.value}</Typography>
                                </Tooltip>

                            </div>
                        </Box>
                    </Grid>
                ))}

                <Grid item xs={12} sm={12}>
                    <Box className={classes.header}>
                        <div className={classes.iconBox}>
                            <Room className={classes.icon} />
                        </div>
                        <div>
                            <Typography className={classes.head}>Address</Typography>
                            <Typography className={classes.value}>{orderDetail && orderDetail.shipping_address && orderDetail.shipping_address.place}</Typography>
                        </div>
                    </Box>
                </Grid>

            </Grid>
            <Table>
                <TableHead >
                    <TableRow className={classes.tableHeader}>
                        <TableCell className={classes.cell}><b>Qty</b></TableCell>
                        <TableCell className={classes.cell}><b>Product</b></TableCell>
                        <TableCell className={classes.cell} align="right"><b>Price</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderDetail && orderDetail.lines.map((line, index) => (
                        <TableRow key={index}>
                            <TableCell>{line.quantity}</TableCell>
                            <TableCell>{line.product.title}</TableCell>
                            <TableCell align="right">£{line.price_incl_tax}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Divider className={classes.divider} />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell rowSpan={3} />
                        <TableCell colSpan={1} align="right">Subtotal</TableCell>
                        <TableCell align="right">{orderDetail && orderDetail.subtotal}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="right">Delivery</TableCell>
                        <TableCell align="right">{orderDetail && orderDetail.shipping_incl_tax}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="right"><b>Total</b></TableCell>
                        <TableCell align="right" style={{ color: '#0D94BC' }}><b>{orderDetail && orderDetail.total_incl_tax}</b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    )
}

export default CustomerDetails