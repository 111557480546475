import {
    GET_STATS, REMOVE_STATS, GET_TOP_PRODUCTS, REMOVE_TOP_PRODUCTS, GET_WEEKLY_ORDERS
} from '../types'

const initialState = {
    stats: null,
    topProducts: null,
    weeklyOrders: null
}

const stats = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_STATS:
            return {
                ...state,
                stats: payload
            }
        case REMOVE_STATS:
            return {
                ...state,
                stats: null
            }
        case GET_TOP_PRODUCTS:
            return {
                ...state,
                topProducts: payload
            }
        case REMOVE_TOP_PRODUCTS:
            return {
                ...state,
                topProducts: null
            }
        case GET_WEEKLY_ORDERS:
            return {
                ...state,
                weeklyOrders: payload
            }
        default:
            return state
    }
}

export default stats