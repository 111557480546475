import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useAlert } from "react-alert";
import { Paper, Grid, Box, Button, Typography } from '@material-ui/core'
import { Person, Lock, CheckCircleOutline } from '@material-ui/icons'
import { mapStateToProps } from '../shared/common/MapStateToProps'
import { signin } from '../redux/actions/auth'
import { onChange } from '../functions/onChange'
import { loader, LoadingScreen } from '../shared/common/LoadingScreen'
import TextField from '../components/material/inputFields/CustomTextField'
import useStyles from '../assets/jss/layouts/signin'
import logo from '../assets/images/logo2.png'

const Signin = ({ signin, auth, error: { errors } }) => {
    const classes = useStyles()
    const alert = useAlert();
    const timer = useRef()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const { username, password } = formData;

    const onSubmit = async e => {
        e.preventDefault()
        loader(loading, setLoading, timer)
        signin({ username, password, alert }).then((success) => {
            if (success) {
                alert.show("Logged in successfully.", { type: "success" });
                setTimeout(() => { window.location = "/" }, 1000);
            } else {
                if (!errors) {
                    alert.show("Invalid credentials", { type: "error" });
                }
            }
        });
    };

    const fields = [
        { name: 'username', id: 'username', placeholder: 'username', type: 'text', icon: <Person /> },
        { name: 'password', id: 'password', placeholder: 'password', type: 'password', icon: <Lock /> }
    ]

    if (auth && auth.isAuthenticated) {
        window.location = "/";
        return <></>;
    }

    return (
        <Grid container>
            {loading && LoadingScreen()}
            <Grid item xs={false} sm={5} md={6}>
                <div className={classes.bg}>
                    <img src={logo} alt='logo' className={classes.logo} />
                </div>
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
                <Box className={classes.box}>
                    <div className={classes.form}>
                        <h1 className={classes.header}>Welcome Back!</h1>
                        <h4 className={classes.subHeader}>Login to your account</h4>
                        <br />
                        <form onSubmit={e => onSubmit(e)} className={classes.body}>
                            {fields && fields.map((field, index) => (
                                <Paper className={classes.paper} key={index}>
                                    <TextField
                                        icon={field.icon}
                                        type={field.type}
                                        name={field.name}
                                        id={field.id}
                                        data-testid={field.name}
                                        placeholder={field.placeholder}
                                        onChange={e => onChange(e, formData, setFormData)}
                                    />
                                </Paper>
                            ))}
                            <br />
                            <Box className={classes.subBody}>
                                <div className={classes.extra}>
                                    <CheckCircleOutline className={classes.icon} />
                                    <Typography className={classes.blackLabel}>Remember me</Typography>
                                </div>
                                <a href='/forgot-password' className={classes.label}>Forgot Password?</a>
                            </Box>
                            <br />
                            <Button
                                type="submit"
                                variant="contained"
                                className={classes.submit}
                            >
                                Login
                            </Button>
                        </form>
                    </div>
                </Box>
            </Grid>
        </Grid>
    )
}

Signin.propTypes = {
    signin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { signin })(Signin)