import { makeStyles } from '@material-ui/core'
import { primaryColor, dangerColor, whiteColor, themeBackground, borderColor } from '../colors'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    container: {
        background: themeBackground,
        marginTop: theme.spacing(1),
        borderRadius: '8px',
        border: `1px solid ${borderColor[1]}`,
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },
    primaryText: {
        color: primaryColor
    },
    cancel: {
        justifyContent: 'flex-end',
        alignSelf: 'center',
        color: dangerColor,
        marginTop: theme.spacing(2.5),
        "&:hover": {
            background: whiteColor,
            color: dangerColor,
            opacity: 0.7,
            cursor: 'pointer'
        },
    },
    alertIcon: {
        color: dangerColor,
        fontSize: '3rem'
    },
    edit: {
        color: primaryColor,
    },
    delete: {
        color: dangerColor
    }
}))

export default useStyles 
