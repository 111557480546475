import React, { useState } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { Container } from "@material-ui/core/"
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import CategoryForm from './Form'
import CrudHeader from '../../components/common/CrudHeader'
import { navigate } from '../../functions/navigate'
import { useStyles } from "../../assets/jss/pages/categories"
import { addCategory } from "../../redux/actions/category";
import { setPage } from '../../redux/actions/page'
import { initialState } from "./State";

const CreateCategory = ({
    addCategory, setPage,
    auth: { me }
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let partnerUrl = me && me.url

    const [formData, setFormData] = useState(initialState)

    const onSubmit = e => {
        e.preventDefault()
        formData.partner = partnerUrl
        addCategory(formData)
            .then((success) => {
                if (success) {
                    alert.show('Category added successfully', { type: "success" })
                    navigate(history, setPage, 'categories')
                }
                else {
                    alert.show('New category creation failed. Please try again later', { type: "error" })
                }
            });
    }


    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='categories' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                <CategoryForm
                    formData={formData}
                    setFormData={setFormData}
                    onSubmit={onSubmit}
                />
            </Container>
        </Container>
    )
}

CreateCategory.propTypes = {
    addCategory: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { addCategory, setPage })(CreateCategory)