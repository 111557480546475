import React, { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { Container } from "@material-ui/core/"
import { MoreVert, Edit, Delete } from "@material-ui/icons/"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import ContentChecker from '../../../shared/common/ContentChecker'
import useStyles from '../../../assets/jss/pages/voucher'
import Header from '../../../components/common/Header'
import { formNavigation } from '../../../functions/navigate'
import CustomTable from "../../../components/material/table/Table";
import CustomMenu from '../../../components/material/menu/Menu'
import { loader } from "../../../shared/common/LoadingScreen";
import { deleteFunc } from '../../../functions/delete'
import { setPage } from "../../../redux/actions/page";
import { getConditionsList, deleteCondition } from '../../../redux/actions/condition'

const ListConditions = ({
    getConditionsList, deleteCondition, setPage,
    condition: { conditionsList, conditionsCount }
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const timer = useRef();
    let limit = 20
    let count = conditionsCount ? conditionsCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [loading, setLoading] = useState(false)

    const [disableClick, setDisableClick] = useState(false);

    useEffect(() => {
        getConditionsList(1)
    }, [getConditionsList]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getConditionsList(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header
                name='conditions' nav='condition'
                title='Make sure RANGE exists before creating a CONDITION.'
            />
            {conditionsList ?
                <CustomTable
                    onClick={disableClick === false ? 'condition' : false}
                    tableHead={['Number', 'Type', 'Value', 'Actions']}
                    tableData={conditionsList.map(condition => (
                        {
                            cells: [
                                condition.id,
                                condition.type,
                                condition.value,
                                <CustomMenu
                                    stateSetter={setDisableClick}
                                    button={<MoreVert />}
                                    menuItems={[
                                        {
                                            name: <Edit className={classes.edit} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                formNavigation(history, setPage, 'condition', condition.id, 'edit')
                                            }
                                        },
                                        {
                                            name: <Delete className={classes.delete} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                deleteFunc(deleteCondition, condition.id, alert)
                                            }
                                        }
                                    ]}
                                />
                            ],
                            uid: condition.id
                        }
                    ))}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={conditionsList} />
            }
        </Container>
    )
}

ListConditions.propTypes = {
    getConditionsList: PropTypes.func.isRequired,
    deleteCondition: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getConditionsList, deleteCondition, setPage })(ListConditions)