import React, { useState } from "react"
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Tooltip, Button } from "@material-ui/core/"
import { MoreVert, Add, Edit, Delete, DragHandle } from "@material-ui/icons/"
import { useStyles } from "../../../assets/jss/pages/modifiers"
import CustomTable from "../../../components/material/table/SortableTable";
import CustomMenu from '../../../components/material/menu/Menu'
import { deleteFunc } from '../../../functions/delete'
import { formNavigation } from '../../../functions/navigate'
import {
    getModifierGroup, deleteModifierOption, sortModifierOptions,
} from "../../../redux/actions/modifier"
import { setPage } from "../../../redux/actions/page";

const ListModifiers = ({
    getModifierGroup, deleteModifierOption, sortModifierOptions, setPage,
    modifier: { modifierGroup },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let { uid } = useParams();

    const [disableClick, setDisableClick] = useState(false);

    const sortModifierOption = (data) => {
        sortModifierOptions(data)
    }

    return (
        <CustomTable
            onClick={disableClick === false ? 'modifier-options-edit' : false}
            tableHead={[
                { size: 1, name: <DragHandle /> },
                { size: 1, name: 'Number' },
                { size: 4, name: 'Options' },
                { size: 2, name: 'Price' },
                { size: 2, name: 'Actions' },
                {
                    size: 2,
                    name:
                        <Button onClick={() => formNavigation(history, setPage, 'modifier-group', modifierGroup?.uid, 'add', 'options')}>
                            <Tooltip title="Add Modifier Option"><Add className={classes.edit} /></Tooltip>
                        </Button>
                }

            ]}
            tableData={modifierGroup?.modifiers?.map(modifier => (
                {
                    id: modifier.id,
                    group: modifierGroup?.uid,
                    cells: [
                        { size: 1, name: modifier.id },
                        { size: 4, name: modifier.name },
                        { size: 2, name: modifier.price },
                        {
                            size: 2, name: <CustomMenu
                                stateSetter={setDisableClick}
                                button={<MoreVert />}
                                menuItems={[
                                    {
                                        name: <Tooltip title="Edit Modifier"><Edit className={classes.edit} /></Tooltip>,
                                        handleItemClick: (event) => {
                                            event.stopPropagation()
                                            formNavigation(history, setPage, 'modifier-group', modifierGroup?.uid, 'edit', 'options', modifier.uid)
                                        }
                                    },
                                    {
                                        name: <Tooltip title="Delete Modifier"><Delete className={classes.delete} /></Tooltip>,
                                        handleItemClick: (event) => {
                                            event.stopPropagation()
                                            deleteFunc(deleteModifierOption, modifier.uid, alert, getModifierGroup, uid)
                                        }
                                    }
                                ]}
                            />
                        },
                        { size: 2, name: ' ' },
                    ]
                }
            ))}
            sort={sortModifierOption}
        />
    )
}

ListModifiers.propTypes = {
    getModifierGroup: PropTypes.func.isRequired,
    deleteModifierOption: PropTypes.func.isRequired,
    sortModifierOptions: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    modifier: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    getModifierGroup, deleteModifierOption, sortModifierOptions, setPage
})(ListModifiers)