import { makeStyles } from '@material-ui/core'
import { dangerColor, whiteColor, themeBackground, borderColor, primaryColor, warningColor } from '../colors'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    container: {
        background: themeBackground,
        marginTop: theme.spacing(1),
        borderRadius: '8px',
        border: `1px solid ${borderColor[1]}`,
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },
    cancel: {
        justifyContent: 'flex-end',
        alignSelf: 'center',
        color: dangerColor,
        marginTop: theme.spacing(2.5),
        "&:hover": {
            background: whiteColor,
            color: dangerColor,
            opacity: 0.7,
            cursor: 'pointer'
        },
    },
    align: {
        justifyContent: 'flex-start',
        alignSelf: 'center',
        fontSize: '14px'
    },
    align2: {
        justifyContent: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        verticalAlign: 'baseline',
    },
    img: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: theme.spacing(3)
    },
    add: {
        color: warningColor
    },
    edit: {
        color: primaryColor,
    },
    delete: {
        color: dangerColor
    }
}))

export { useStyles }
