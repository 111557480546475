import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const CustomMenu = ({ button, menuItems, stateSetter }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        stateSetter(true)
        if (anchorEl !== null) {
            setAnchorEl(null);
        }
        else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        stateSetter(false)
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true"
                onClick={(event) => {
                    event.stopPropagation()
                    handleClick(event)
                }}
            >
                {button}
            </Button>
            <Menu
                style={{ marginTop: '2rem', justifyContent: 'center', alignItems: 'center' }}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems && menuItems.map((item, index) => (
                    <MenuItem onClick={item.handleItemClick} key={index}><div onClick={handleClose}>{item.name}</div></MenuItem>
                ))}
            </Menu>
        </div >
    );
}

export default CustomMenu