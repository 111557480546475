import React, { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Container, Tooltip, Chip, Typography } from "@material-ui/core/"
import { MoreVert, Update, Edit, Delete } from "@material-ui/icons/"
import ContentChecker from '../../shared/common/ContentChecker'
import { loader } from "../../shared/common/LoadingScreen";
import CustomTable from "../../components/material/table/Table";
import CustomMenu from '../../components/material/menu/Menu'
import Header from '../../components/common/Header'
import { formNavigation } from '../../functions/navigate'
import { useStyles } from "../../assets/jss/pages/products"
import { deleteFunc } from '../../functions/delete'
import { getList } from '../../functions/search'
import { getFilteredProducts, getProductsList, updateProductStatus, deleteProduct } from "../../redux/actions/product";
import { setPage } from "../../redux/actions/page";

const ListProducts = ({
    getProductsList, updateProductStatus, getFilteredProducts, setPage, deleteProduct,
    product: { productsList, productsCount, filteredProduct },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const timer = useRef();
    let limit = 20
    let count = productsCount ? productsCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [loading, setLoading] = useState(false)
    const [searchProduct, setSearchProduct] = useState('');
    let currentList = searchProduct ? filteredProduct : productsList

    const [disableClick, setDisableClick] = useState(false);

    useEffect(() => {
        getList(searchProduct, getFilteredProducts, getProductsList)
    }, [getFilteredProducts, getProductsList, searchProduct]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getProductsList(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    const updateStatus = (uid, status) => {
        updateProductStatus(uid, status)
            .then((success) => {
                if (success) {
                    alert.show('Product status updated', { type: "success" })
                    getProductsList()
                }
                else {
                    alert.show('Cannot update the product status. Please try again later', { type: "error" })
                }
            })
    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header doSeacrch={true} search={searchProduct} setSearch={setSearchProduct} name='products' nav='product' />
            {currentList ?
                <CustomTable
                    onClick={disableClick === false ? 'product' : false}
                    tableHead={['Number', 'Seq.', 'Name', 'Price', 'Status', 'Actions']}
                    tableData={currentList.map(product => (
                        {
                            uid: product.uid,
                            cells: [
                                product.id,
                                product.sequence,
                                <Typography className={classes.align}>
                                    {product.images && product.images[0] && <img src={product.images && product.images[0] && product.images[0].original} alt='product' className={classes.img} />}
                                    <span className={classes.align2}>{product.title}</span>
                                </Typography>,
                                product.price,
                                <Chip style={{
                                    width: '40% ',
                                    background: 'white',
                                    color: product.available ? "#00C980" : "#EE0C2B",
                                    border: `1px solid ${product.available ? "#00C980" : "#EE0C2B"}`
                                }}
                                    label={product.available ? 'Active' : 'Block'}
                                />,
                                <CustomMenu
                                    stateSetter={setDisableClick}
                                    button={<MoreVert />}
                                    menuItems={[
                                        {
                                            name: <Tooltip title="Update Status"><Update className={classes.add} /></Tooltip>,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                updateStatus(product.uid, product.available)
                                            }
                                        },
                                        {
                                            name: <Tooltip title="Edit Product"><Edit className={classes.edit} /></Tooltip>,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                formNavigation(history, setPage, 'product', product.uid, 'edit')
                                            }
                                        },
                                        {
                                            name: <Tooltip title="Delete Product"><Delete className={classes.delete} /></Tooltip>,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                deleteFunc(deleteProduct, product.uid, alert)
                                            }
                                        }
                                    ]}
                                />
                            ],
                        }
                    ))}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    search={searchProduct}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={searchProduct.length > 0 ? filteredProduct : productsList} />
            }
        </Container>
    )
}

ListProducts.propTypes = {
    getProductsList: PropTypes.func.isRequired,
    getFilteredProducts: PropTypes.func.isRequired,
    updateProductStatus: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getProductsList, updateProductStatus, getFilteredProducts, deleteProduct, setPage })(ListProducts)