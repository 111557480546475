import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Paper, TextField, Grid, Box } from '@material-ui/core'
import useStyles from '../../../assets/jss/components/material/textFields'

const CustomTextField = ({
    name, value, onChange, type, placeholder, icon, id,
    page: { loadedPage },
    auth: { isAuthenticated }
}) => {
    const classes = useStyles()

    return (
        loadedPage === 'signin' || isAuthenticated === false ?
            <Grid container spacing={1}>
                <Grid item xs={2} className={classes.icon}>
                    {icon}
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        className={classes.field}
                        type={type ? type : 'text'}
                        name={name}
                        id={id}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        required
                        InputProps={{ disableUnderline: true }}
                    />
                </Grid>
            </Grid>
            :
            <Paper className={classes.paper}>
                <Box className={classes.icon2}>
                    {icon}
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        className={classes.field2}
                        type={type ? type : 'text'}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        required
                        InputProps={{ disableUnderline: true }}
                    />
                </Box>
            </Paper>
    )
}

CustomTextField.propTypes = {
    page: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(CustomTextField)