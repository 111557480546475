import axios from "axios";
import { apiUrl, baseUrl } from "../../shared/utils/fetcher";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, ERROR } from "../types";

export const signin = ({ username, password, alert }) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": " application/json",
        },
    };
    const body = JSON.stringify({ username, password });
    try {
        const res = await axios.post(`${apiUrl}login/`, body, config);
        if (res.status === 200) {
            return true;
        } else {
            throw new Error();
        }
    } catch (error) {
        if (error.toJSON().message === 'Network Error') {
            dispatch({
                type: ERROR,
                payload: 'no internet connection'
            });
            alert.show('no internet connection', { type: 'error' })
        }
        return false;
    }
};

export const verifyUser = (alert) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}me/`);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });
        return true;
    } catch (error) {
        if (error.toJSON().message === 'Network Error') {
            dispatch({
                type: ERROR,
                payload: 'no internet connection'
            });
            alert.show('no internet connection', { type: 'error' })
        }
        dispatch({
            type: LOGIN_FAIL,
        });
        return false;
    }
};

export const forgotPassword = ({ email }) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": " application/json",
        },
    };
    const body = JSON.stringify({ email });
    try {
        const res = await axios.post(`${baseUrl}auth/password/reset/`, body, config);
        if (res.status === 200) {
            return true;
        } else {
            throw new Error();
        }
    } catch (err) {
        console.debug(err);
        return false;
    }
};

export const changePassword = (data) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": " application/json",
        },
    };
    const body = JSON.stringify(data);
    try {
        const res = await axios.post(`${baseUrl}auth/password/change/`, body, config);
        if (res.status === 200) {
            return true;
        } else {
            throw new Error();
        }
    } catch (err) {
        console.debug(err);
        return false;
    }
};

export const logout = () => async (dispatch) => {
    const res = await axios.post(`${apiUrl}logout/`);
    if (res.status === 200) {
        dispatch({
            type: LOGOUT,
        });
        return true
    }
    else {
        return false
    }
};
