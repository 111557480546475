import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core"
import CheckBoxField from "../../../components/material/inputFields/CheckBoxField";
import InputField from '../../../components/material/inputFields/InputField'
import AutocompleteField from '../../../components/material/inputFields/AutocompleteField'
import Buttons from '../../../components/common/Buttons'
import { onChange, filterById } from '../../../functions/onChange'
import { navigate } from '../../../functions/navigate'
import { setPage } from '../../../redux/actions/page'

const RangeForm = ({
    formData, setFormData, onSubmit, setPage,
    category: { categoriesList },
    page: { loadedPage }
}) => {
    const history = useHistory()

    const onCancel = () => {
        navigate(history, setPage, 'ranges')
    }

    let categoryArray = []
    let categoryGroup = categoriesList?.filter((category) => {
        if (formData?.included_categories?.includes(category.id)) {
            categoryArray.push(category.name)
        }
        return categoryArray
    })

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <InputField
                        name='name'
                        label='name'
                        value={formData?.name}
                        required={true}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        type='text'
                        disabled={loadedPage === 'edit-range' ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        name='description'
                        label='Description'
                        value={formData?.description}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        type='text'
                    />
                </Grid>
                <Grid item xs={6}>
                    <CheckBoxField
                        name='is_public' label='Is Public?' value={formData?.is_public}
                        onChange={(e) => onChange(e, formData, setFormData)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CheckBoxField
                        name='includes_all_products' label='Includes all Products' value={formData?.includes_all_products}
                        onChange={(e) => onChange(e, formData, setFormData)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AutocompleteField
                        name='included_categories'
                        label='Categories'
                        options={categoriesList && categoriesList?.map(category => category.name)}
                        value={categoryGroup ? categoryArray?.map(val => val) : []}
                        onChange={(event, newValue) => {
                            setFormData({
                                ...formData, 'included_categories':
                                    filterById(newValue, categoriesList)
                            })
                        }}
                        disabled={formData?.includes_all_products === true ? true : false}
                    />
                </Grid>
            </Grid>
            <br />
            <Buttons onCancel={onCancel} />

        </form>
    )
}

RangeForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(RangeForm)