import { makeStyles } from '@material-ui/core'
import { themeBackground, borderColor, primaryColor, dangerColor } from '../colors'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    container: {
        background: themeBackground,
        marginTop: theme.spacing(1),
        borderRadius: '8px',
        border: `1px solid ${borderColor[1]}`,
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },
    edit: {
        color: primaryColor,
    },
    delete: {
        color: dangerColor
    }
}))

export { useStyles }
