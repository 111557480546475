import { makeStyles } from '@material-ui/core/styles'
import { contrastColor, shadowColor } from '../../colors'

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 100,
        boxShadow: `0px 3px 9px ${shadowColor}`
    },
    media: {
        height: 0,
        paddingTop: theme.spacing(1)
    },
    avatar: {
        backgroundColor: contrastColor,
    },
    content: {
        marginTop: theme.spacing(-2)
    }
    // card: {
    //     cursor: 'pointer',
    //     background: whiteColor,
    //     width: '100%',
    //     position: 'relative',
    //     boxShadow: `0px 3px 9px ${grayColor}`

    // },
    // format: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     textAlign: 'center'
    // },
    // cardHeader: {
    //     clipPath: 'polygon(0% 15%, 0 0, 100% 0, 50% 16%, 100% 31%, 52% 48%, 100% 66%, 54% 81%, 100% 100%, 15% 100%, 0 100%, 0% 85%)',
    //     width: '0.5rem',
    // },
    // header: {
    //     color: contrastColor,
    //     textShadow: `0px 3px 9px ${grayColor}`
    // },
    // cardContent: {
    //     color: contrastColor,
    // },
    // formatBlock: {
    //     display: 'block',
    //     flexGrow: 1
    // },
    // cardActions: {
    //     justifyContent: 'center',
    //     textAlign: 'center'
    // },
    // divider: {
    //     margin: theme.spacing(0, 2)
    // },
}))

export default useStyles
