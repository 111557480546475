import React from "react"
import ImageUploader from "react-images-upload";
import { InputLabel } from "@material-ui/core"
import useStyles from '../../../assets/jss/components/material/inputFields'

const ImageField = (props) => {
    const classes = useStyles()
    const { onDrop, single } = props

    return (
        <>
            <InputLabel className={classes.label}>Image</InputLabel>
            <ImageUploader
                className={classes.input}
                withIcon={true}
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                withPreview={true}
                accept="accept=image/*"
                singleImage={single}
            />
        </>
    )
}

export default ImageField