import {
    BENEFITS_FOUND, BENEFITS_NOT_FOUND,
    GET_BENEFIT, REMOVE_BENEFIT,
    DELETE_BENEFIT
} from '../types'

const initialState = {
    benefitsList: null,
    benefitsCount: 0,
    benefitDetail: null,
}

const benefit = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case BENEFITS_FOUND:
            return {
                ...state,
                benefitsList: payload.benefitsList,
                benefitsCount: payload.benefitsCount,
                benefitDetail: null,
            }
        case BENEFITS_NOT_FOUND:
            return {
                ...state,
                benefitsList: null,
                benefitsCount: 0,
            }
        case DELETE_BENEFIT:
            let updatedList = state.benefitsList.filter((item) => item.id !== payload).map(items => items);
            let count = updatedList?.length
            return {
                ...state,
                benefitsList: updatedList,
                benefitsCount: count,
                benefitDetail: null,
            }
        case GET_BENEFIT:
            return {
                ...state,
                benefitDetail: payload
            }
        case REMOVE_BENEFIT:
            return {
                ...state,
                benefitDetail: null
            }
        default:
            return state
    }
}

export default benefit