import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import { useStyles } from "../../../assets/jss/components/material/table";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { formNavigation } from '../../../functions/navigate'
import { setPage } from "../../../redux/actions/page";

const CustomTable = ({ tableHead, tableData, sort, onClick, setPage }) => {

    const classes = useStyles();
    const [items, setItems] = useState([]);
    const history = useHistory()

    useEffect(() => {
        setItems(tableData);
    }, [tableData]);

    let keyValue = (prop) => (
        <Grid container style={{ padding: '0.2rem' }} className={classes.tableBodyRow}
            onClick={() => onClick === 'modifier-options-edit' ?
                formNavigation(history, setPage, 'modifier-group', prop?.group, 'edit', 'options', prop?.uid)
                :
                formNavigation(history, setPage, onClick, prop?.uid, 'edit')
            }
        >
            <Grid item xs={1} className={classes.sortableTableCell}>
                <DragHandles />
            </Grid>
            {prop?.cells?.map((cell, index) => (
                <Grid item xs={cell.size} key={index} className={classes.sortableTableCell}>
                    {cell.name}
                </Grid>
            ))}
        </Grid>
    );

    const DragHandles = sortableHandle(() => (
        <DragHandle style={{ cursor: 'alias' }} />
    ));

    const SortableItem = sortableElement(({ value }) => value);

    const SortableContainer = sortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems((items) => arrayMove(items, oldIndex, newIndex));
        sort(arrayMove(items, oldIndex, newIndex).map(item => item.id))
    };

    return (
        <div className={classes.tableResponsive}>
            <div className={classes.table}>
                {tableHead !== undefined ? (
                    <div className={classes.head}>
                        <Grid container className={classes.sortableTableHeadRow}>
                            {tableHead.map((prop, key) => (
                                <Grid item xs={prop.size} className={classes.tableHeadCell} style={{ padding: '0.5rem 1rem', opacity: 0.8 }} key={key}>
                                    {prop.name}
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : null}

                <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                    {items !== undefined &&
                        items.map((prop, key) => (
                            <SortableItem key={key} index={key} value={keyValue(prop)} />
                        ))}
                </SortableContainer>
            </div>
        </div>
    );
};

CustomTable.propTypes = {
    setPage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setPage })(CustomTable)
