import React from "react"
import { Button } from "@material-ui/core/"
import Dialog from '../../components/material/dialog/Dialog'
import SwipeableTabs from '../../components/material/swipeableTabs/SwipeableTabs'
import { useStyles } from "../../assets/jss/pages/order"
import CustomerDetails from "./CustomerDetails"
import ContentChecker from '../../shared/common/ContentChecker'
import Notes from "./Notes"
// import Payment from "./Payment"

const ShowOrder = (props) => {
    const classes = useStyles()
    const { show, hideOrderDialog, orderDetail } = props

    return (
        <Dialog
            close={hideOrderDialog}
            open={show}
            dialogTitle={`Order # ${orderDetail && orderDetail.number}`}
            dialogContent={
                orderDetail ?
                    <SwipeableTabs
                        tabData={[
                            { label: 'Customer Details', component: <CustomerDetails orderDetail={orderDetail} /> },
                            { label: 'Notes', component: <Notes orderDetail={orderDetail} /> },
                            // { label: 'Payment', component: <Payment orderDetail={orderDetail} /> },
                        ]}
                    />
                    :
                    <ContentChecker reducerName={orderDetail} />
            }
            dialogActions={
                <Button onClick={hideOrderDialog} className={classes.danger}>
                    Close
                </Button>
            }
            detail={orderDetail && orderDetail}
        />
    )
}

export default ShowOrder