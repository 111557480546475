export const path = (name) => {
    return name.split('/').length === 6 && name.split('/')[5] === "edit" ?
        name.split('/')[1] + '-' + name.split('/')[3] + '-' + name.split('/')[5]
        :
        name.split('/').length === 5 && name.split('/')[4] === "add" ?
            name.split('/')[1] + '-' + name.split('/')[3] + '-' + name.split('/')[4]
            :
            name.split('/')[2] === "add" ?
                name.split('/')[1] + '-' + name.split('/')[2]
                :
                name.split('/')[3] === "edit" ?
                    name.split('/')[1] + '-' + name.split('/')[3]
                    :
                    name.split('/')[1]
}