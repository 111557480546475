export const formatSingleBenefit = (benefit) => {
    benefit.name = `The products that meet the condition are sold for £${benefit.value}`
    return benefit
}

export const formatBenefits = (benefits) => {
    for (let i = 0; i < benefits.length; i++) {
        benefits[i] = formatSingleBenefit(benefits[i])
    }
    return benefits
}