import { makeStyles } from '@material-ui/core/styles';
import { themeBackground } from '../colors'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            overflowX: 'hidden'
        }
    },
    content: {
        backgroundColor: themeBackground,
        flexGrow: 1,
        padding: theme.spacing(1, 1, 1, 0),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
}));

export default useStyles
