import React, { useEffect, useState } from "react"
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import OptionsForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { useStyles } from "../../../assets/jss/pages/modifiers"
import ContentChecker from '../../../shared/common/ContentChecker'
import { formNavigation } from '../../../functions/navigate'
import { addModifierOptions, getModifierGroup } from "../../../redux/actions/modifier";
import { getAllProducts } from "../../../redux/actions/product";
import { setPage } from '../../../redux/actions/page'
import { initialState } from "./State"

const CreateModifierOption = ({
    addModifierOptions, getModifierGroup, getAllProducts, setPage,
    product: { productsList },
    modifier: { modifierGroup },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let { id } = useParams();

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        getAllProducts()
        if (id) {
            getModifierGroup(id)
        }
    }, [getAllProducts, getModifierGroup, id])

    useEffect(() => {
        if (modifierGroup) {
            setFormData({
                ...formData,
                group: parseInt(modifierGroup?.id)
            })
        }
        // eslint-disable-next-line
    }, [modifierGroup])

    const onSubmit = e => {
        e.preventDefault()

        let productIdString = typeof (formData.product) === 'string' && productsList?.filter(product => product.title === formData.product)
        let productIdObject = typeof (formData?.product) === 'object' && formData?.product && formData?.product[0]
        formData.product = productIdString?.length > 0 ? productIdString[0]?.id : productIdObject

        addModifierOptions(formData, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Modifier options added successfully`, { type: "success" })
                    formNavigation(history, setPage, 'modifier-group', modifierGroup?.uid, 'edit')
                }
            });

    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='modifier options' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {productsList ?
                    <OptionsForm formData={formData} setFormData={setFormData} onSubmit={onSubmit} />
                    :
                    <ContentChecker reducerName={productsList} />
                }
            </Container>
        </Container>
    )
}

CreateModifierOption.propTypes = {
    addModifierOptions: PropTypes.func.isRequired,
    getModifierGroup: PropTypes.func.isRequired,
    getAllProducts: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    modifier: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    addModifierOptions, getModifierGroup, getAllProducts, setPage
})(CreateModifierOption)
