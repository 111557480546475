import { makeStyles } from '@material-ui/core/styles'
import { headerText, borderColor, themeBackground, primaryColor, dangerColor, whiteColor } from '../colors'

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3, 2)
    },
    headers: {
        color: headerText[1],
        fontSize: '18px'
    },
    formContainer: {
        background: themeBackground,
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(3),
        borderRadius: '8px',
        border: `1px solid ${borderColor[1]}`,
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },
    header: {
        justifyContent: 'space-between',
        display: 'flex',
    },
    info: {
        justifyContent: 'flex-start',
        display: 'flex',
    },
    logo: {
        borderRadius: '70%',
        width: '70px',
        height: '70px',
        marginRight: theme.spacing(2)
    },
    edit: {
        color: headerText[1],
        background: themeBackground,
        border: `1px solid ${borderColor[1]}`,
        height: '36px',
        borderRadius: '6px'
    },
    flexBox: {
        justifyContent: 'flex-start',
        display: 'flex',
        margin: theme.spacing(5, 1)
    },
    icon: {
        color: primaryColor,
        alignSelf: 'center',
        margin: theme.spacing(0, 3),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 1.5),
        },
    },
    name: {
        color: headerText[4],
        marginTop: theme.spacing(2),
        alignSelf: 'center',
        fontWeight: 700
    },
    change: {
        color: headerText[5],
        alignSelf: 'center',
        cursor: 'pointer'
    },
    heading: {
        color: headerText[3],
        fontSize: '16px'
    },
    detail: {
        color: headerText[1],
        fontSize: '16px'
    },
    day: {
        justifyContent: 'flex-start',
        padding: theme.spacing(2.25, 5),
        alignSelf: 'center',
        minWidth: '15rem',
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2, 2),
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(1, 5),
            fontSize: '15px',
            textAlign: 'center',
            minWidth: '10rem',

        },
    },
    to: {
        alignSelf: 'center',
        textAlign: 'center',
        paddingTop: theme.spacing(2.25),
        fontSize: '18px',
        lineHeight: '1rem',
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(0.25),
            fontSize: '15px',
            textAlign: 'center'
        },
    },
    danger: {
        backgroundColor: dangerColor,
        color: whiteColor,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: dangerColor,
            color: whiteColor,
            opacity: 0.7,
        },
    },
    save: {
        backgroundColor: primaryColor,
        color: whiteColor,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: primaryColor,
            color: whiteColor,
            opacity: 0.7,
        },
    },
    subHeader: {
        color: headerText[2],
        fontSize: '14px'
    }
}))

export default useStyles
