import axios from "axios";
import { apiUrl } from "../../shared/utils/fetcher";
import { GET_STATS, REMOVE_STATS, GET_TOP_PRODUCTS, REMOVE_TOP_PRODUCTS, GET_WEEKLY_ORDERS } from "../types";

export const getStats = () => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}dashboard/`,);
        dispatch({
            type: GET_STATS,
            payload: res.data
        });
        return true
    } catch (error) {
        console.debug(error)
        dispatch({
            type: REMOVE_STATS,
        });
        return false
    }
};

export const getTopProducts = () => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}product/analytics/`,);
        dispatch({
            type: GET_TOP_PRODUCTS,
            payload: res.data.results
        });
        return true
    } catch (error) {
        console.debug(error)
        dispatch({
            type: REMOVE_TOP_PRODUCTS,
        });
        return false
    }
};

export const getWeeklyOrders = () => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}weekly/orders/`,);
        dispatch({
            type: GET_WEEKLY_ORDERS,
            payload: res.data.results
        });
        return true
    } catch (error) {
        console.debug(error)
        return false
    }
};