import React, { useState } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import GroupForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { useStyles } from "../../../assets/jss/pages/modifiers"
import { navigate } from '../../../functions/navigate'
import { addModifierGroup } from "../../../redux/actions/modifier";
import { setPage } from '../../../redux/actions/page'
import { initialState } from "./State";

const CreateModifierGroup = ({
    addModifierGroup, setPage
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();

    const [formData, setFormData] = useState(initialState)

    const onSubmit = (e) => {
        e.preventDefault()

        formData.required = formData.required === true ? true : false
        formData.type = formData.type === true ? "CHECKBOX" : "RADIO"

        addModifierGroup(formData)
            .then((success) => {
                if (success) {
                    alert.show('Modifier group added successfully', { type: "success" })
                    navigate(history, setPage, 'modifiers')
                }
                else {
                    alert.show('Modifier group creation failed. Please try again later', { type: "error" })
                }
            });
    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='modifier group' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                <GroupForm formData={formData} setFormData={setFormData} onSubmit={onSubmit} />
            </Container>
        </Container>
    )
}

CreateModifierGroup.propTypes = {
    addModifierGroup: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    modifier: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { addModifierGroup, setPage })(CreateModifierGroup)