import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import CrudHeader from '../../../components/common/CrudHeader'
import ContentChecker from '../../../shared/common/ContentChecker'
import RangeForm from './Form'
import { getRangeDetail, updateRange } from '../../../redux/actions/range'
import { getAllCategories } from '../../../redux/actions/category'
import { initialState } from './State'

const EditRange = ({
    getRangeDetail, updateRange, getAllCategories,
    range: { rangeDetail },
    category: { categoriesList },
}) => {
    const classes = useStyles()
    const alert = useAlert();
    let id = window.location.pathname.split('/')[2]

    useEffect(() => {
        getAllCategories()
        if (id) {
            getRangeDetail(id)
        }
    }, [getRangeDetail, getAllCategories, id])

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (rangeDetail) {
            setFormData({
                ...formData,
                id: rangeDetail?.id,
                name: rangeDetail?.name,
                description: rangeDetail?.description,
                is_public: rangeDetail?.is_public,
                includes_all_products: rangeDetail?.includes_all_products,
                included_categories: rangeDetail?.included_categories,
            })
        }
        // eslint-disable-next-line
    }, [rangeDetail])

    const onSubmit = e => {
        e.preventDefault()

        formData.is_public = formData?.is_public === true ? true : false
        formData.includes_all_products = formData?.includes_all_products === true ? true : false

        updateRange(formData, rangeDetail && rangeDetail?.id, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Range updated successfully`, { type: "success" })
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='range' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {categoriesList ?
                    (formData?.id && id && rangeDetail) ?
                        <RangeForm
                            formData={formData}
                            setFormData={setFormData}
                            onSubmit={onSubmit}
                        />
                        :
                        <ContentChecker reducerName={rangeDetail} />
                    :
                    <ContentChecker reducerName={categoriesList} />
                }
            </Container>
        </Container>
    )
}

EditRange.propTypes = {
    getRangeDetail: PropTypes.func.isRequired,
    updateRange: PropTypes.func.isRequired,
    getAllCategories: PropTypes.func.isRequired,
    range: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getRangeDetail, updateRange, getAllCategories })(EditRange)