import axios from 'axios';
import { apiUrl } from '../../shared/utils/fetcher'
import {
    GET_FILTERED_MODIFIERS, MODIFIERS_FOUND, MODIFIERS_NOT_FOUND,
    GET_MODIFIER_GROUP, REMOVE_MODIFIER_GROUP,
    GET_MODIFIER_OPTION, REMOVE_MODIFIER_OPTION,
    DELETE_MODIFIER_GROUP
} from '../types'
import { formatModifiers } from '../../shared/formatter/modifierFormatter'

export const sortModifierOptions = (items) => async dispatch => {
    try {
        const resp = await axios.post(`${apiUrl}modifier-sequence/`, { sequence: items });
        if (resp) {
            return true;
        }
    } catch {
        return false;
    }
}

export const getAllModifiers = () => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}modifiergroups/?limit=200`);
        let data = formatModifiers(res.data.results)
        dispatch({
            type: MODIFIERS_FOUND,
            payload: {
                modifiersList: data && data.length > 0 ? data : 0,
                modifiersCount: res.data.count
            },
        })
        return true;
    } catch {
        dispatch({
            type: MODIFIERS_NOT_FOUND,
        });
        return false;
    }
}

export const getModifiersList = (page) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}modifiergroups/?page=${page ? page : 1}`);
        let data = formatModifiers(res.data.results)
        dispatch({
            type: MODIFIERS_FOUND,
            payload: {
                modifiersList: data && data.length > 0 ? data : 0,
                modifiersCount: res.data.count
            },
        })
        return true;
    } catch {
        dispatch({
            type: MODIFIERS_NOT_FOUND,
        });
        return false;
    }
}

export const getFilteredModifiers = (query) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}modifiergroups/?search=${query}`)
        let data = formatModifiers(res.data.results)
        dispatch({
            type: GET_FILTERED_MODIFIERS,
            payload: {
                filteredModifier: data && data.length > 0 ? data : 0,
                modifiersCount: res.data.count
            },
        });
        return true;
    } catch (error) {
        console.debug(error)
        dispatch({
            type: MODIFIERS_NOT_FOUND,
        });
        return false;
    }
}

export const getModifierGroup = (id) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}modifiergroups/${id}/`);
        let data = res.data
        dispatch({
            type: GET_MODIFIER_GROUP,
            payload: data,
        })
        return true;
    } catch {
        dispatch({
            type: REMOVE_MODIFIER_GROUP,
        });
        return false;
    }
}

export const addModifierGroup = (formData) => async () => {
    let body = formData
    try {
        let res = await axios.post(`${apiUrl}modifiergroups/`, body)

        if (res) {
            let idx = res.data.id
            formData.product && formData.product.map(async item => {
                await axios.post(`${apiUrl}modifieroptions/`, { group: parseInt(idx), product: item.id });
            })
            return true;
        }
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const updateModifierGroup = (formData) => async () => {
    let body = formData
    try {
        await axios.put(`${apiUrl}modifiergroups/${formData.uid}/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const getModifierOption = (id) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}modifieroptions/${id}/`);
        let data = res.data
        dispatch({
            type: GET_MODIFIER_OPTION,
            payload: data,
        })
        return true;
    } catch {
        dispatch({
            type: REMOVE_MODIFIER_OPTION,
        });
        return false;
    }
}

export const addModifierOptions = (formData, alert) => async (dispatch) => {
    try {
        await axios.post(`${apiUrl}modifieroptions/`, formData)
        return true;
    } catch (error) {
        let err = error.response
        if (err?.status === 500) {
            alert.show('Cannot add modifier option. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
        return false;
    }
}

export const updateModifierOptions = (formData, alert) => async (dispatch) => {
    try {
        await axios.put(`${apiUrl}modifieroptions/${formData.uid}/`, formData);
        dispatch({
            type: REMOVE_MODIFIER_OPTION,
        });
        return true;
    } catch (error) {
        let err = error.response
        if (err?.status === 500) {
            alert.show('Cannot update modifier option. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
        return false;
    }
}

export const deleteModifierGroup = (idx) => async dispatch => {
    try {
        await axios.delete(`${apiUrl}modifiergroups/${idx}/`);
        dispatch({
            type: DELETE_MODIFIER_GROUP,
            payload: idx
        })
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const deleteModifierOption = (idx) => async dispatch => {
    try {
        await axios.delete(`${apiUrl}modifieroptions/${idx}/`);
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

