import axios from 'axios';
import { apiUrl } from "../../shared/utils/fetcher";
import {
    OFFERS_FOUND, OFFERS_NOT_FOUND,
    GET_OFFER, REMOVE_OFFER,
    DELETE_OFFER
} from '../types'

let offerUrl = `${apiUrl}/me/offers`

export const getAllOffers = () => async dispatch => {
    try {
        const res = await axios.get(`${offerUrl}/?limit=200`)
        let data = res.data.results
        dispatch({
            type: OFFERS_FOUND,
            payload: {
                offersList: data && data.length > 0 ? data : 0,
                offersCount: res.data.count
            },
        });
        return true;
    } catch {
        dispatch({
            type: OFFERS_NOT_FOUND,
        });
        return false;
    }
}

export const getOffersList = (page) => async dispatch => {
    try {
        const res = await axios.get(`${offerUrl}/?page=${page ? page : 1}`)
        let data = res.data.results
        dispatch({
            type: OFFERS_FOUND,
            payload: {
                offersList: data && data.length > 0 ? data : 0,
                offersCount: res.data.count
            },
        });
        return true;
    } catch {
        dispatch({
            type: OFFERS_NOT_FOUND,
        });
        return false;
    }
}

export const getOfferDetail = (id) => async dispatch => {
    try {
        const res = await axios.get(`${offerUrl}/${id}/`)
        let data = res.data
        dispatch({
            type: GET_OFFER,
            payload: data
        });
        return true;
    } catch {
        dispatch({
            type: REMOVE_OFFER,
        });
        return false;
    }
}

export const addOffer = (formData, alert) => async dispatch => {
    let body = formData
    try {
        await axios.post(`${offerUrl}/`, body)
        return true;
    } catch (error) {
        let err = error.response
        if (err?.status === 500) {
            alert.show('Offer creation failed. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
        return false;
    }
}

export const updateOffer = (formData, id, alert) => async dispatch => {
    let body = formData
    try {
        await axios.put(`${offerUrl}/${id}/`, body)
        return true;
    } catch (error) {
        let err = error.response
        if (err?.status === 500) {
            alert.show('Cannot update Offer. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
        return false;
    }
}

export const deleteOffer = (id) => async dispatch => {
    try {
        await axios.delete(`${offerUrl}/${id}/`)
        dispatch({
            type: DELETE_OFFER,
            payload: id
        })
        return true;
    } catch {
        return false
    }
}
