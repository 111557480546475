import React from "react"
import Loader from 'react-loader-spinner'
import '../../assets/css/Loader.css'

export const loader = (loading, setLoading, timer) => {
    if (!loading) {
        setLoading(true);
        timer.current = window.setTimeout(() => {
            setLoading(false);
        }, 2000);
    }
}

export const LoadingScreen = () => (
    <div className="loading">
        <Loader
            color="#FFFFFF"
            type="ThreeDots"
            height={100}
            width={100}
            timeout={0}
        />
    </div>
)

