import { formatDate, formatTime } from './dateTimeFormatter'

export const formatSingleVoucher = (voucher) => {
    voucher.startDate = formatDate(voucher.start_datetime)
    voucher.startTime = formatTime(voucher.start_datetime)
    voucher.endDate = formatDate(voucher.end_datetime)
    voucher.endTime = formatTime(voucher.end_datetime)

    return voucher
}

export const formatVouchers = (vouchers) => {
    for (let i = 0; i < vouchers.length; i++) {
        vouchers[i] = formatSingleVoucher(vouchers[i])
    }
    return vouchers
}