import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core/"
import Card from '../../components/material/card/Card'
import useStyles from '../../assets/jss/pages/dashboard'
import Line from '../../components/charts/Line'

const StatCards = ({
    stats: { stats },
}) => {
    const classes = useStyles()

    const options = {
        tooltips: {
            enabled: false,
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [{ display: false }],
            yAxes: [{ display: false }],
        }
    }

    const labels = ['', '', '', '', '', '', '']
    const data = [0, 1, 0.5, 2, 1.3, 3.5, 2]
    const cardsData = [
        {
            title: stats && stats.orders ? stats.orders : 0, subHeader: 'Total Orders',
            content: <Line
                colors={{ border: 'rgb(255, 99, 132, 0.8)', background: 'rgb(255, 99, 132, 0.2)' }}
                dataSet={data} labels={labels} options={options}
            />
        },
        {
            title: stats && stats.total_products ? stats.total_products : 0, subHeader: 'Total Products',
            content: <Line
                colors={{ border: 'rgb(122, 233, 118, 0.8)', background: 'rgb(122, 233, 118, 0.2)' }}
                dataSet={data} labels={labels} options={options}
            />
        },
        {
            title: stats && stats.inprogress_orders ? stats.inprogress_orders : 0, subHeader: 'In Progress Orders',
            content: <Line
                colors={{ border: 'rgb(151, 51, 182, 0.8)', background: 'rgb(151, 51, 182, 0.2)' }}
                dataSet={data} labels={labels} options={options}
            />
        },
        {
            title: stats && stats.shipped_orders ? stats.shipped_orders : 0, subHeader: 'Shipped Orders',
            content: <Line
                colors={{ border: 'rgb(116, 207, 220, 0.8)', background: 'rgb(116, 207, 220, 0.2)' }}
                dataSet={data} labels={labels} options={options}
            />
        },
        // {
        //     title: '04', subHeader: 'Total Stores',
        //     content: <Line
        //         colors={{ border: 'rgb(116, 207, 220, 0.8)', background: 'rgb(116, 207, 220, 0.2)' }}
        //         dataSet={data} labels={labels} options={options}
        //     />
        // },
    ]
    return (
        <Grid container spacing={2}>
            {cardsData && cardsData.map((card, index) => (
                <Grid item xs={12} sm={6} lg={3} key={index}>
                    <Card
                        header={{
                            title: <h1 className={classes.title}>{card.title}</h1>,
                            subHeader: <h3 className={classes.subHeader}>{card.subHeader}</h3>
                        }}
                        content={<div className={classes.content}>{card.content}</div>}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

StatCards.propTypes = {
    stats: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(StatCards)