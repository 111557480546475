import { fromatDescription } from './descriptionFormatter'

export const formatSingleCategory = (category) => {
    category.description = fromatDescription(category.description)
    return category
}

export const formatCategories = (categorys) => {
    for (let i = 0; i < categorys.length; i++) {
        categorys[i] = formatSingleCategory(categorys[i])
    }
    return categorys
}