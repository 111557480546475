import { makeStyles } from "@material-ui/core/styles";
import { primaryColor, whiteColor, shadowColor, borderColor } from "../../colors";

const useStyles = makeStyles(theme => ({
    tabs: {
        color: whiteColor,
        background: primaryColor,
        borderBottom: `1px solid ${borderColor[1]}`,
        boxShadow: "0 1px 4px 0" + shadowColor,
        fontSize: ".875rem",
    },
    paper: {
        padding: theme.spacing(5, 2, 7, 2),
        marginBottom: theme.spacing(5),
    }
}))

export default useStyles;