import axios from 'axios';
import { apiUrl } from '../../shared/utils/fetcher'
import {
    GET_FILTERED_PRODUCTS, PRODUCTS_FOUND, PRODUCTS_NOT_FOUND, GET_PRODUCT, REMOVE_PRODUCT, DELETE_PRODUCT
} from '../types';
import { formatProducts, formatProduct } from '../../shared/formatter/productFormatter'

export const sortProducts = (items) => async dispatch => {
    try {
        const resp = await axios.post(`${apiUrl}product-sequence/`, { sequence: items })
        if (resp) {
            const res = await axios.get(`${apiUrl}products/`)
            let data = formatProducts(res.data.results)
            dispatch({
                type: PRODUCTS_FOUND,
                payload: {
                    productsList: data && data.length > 0 ? data : 0,
                    productsCount: res.data.count
                },
            });
            return true
        }
    } catch {
        dispatch({
            type: PRODUCTS_NOT_FOUND,
        });
        return false;
    }
}

export const sortProductModifiers = (items) => async dispatch => {
    try {
        const resp = await axios.post(`${apiUrl}modifier-group-sequence/`, { sequence: items });
        if (resp) {
            return true;
        }
    } catch {
        return false;
    }
}

export const getAllProducts = () => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}products/?limit=500`)
        let data = formatProducts(res.data.results)
        dispatch({
            type: PRODUCTS_FOUND,
            payload: {
                productsList: data && data.length > 0 ? data : 0,
                productsCount: res.data.count
            },
        });
        return true
    } catch {
        dispatch({
            type: PRODUCTS_NOT_FOUND,
        });
        return false
    }
}

export const getProductsList = (page) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}products/?page=${page ? page : 1}`)
        let data = formatProducts(res.data.results)
        dispatch({
            type: PRODUCTS_FOUND,
            payload: {
                productsList: data && data.length > 0 ? data : 0,
                productsCount: res.data.count
            },
        });
        return true
    } catch {
        dispatch({
            type: PRODUCTS_NOT_FOUND,
        });
        return false
    }
}

export const getFilteredProducts = (query) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}products/?search=${query}`)
        let data = formatProducts(res.data.results)
        dispatch({
            type: GET_FILTERED_PRODUCTS,
            payload: {
                filteredProduct: data && data.length > 0 ? data : 0,
                productsCount: res.data.count
            },
        });
        return true
    } catch (error) {
        console.debug(error)
        dispatch({
            type: PRODUCTS_NOT_FOUND,
        });
        return false
    }
}

export const getProduct = (id) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}products/${id}/`)
        let data = formatProduct(res.data)
        dispatch({
            type: GET_PRODUCT,
            payload: data
        });
        return true
    } catch {
        dispatch({
            type: REMOVE_PRODUCT,
        });
        return false
    }
}

export const updateProductStatus = (id, status) => async dispatch => {
    let body;
    if (status === true)
        body = { status: 'Unavailable' }
    else
        body = { status: 'Available' }
    try {
        await axios.put(`${apiUrl}products/${id}/availability/`, body);
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const updateProduct = (formData, pictures) => async dispatch => {
    let id = formData && formData.uid
    let params = {}
    let data = new FormData();
    if (pictures !== undefined) {
        params.original = pictures[0]
        params.product = id
    } else {
        delete params.original
    }

    for (var field in params) {
        data.set(field, params[field]);
    }
    let body = formData
    try {
        if (pictures !== undefined) {
            await axios.post(`${apiUrl}products/${id}/images/`, data)
        }
        await axios.put(`${apiUrl}products/${id}/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const addProduct = (formData) => async dispatch => {
    let body = formData
    try {
        await axios.post(`${apiUrl}products/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const deleteImage = (id) => async dispatch => {
    try {
        await axios.delete(`${apiUrl}/products/${id}/images/`)
        return true;
    } catch {
        return false
    }
}

export const deleteProduct = (id) => async dispatch => {
    try {
        await axios.delete(`${apiUrl}products/${id}/`);
        dispatch({
            type: DELETE_PRODUCT,
            payload: id
        })
        return true;
    } catch {
        return false
    }
}