import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { useAlert } from "react-alert"
import { Container } from "@material-ui/core/"
import { MoreVert, Edit, Delete, DragHandle } from "@material-ui/icons/"
import ContentChecker from '../../shared/common/ContentChecker'
import { getList } from '../../functions/search'
import CustomTable from "../../components/material/table/SortableTable";
import CustomMenu from '../../components/material/menu/Menu'
import Header from '../../components/common/Header'
import { deleteFunc } from '../../functions/delete'
import { formNavigation } from '../../functions/navigate'
import { useStyles } from "../../assets/jss/pages/categories"
import { deleteCategory, getFilteredCategories, getCategoriesList, getCategory, sortCategories } from "../../redux/actions/category"
import { setPage } from "../../redux/actions/page";

const ListCategories = ({
    getFilteredCategories, getCategoriesList, setPage, deleteCategory, sortCategories,
    category: { categoriesList, filteredCategory },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const [searchCategory, setSearchCategory] = useState('');
    const [disableClick, setDisableClick] = useState(false);

    let currentList = searchCategory ? filteredCategory : categoriesList

    useEffect(() => {
        getList(searchCategory, getFilteredCategories, getCategoriesList)
    }, [searchCategory, getFilteredCategories, getCategoriesList]);

    const sortCategory = (data) => {
        sortCategories(data)
    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header doSeacrch={true} search={searchCategory} setSearch={setSearchCategory} name='categories' nav='category' />

            {currentList ?
                <CustomTable
                    onClick={disableClick === false ? 'category' : false}
                    tableHead={[
                        { size: 1, name: <DragHandle /> },
                        { size: 2, name: 'Number' },
                        { size: 4, name: 'Name' },
                        { size: 4, name: 'Description' },
                        { size: 1, name: 'Actions' }
                    ]}
                    tableData={currentList.map(category => (
                        {
                            id: category.id,
                            cells: [
                                { size: 2, name: category.id },
                                { size: 4, name: category.name },
                                { size: 4, name: category.description },
                                {
                                    size: 1,
                                    name: <CustomMenu
                                        stateSetter={setDisableClick}
                                        button={<MoreVert />}
                                        menuItems={[
                                            {
                                                name: <Edit className={classes.edit} />,
                                                handleItemClick: (event) => {
                                                    event.stopPropagation()
                                                    formNavigation(history, setPage, 'category', category.uid, 'edit')
                                                }
                                            },
                                            {
                                                name: <Delete className={classes.delete} />,
                                                handleItemClick: (event) => {
                                                    event.stopPropagation()
                                                    deleteFunc(deleteCategory, category.uid, alert)
                                                }
                                            }
                                        ]}
                                    />
                                }
                            ]
                        }
                    ))}
                    sort={sortCategory}
                />
                :
                <ContentChecker reducerName={searchCategory ? filteredCategory : categoriesList} />
            }
        </Container>
    )
}

ListCategories.propTypes = {
    deleteCategory: PropTypes.func.isRequired,
    getFilteredCategories: PropTypes.func.isRequired,
    getCategoriesList: PropTypes.func.isRequired,
    sortCategories: PropTypes.func.isRequired,
    getCategory: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    deleteCategory, getFilteredCategories, getCategoriesList, getCategory, sortCategories, setPage
})(ListCategories)