
import React from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from '@material-ui/core';
import ContentChecker from '../../../shared/common/ContentChecker'

const CustomDialog = (props) => {
    // const classes = useStyles();
    const { open, close, dialogTitle, dialogContent, dialogActions, detail } = props
    return (
        <Dialog
            // className={classes.dialog}
            open={open}
            onClose={close}
            fullWidth={true}
            maxWidth={'sm'}
        >
            {detail ?
                <>
                    <DialogTitle><Typography style={{ fontWeight: 700, fontSize: '22px' }}>{dialogTitle}</Typography></DialogTitle>
                    <DialogContent dividers style={{ scrollBehavior: 'smooth' }}>{dialogContent}</DialogContent>
                </>
                :
                <DialogContent dividers>
                    <ContentChecker reducerName={detail} />
                </DialogContent>
            }
            {dialogActions ? <DialogActions>{dialogActions}</DialogActions> : ''}

        </Dialog>
    );
};


export default CustomDialog;