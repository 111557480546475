import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { Container } from "@material-ui/core/"
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import CategoryForm from './Form'
import CrudHeader from '../../components/common/CrudHeader'
import { useStyles } from "../../assets/jss/pages/categories"
import ContentChecker from '../../shared/common/ContentChecker'
import { getCategory, updateCategory } from "../../redux/actions/category";
import { initialState } from "./State";

const EditCategory = ({
    getCategory, updateCategory,
    category: { categoryDetail },
    auth: { me }

}) => {
    const classes = useStyles()
    const alert = useAlert();
    let partnerUrl = me && me.url
    let { uid } = useParams();

    useEffect(() => {
        if (uid) {
            getCategory(uid)
        }
    }, [getCategory, uid])

    // const [pictures, setPictures] = useState('');

    // const onDrop = picture => {
    //     setPictures(picture); // && picture[0] && picture[0].name
    // };

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (categoryDetail) {
            setFormData({
                ...formData,
                uid: categoryDetail?.uid,
                name: categoryDetail?.name,
                description: categoryDetail?.description,
                partner: categoryDetail?.partner ? categoryDetail?.partner : partnerUrl,
            })
        }
        // eslint-disable-next-line
    }, [categoryDetail])

    const onSubmit = e => {
        e.preventDefault()
        updateCategory(formData) //(formData, pictures)
            .then((success) => {
                if (success) {
                    alert.show('Category updated successfully', { type: "success" })
                }
                else {
                    alert.show('Could not update the category. Please try again later', { type: "error" })
                }
            });
    }


    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='categories' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {(formData?.uid && uid && categoryDetail) ?
                    <CategoryForm
                        formData={formData}
                        setFormData={setFormData}
                        onSubmit={onSubmit}
                    // pictures={pictures}
                    // onDrop={onDrop}
                    />
                    :
                    <ContentChecker reducerName={categoryDetail} />
                }
            </Container>
        </Container>
    )
}

EditCategory.propTypes = {
    getCategory: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getCategory, updateCategory })(EditCategory)