import { makeStyles } from '@material-ui/core/styles'
import { whiteColor, shadowColor, headerText, borderColor, primaryColor, blackColor } from '../colors'
import bgImage from '../../images/bgImage.png'

const useStyles = makeStyles(theme => ({
    box: {
        margin: theme.spacing(10, 5),
        padding: theme.spacing(10, 5),
        background: whiteColor,
        height: '70%',
        backgroundSize: 'cover',
        boxShadow: `0px 3px 6px ${shadowColor}`,
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        marginTop: '12%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    header: {
        color: headerText[0],
        fontSize: '35px',
        lineHeight: '0.8rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '25px',
        }
    },
    subHeader: {
        color: headerText[1],
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '0.8rem',
        opacity: 0.5,
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        }
    },
    body: {
        margin: theme.spacing(2, 15),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2, 10),
        },
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2, 2),
        },
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1, 0.1),
        }
    },
    bg: {
        backgroundImage: `url(${bgImage})`,
        width: '100%',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    logo: {
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '40%',
        marginLeft: '20%',
        transform: 'transform: translateY(-50%)',
        [theme.breakpoints.down('md')]: {
            marginLeft: '15%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10%',
        },
    },
    paper: {
        background: '#FAFAFA',
        padding: theme.spacing(1, 3),
        margin: theme.spacing(1.5, 1),
        border: `1px solid ${borderColor[0]}`,
        borderRadius: '6px',
    },
    subBody: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0),
            display: 'block',
            justifyContent: 'center',
        },
    },
    extra: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
        },
    },
    icon: {
        color: primaryColor,
        fontWeight: 300
    },
    blackLabel: {
        color: blackColor,
        marginLeft: theme.spacing(1),
        opacity: 0.7
    },
    label: {
        color: primaryColor,
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    submit: {
        backgroundColor: primaryColor,
        color: whiteColor,
        margin: theme.spacing(1, 2, 1, 2),
        width: '95%'
    }
}))

export default useStyles
