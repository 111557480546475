import React from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar } from '@material-ui/core';
import { whiteColor } from '../../../assets/jss/colors'
import useStyles from '../../../assets/jss/components/material/appbar'
import DropDownLink from './DropDownLink';

const Appbar = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                style={{ backgroundColor: whiteColor }}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: true,
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <div>
                        {''}
                    </div>
                    <DropDownLink />
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Appbar