import { combineReducers } from 'redux'
import authReducer from './auth'
import statsReducer from './stats'
import notificationReducer from './notification'
import orderReducer from './order'
import productReducer from './product'
import categoryReducer from './category'
import modifierReducer from './modifier'
import rangeReducer from './range'
import conditionReducer from './condition'
import benefitReducer from './benefit'
import offerReducer from './offer'
import voucherReducer from './voucher'
import errorReducer from './error'
import pageReducer from './page'

export default combineReducers({
    auth: authReducer,
    stats: statsReducer,
    notification: notificationReducer,
    order: orderReducer,
    product: productReducer,
    category: categoryReducer,
    modifier: modifierReducer,
    range: rangeReducer,
    condition: conditionReducer,
    benefit: benefitReducer,
    offer: offerReducer,
    voucher: voucherReducer,
    error: errorReducer,
    page: pageReducer,
});
