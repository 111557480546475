import { addCurrency } from './priceFormatter'

export const formatSingleOrderLine = (line) => {
    line.price = addCurrency(line.product && line.product.stockrecords && line.product.stockrecords[0] && line.product.stockrecords[0].price);
    return line
}

export const formatOrderLines = (lines) => {
    for (let i = 0; i < lines.length; i++) {
        lines[i] = formatSingleOrderLine(lines[i])
    }
    return lines
}

export const getOrderLinesTotal = (total_incl_tax, shipping_incl_tax) => {
    let subtotal = 0;
    subtotal = parseFloat(total_incl_tax - shipping_incl_tax).toFixed(2)
    return addCurrency(subtotal)
}

export const formatSingleOrder = (order) => {
    let shipping_address = order.shipping_address
    order.full_name = order.first_name + ' ' + order.last_name
    order.phone_number = shipping_address.phone_number
    order.lines = formatOrderLines(order.lines)
    order.subtotal = getOrderLinesTotal(order.total_incl_tax, order.shipping_incl_tax)
    order.shipping_incl_tax = addCurrency(order.shipping_incl_tax)
    order.total_incl_tax = addCurrency(order.total_incl_tax)
    order.date_placed = new Date(order.date_placed).toLocaleString()
    return order
}

export const formatOrders = (orders) => {
    for (let i = 0; i < orders.length; i++) {
        orders[i] = formatSingleOrder(orders[i])
    }
    return orders
}