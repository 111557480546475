export const formatSingleCondition = (condition) => {
    condition.name = `Basket includes ${condition.value} distinct item(s)`
    return condition
}

export const formatConditions = (conditions) => {
    for (let i = 0; i < conditions.length; i++) {
        conditions[i] = formatSingleCondition(conditions[i])
    }
    return conditions
}