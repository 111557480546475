import React, { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { Container } from "@material-ui/core/"
import { MoreVert, Edit, Delete, Check, Clear } from "@material-ui/icons/"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import ContentChecker from '../../../shared/common/ContentChecker'
import useStyles from '../../../assets/jss/pages/voucher'
import Header from '../../../components/common/Header'
import { formNavigation } from '../../../functions/navigate'
import CustomTable from "../../../components/material/table/Table";
import CustomMenu from '../../../components/material/menu/Menu'
import { loader } from "../../../shared/common/LoadingScreen";
import { deleteFunc } from '../../../functions/delete'
import { setPage } from "../../../redux/actions/page";
import { getOffersList, deleteOffer } from '../../../redux/actions/offer'

const ListOffers = ({
    getOffersList, deleteOffer, setPage,
    offer: { offersList, offersCount }
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const timer = useRef();
    let limit = 20
    let count = offersCount ? offersCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [loading, setLoading] = useState(false)

    const [disableClick, setDisableClick] = useState(false);

    useEffect(() => {
        getOffersList(1)
    }, [getOffersList]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getOffersList(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header
                name='offers' nav='offer'
                title='Offer is dependent on range, condition and benefits. Make sure RANGE, CONDITION and BENEFIT exists before creating an OFFER.'
            />
            {offersList ?
                <CustomTable
                    onClick={disableClick === false ? 'offer' : false}
                    tableHead={['Number', 'Name', 'Description', 'Offer Type', 'Exclusive', 'Actions']}
                    tableData={offersList.map(offer => (
                        {
                            cells: [
                                offer.id,
                                offer.name,
                                offer.description,
                                offer.offer_type,
                                offer.exclusive === true ? <Check /> : <Clear />,
                                <CustomMenu
                                    stateSetter={setDisableClick}
                                    button={<MoreVert />}
                                    menuItems={[
                                        {
                                            name: <Edit className={classes.edit} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                formNavigation(history, setPage, 'offer', offer.id, 'edit')
                                            }
                                        },
                                        {
                                            name: <Delete className={classes.delete} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                deleteFunc(deleteOffer, offer.id, alert)
                                            }
                                        }
                                    ]}
                                />
                            ],
                            uid: offer.id
                        }
                    ))}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={offersList} />
            }
        </Container>
    )
}

ListOffers.propTypes = {
    getOffersList: PropTypes.func.isRequired,
    deleteOffer: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    offer: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getOffersList, deleteOffer, setPage })(ListOffers)