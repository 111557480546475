import React, { useState, useRef, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import { MoreVert, Edit, Delete } from "@material-ui/icons/"
import ContentChecker from '../../../shared/common/ContentChecker'
import useStyles from '../../../assets/jss/pages/voucher'
import Header from '../../../components/common/Header'
import { formNavigation } from '../../../functions/navigate'
import CustomTable from "../../../components/material/table/Table";
import CustomMenu from '../../../components/material/menu/Menu'
import { loader } from "../../../shared/common/LoadingScreen";
import { deleteFunc } from '../../../functions/delete'
import { setPage } from "../../../redux/actions/page";
import { getVouchersList, deleteVoucher } from '../../../redux/actions/voucher'

const ListVouchers = ({
    getVouchersList, deleteVoucher, setPage,
    voucher: { vouchersList, vouchersCount }
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const timer = useRef();
    let limit = 20
    let count = vouchersCount ? vouchersCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [loading, setLoading] = useState(false)

    const [disableClick, setDisableClick] = useState(false);

    useEffect(() => {
        getVouchersList(1)
    }, [getVouchersList]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getVouchersList(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header
                name='vouchers' nav='voucher'
                title='Voucher will only be added when an offer exists. Make sure you have an OFFER before creating a VOUCHER.'
            />

            {vouchersList ?
                <CustomTable
                    onClick={disableClick === false ? 'voucher' : false}
                    tableHead={['Number.', 'Name', 'Code', 'Usage', 'Start Date-Time', 'End Date-Time', 'Actions']}
                    tableData={vouchersList.map(voucher => (
                        {
                            cells: [
                                voucher.id,
                                voucher.name,
                                voucher.code,
                                voucher.usage,
                                voucher.startDate + '-' + voucher.startTime,
                                voucher.endDate + '-' + voucher.endTime,
                                <CustomMenu
                                    stateSetter={setDisableClick}
                                    button={<MoreVert />}
                                    menuItems={[
                                        {
                                            name: <Edit className={classes.edit} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                formNavigation(history, setPage, 'voucher', voucher.id, 'edit')
                                            }
                                        },
                                        {
                                            name: <Delete className={classes.delete} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                deleteFunc(deleteVoucher, voucher.id, alert)
                                            }
                                        }
                                    ]}
                                />
                            ],
                            uid: voucher.id
                        }
                    ))}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={vouchersList} />
            }
        </Container>
    )
}

ListVouchers.propTypes = {
    deleteVoucher: PropTypes.func.isRequired,
    getVouchersList: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    voucher: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getVouchersList, deleteVoucher, setPage })(ListVouchers)