import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import ContentChecker from '../../../shared/common/ContentChecker'
import OfferForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { showAlertFunc } from '../../../functions/showAlert';
import { setPage } from '../../../redux/actions/page'
import { getOfferDetail, updateOffer } from '../../../redux/actions/offer'
import { getAllBenefits } from '../../../redux/actions/benefit'
import { getAllConditions } from '../../../redux/actions/condition'
import { getAllRanges } from '../../../redux/actions/range'
import { initialState } from './State'

const EditOffer = ({
    getOfferDetail, updateOffer, getAllBenefits, getAllConditions, getAllRanges, setPage,
    offer: { offerDetail },
    benefit: { benefitsList },
    condition: { conditionsList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let id = window.location.pathname.split('/')[2]

    useEffect(() => {
        getAllRanges()
        getAllConditions()
        getAllBenefits()
        if (id) {
            getOfferDetail(id)
        }
    }, [getOfferDetail, getAllBenefits, getAllConditions, getAllRanges, id])

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (offerDetail) {
            setFormData({
                ...formData,
                id: offerDetail?.id,
                name: offerDetail?.name,
                description: offerDetail?.description,
                offer_type: offerDetail?.offer_type,
                exclusive: offerDetail?.exclusive,
                condition: offerDetail?.condition,
                benefit: offerDetail?.benefit
            })
        }
        // eslint-disable-next-line
    }, [offerDetail])

    const onSubmit = e => {
        e.preventDefault()

        formData.exclusive = formData.exclusive === true ? true : false

        updateOffer(formData, offerDetail && offerDetail.id, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Offer updated successfully`, { type: "success" })
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='offer' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {conditionsList ?
                    benefitsList ?
                        (formData?.id && id && offerDetail) ?
                            <OfferForm
                                formData={formData}
                                setFormData={setFormData}
                                onSubmit={onSubmit}
                            />
                            :
                            <ContentChecker reducerName={offerDetail} />
                        :
                        <ContentChecker reducerName={benefitsList} />
                    :
                    <ContentChecker reducerName={conditionsList} />
                }
                {conditionsList === 0 ?
                    showAlertFunc('Do add condition before adding an offer', history, setPage, 'condition')
                    :
                    benefitsList === 0 ? showAlertFunc('Do add benefits before adding an offer', history, setPage, 'benefit')
                        :
                        null
                }
            </Container>
        </Container>
    )
}

EditOffer.propTypes = {
    getOfferDetail: PropTypes.func.isRequired,
    updateOffer: PropTypes.func.isRequired,
    getAllBenefits: PropTypes.func.isRequired,
    getAllConditions: PropTypes.func.isRequired,
    getAllRanges: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    offer: PropTypes.object.isRequired,
    benefit: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,

};

export default connect(mapStateToProps, { getOfferDetail, updateOffer, getAllBenefits, getAllConditions, getAllRanges, setPage })(EditOffer)