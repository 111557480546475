import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core"
import SelectField from '../../../components/material/inputFields/SelectField'
import AutocompleteField from '../../../components/material/inputFields/AutocompleteField'
import InputField from '../../../components/material/inputFields/InputField'
import { onChange, filterById } from '../../../functions/onChange'
import Buttons from '../../../components/common/Buttons'
import { navigate } from '../../../functions/navigate'
import { setPage } from '../../../redux/actions/page'

const VoucherForm = ({
    formData, setFormData, onSubmit, setPage,
    offer: { offersList },
}) => {
    const history = useHistory()

    const onCancel = () => {
        navigate(history, setPage, 'vouchers')
    }

    let offersArray = []
    let getOfferName = offersList && offersList.filter((offer) => {
        if (formData?.offers?.includes(offer.id)) {
            offersArray.push(offer.name)
        }
        return offersArray
    })

    let opt = [
        { id: 'Single use', name: 'Can be used once by one customer' },
        { id: 'Multi-use', name: 'Can be used multiple times by multiple customer' },
        { id: 'Once per customer', name: 'Can be used once per customer' },
    ]

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='name'
                        label='Name'
                        value={formData?.name}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='text'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        name='code'
                        label='Code'
                        value={formData?.code}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        type='text'
                        disabled={formData?.condition ? true : false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='start_datetime'
                        label='Start Date-Time'
                        value={`${new Date(formData?.start_datetime).getFullYear()}-${`${new Date(formData?.start_datetime).getMonth() +
                            1}`.padStart(2, 0)}-${`${new Date(formData?.start_datetime).getDate()}`.padStart(
                                2,
                                0
                            )}T${`${new Date(formData?.start_datetime).getHours()}`.padStart(
                                2,
                                0
                            )}:${`${new Date(formData?.start_datetime).getMinutes()}`.padStart(2, 0)}`}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='datetime-local'
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='end_datetime'
                        label='End Date-Time'
                        value={`${new Date(formData?.end_datetime).getFullYear()}-${`${new Date(formData?.end_datetime).getMonth() +
                            1}`.padStart(2, 0)}-${`${new Date(formData?.end_datetime).getDate()}`.padStart(
                                2,
                                0
                            )}T${`${new Date(formData?.end_datetime).getHours()}`.padStart(
                                2,
                                0
                            )}:${`${new Date(formData?.end_datetime).getMinutes()}`.padStart(2, 0)}`}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='datetime-local'
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SelectField
                        name='usage'
                        label='Usage'
                        value={formData?.usage}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={opt}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <AutocompleteField
                        name='offers'
                        label='Offers'
                        options={offersList && offersList.map(offer => offer.name)}
                        value={getOfferName ? offersArray && offersArray.map(val => val) : []}
                        onChange={(event, newValue) => {
                            setFormData({
                                ...formData, 'offers':
                                    filterById(newValue, offersList)
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <br />
            <Buttons onCancel={onCancel} />

        </form>
    )
}

VoucherForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    voucher: PropTypes.object.isRequired,
    offer: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(VoucherForm)