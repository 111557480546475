import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core/"
import InputField from '../../../components/material/inputFields/InputField'
import CheckBoxField from '../../../components/material/inputFields/CheckBoxField'
import Buttons from '../../../components/common/Buttons'
import { onChange } from '../../../functions/onChange'
import { navigate } from '../../../functions/navigate'
import ListModifierOption from '../options/List'
import { setPage } from '../../../redux/actions/page'

const GroupForm = ({
    formData, setFormData, onSubmit, setPage,
    page: { loadedPage }
}) => {
    const history = useHistory()

    const onCancel = () => {
        navigate(history, setPage, 'modifiers')
    }

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <InputField
                        name='name'
                        label='Name'
                        value={formData?.name}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='text'
                    />
                </Grid>

                <Grid item xs={6} sm={6}>
                    <CheckBoxField
                        name='required' label='Mandatory' value={formData?.required}
                        onChange={(e) => onChange(e, formData, setFormData)}
                    />
                </Grid>

                <Grid item xs={6} sm={6}>
                    <CheckBoxField
                        name='type' label='Multiple' value={formData?.type}
                        onChange={(e) => onChange(e, formData, setFormData)}
                    />
                </Grid>

                <Grid item xs={6} sm={12}>
                    {formData?.type === true &&
                        <InputField
                            name='limit'
                            label='Limit'
                            value={formData?.limit}
                            onChange={(e) => onChange(e, formData, setFormData)}
                            type='number'
                        />
                    }
                </Grid>

                <Grid item xs={12}>
                    {loadedPage === 'modifier-group-edit' &&
                        <ListModifierOption />
                    }
                </Grid>

            </Grid>
            <br />
            <Buttons onCancel={onCancel} />
        </form>
    )
}

GroupForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps,
    { setPage }
)(GroupForm)