import React from 'react'
import { withRouter } from 'react-router-dom'
import { Line } from 'react-chartjs-2'

const LineChart = (props) => {
    const { labels, colors, dataSet, options } = props

    const data = {
        labels: labels,
        datasets: [
            {
                label: '',
                fill: true,
                backgroundColor: colors && colors.background, //'rgb(255, 99, 132, 0.2)',
                borderColor: colors && colors.border, //'rgba(255, 99, 132)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#fff',
                borderWidth: 1,
                data: dataSet,
            },
        ]
    };

    return (
        <Line data={data} options={options} height={72} />
    );
}

export default withRouter(LineChart);