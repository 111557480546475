import React, { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { Container } from "@material-ui/core/"
import { MoreVert, Edit, Delete } from "@material-ui/icons/"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import ContentChecker from '../../../shared/common/ContentChecker'
import useStyles from '../../../assets/jss/pages/voucher'
import Header from '../../../components/common/Header'
import { formNavigation } from '../../../functions/navigate'
import CustomTable from "../../../components/material/table/Table";
import CustomMenu from '../../../components/material/menu/Menu'
import { loader } from "../../../shared/common/LoadingScreen";
import { deleteFunc } from '../../../functions/delete'
import { setPage } from "../../../redux/actions/page";
import { getBenefitsList, deleteBenefit } from '../../../redux/actions/benefit'

const ListBenefits = ({
    getBenefitsList, deleteBenefit, setPage,
    benefit: { benefitsList, benefitsCount }
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const timer = useRef();
    let limit = 20
    let count = benefitsCount ? benefitsCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [loading, setLoading] = useState(false)

    const [disableClick, setDisableClick] = useState(false);

    useEffect(() => {
        getBenefitsList()
    }, [getBenefitsList]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getBenefitsList(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header
                name='benefits' nav='benefit'
                title='Make sure RANGE exists before creating a BENEFIT.'
            />
            {benefitsList ?
                <CustomTable
                    onClick={disableClick === false ? 'benefit' : false}
                    tableHead={['Number', 'Type', 'Value', 'Actions']}
                    tableData={benefitsList.map(benefit => (
                        {
                            cells: [
                                benefit.id,
                                benefit.type,
                                benefit.value,
                                <CustomMenu
                                    stateSetter={setDisableClick}
                                    button={<MoreVert />}
                                    menuItems={[
                                        {
                                            name: <Edit className={classes.edit} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                formNavigation(history, setPage, 'benefit', benefit.id, 'edit')
                                            }
                                        },
                                        {
                                            name: <Delete className={classes.delete} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                deleteFunc(deleteBenefit, benefit.id, alert)
                                            }
                                        }
                                    ]}
                                />
                            ], uid: benefit.id
                        }
                    ))}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={benefitsList} />
            }
        </Container>
    )
}

ListBenefits.propTypes = {
    getBenefitsList: PropTypes.func.isRequired,
    deleteBenefit: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    benefit: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getBenefitsList, deleteBenefit, setPage })(ListBenefits)