import { Box, Typography } from "@material-ui/core/"
import { Inbox } from '@material-ui/icons';
import Loader from 'react-loader-spinner'
import useStyles from "../../assets/jss/shared/loader";

const ContentChecker = (props) => {
    const classes = useStyles()

    const { reducerName } = props
    return (
        <Box className={classes.noData}>
            {reducerName === 0 ?
                <Box className={classes.noData} >
                    <Inbox className={classes.emptybox} />
                    <Typography>No items</Typography>
                </Box>
                :
                <Loader
                    type="ThreeDots"
                    color="#0E9AC4"
                    height={100}
                    width={100}
                    timeout={0}
                />
            }
        </Box>
    )
}

export default ContentChecker