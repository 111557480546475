import React, { useState } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Grid, Button, DialogActions, Divider } from "@material-ui/core/"
import Dialog from '../../components/material/dialog/Dialog'
import useStyles from "../../assets/jss/pages/settings"
import InputField from '../../components/material/inputFields/InputField'
import { changePassword } from '../../redux/actions/auth'

const ChangePassword = ({ show, hideOrderDialog, me, changePassword }) => {
    const classes = useStyles()
    const alert = useAlert();

    const [formData, setFormData] = useState({
        old_password: '',
        new_password1: '',
        new_password2: ''
    });

    const { old_password, new_password1, new_password2 } = formData

    const inputFields = [
        { label: "Current Password", name: "old_password", value: old_password, type: "password", required: true },
        { label: "New Password", name: "new_password1", value: new_password1, type: "password", required: true },
        { label: "Confirm Password", name: "new_password2", value: new_password2, type: "password", required: true },
    ]

    const onSubmit = async e => {
        e.preventDefault()
        if (new_password1 !== new_password2) {
            alert.show(' Password do not match', 'danger')
        }

        else {
            changePassword(formData)
                .then((success) => {
                    if (success) {
                        alert.show("Password changed successfully.", { type: "success" });
                        hideOrderDialog()
                    } else {
                        alert.show("Couldn't change password. Please try again later", { type: "error" });
                    }
                })
        }
    }

    return (

        <Dialog
            close={hideOrderDialog}
            open={show}
            dialogTitle='Change Password'
            dialogContent={
                <form onSubmit={(e) => onSubmit(e)}>
                    <small className={classes.subHeader}> Enter a new password for your account </small>
                    <Grid container spacing={2}>
                        <InputField formData={formData} setFormData={setFormData} fields={inputFields} />
                    </Grid>
                    <br />
                    <Divider />
                    <DialogActions>
                        <Button onClick={hideOrderDialog} className={classes.danger}>
                            Close
                        </Button>
                        <Button type='submit' className={classes.save}>
                            Save
                        </Button>
                    </DialogActions>
                </form>
            }
            detail={me && me}
        />

    )
}

ChangePassword.propTypes = {
    changePassword: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { changePassword })(ChangePassword)