import axios from 'axios';
import { apiUrl } from '../../shared/utils/fetcher'
import {
    GET_FILTERED_ORDERS, ORDERS_FOUND, ORDERS_NOT_FOUND, GET_ORDER, REMOVE_ORDER
} from '../types';
import { formatOrders, formatSingleOrder } from '../../shared/formatter/orderFormatter'

export const getAllOrders = (page) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}orders/?page=${page}`);
        let data = formatOrders(res.data.results);
        dispatch({
            type: ORDERS_FOUND,
            payload: {
                ordersList: data && data.length > 0 ? data : 0,
                ordersCount: res.data.count
            },
        })
        return true
    } catch (error) {
        console.debug(error);
        dispatch({
            type: ORDERS_NOT_FOUND,
        });
        return false
    }
}

export const getFilteredOrders = (query) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}orders/?search=${query}`)
        let data = formatOrders(res.data.results)
        dispatch({
            type: GET_FILTERED_ORDERS,
            payload: {
                filteredOrder: data && data.length > 0 ? data : 0,
                ordersCount: res.data.count
            },
        });
        return true
    } catch {
        dispatch({
            type: ORDERS_NOT_FOUND,
        });
        return false
    }
}

export const getOrder = (uid) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}orders/${uid}/`);
        // const detail = await axios.get(`${apiUrl}orders/${getIdFromUrl(url)}/booking/`);
        // let data = formatSingleOrder(res.data) + detail.data
        dispatch({
            type: GET_ORDER,
            payload: formatSingleOrder(res.data)
        });
        return true
    } catch {
        dispatch({
            type: REMOVE_ORDER,
        });
        return false
    }
}