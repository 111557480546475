import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { ExpandMore } from '@material-ui/icons';
import { navigate } from '../../../functions/navigate'
import useStyles from '../../../assets/jss/components/material/appbar'
import Menu from '../menu/Menu'
import { logout } from '../../../redux/actions/auth'
import { setPage } from "../../../redux/actions/page";

const DropDownLink = ({ logout, setPage, auth: { me } }) => {
    const classes = useStyles()
    const history = useHistory()

    // eslint-disable-next-line
    const [disableClick, setDisableClick] = useState(false);

    return (
        <Menu
            stateSetter={setDisableClick}
            button={
                <>
                    <img src={me.image} alt='logo' className={classes.avatar} />
                    &nbsp;<span className={classes.text}>{me.name} </span>&nbsp;<ExpandMore className={classes.icon} />
                </>
            }
            menuItems={[
                { name: 'Settings', handleItemClick: () => navigate(history, setPage, 'settings') },
                { name: 'Logout', handleItemClick: () => logout() }
            ]}
        />
    );
}

DropDownLink.propTypes = {
    logout: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { logout, setPage })(DropDownLink)