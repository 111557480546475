import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const deleteFunc = (deleteAction, idx, alert, listAction, id) => {
    confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    deleteAction(idx)
                        .then((success) => {
                            if (success) {
                                alert.show(`OK! item removed`, { type: "success" })
                                id && listAction(id)
                            }
                            else {
                                alert.show('Cannot remove the item. Please try again later', { type: "error" })
                            }
                        })
                }
            },
            {
                label: 'No',
                onClick: () => alert.show('Ok. Cancelled', { type: "error" })
            }
        ]
    });
}