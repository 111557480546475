import React from "react"
import { Button, Box } from "@material-ui/core/"
import useStyles from '../../assets/jss/components/common/buttons'

const Buttons = (props) => {
    const classes = useStyles()
    const { onCancel } = props

    return (
        <Box display="flex" flexDirection="row-reverse">
            <Box p={1}>
                <Button type='submit' className={classes.edit}>Save</Button>
            </Box>
            <Box p={1}>
                <Button onClick={() => onCancel()} className={classes.cancel}>Cancel</Button>
            </Box>
        </Box>
    )
}

export default Buttons