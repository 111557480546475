import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Typography } from "@material-ui/core/"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import AutocompleteField from '../../../components/material/inputFields/AutocompleteField'
import InputField from '../../../components/material/inputFields/InputField'
import Buttons from '../../../components/common/Buttons'
import { onChange, filterById } from '../../../functions/onChange'
import { formNavigation } from '../../../functions/navigate'
import { setPage } from '../../../redux/actions/page'

const OptionsForm = ({
    formData, setFormData, onSubmit,
    modifier: { modifierGroup },
    product: { productsList },
    page: { loadedPage }
}) => {
    const history = useHistory()
    const [modifierName, setModifierName] = useState('')

    useEffect(() => {
        if (modifierGroup && modifierGroup?.modifiers) {
            setModifierName(modifierGroup?.modifiers?.map(group => group.name))
        }
    }, [modifierGroup])

    const onCancel = () => {
        formNavigation(history, setPage, 'modifier-group', modifierGroup?.uid, 'edit')
    }

    const getProductPrice = () => {
        let productPrice = 0
        productPrice = productsList?.filter(prod =>
            (prod?.uid === formData?.product[0] || prod?.title === formData?.product)
        ).map(product => product.price)
        return (productPrice[0])
    }

    const productArray = []
    const getProducts = productsList.filter(prod => {
        if (!modifierName?.includes(prod.title)) {
            productArray.push(prod.title)
        }
        return productArray
    })

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>
                <Grid item xs={12} />
                <Grid item xs={12}>
                    <Typography
                        style={{ fontSize: '22px', fontWeight: 900 }}
                    >
                        {modifierGroup?.name?.toString()?.toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AutocompleteField
                        name='product'
                        label='Product'
                        single={true}
                        options={getProducts ? productArray?.map(product => product) : productsList?.map(product => product.title)}
                        value={formData?.product ? formData?.product : []}
                        required={true}
                        disabled={loadedPage === 'edit-modifier-options' ? true : false}
                        onChange={(event, newValue) => {
                            setFormData({
                                ...formData, 'product':
                                    filterById(newValue, productsList)
                            })
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='price'
                        label='Price'
                        value={formData?.price}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='number'
                    />
                </Grid>

                {formData?.product.length > 0 &&
                    <>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                            <Typography style={{ color: 'red' }}>
                                *Actual price of the selected product is : {getProductPrice()}
                            </Typography>
                        </Grid>
                    </>
                }
            </Grid>
            <br />
            <Buttons onCancel={onCancel} />
        </form>
    )
}

OptionsForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    modifier: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    setPage
})(OptionsForm)