import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Route, BrowserRouter, Switch } from "react-router-dom";
import { withStyles, Container, AppBar, Tabs, Tab, Typography } from "@material-ui/core/";
import ListVouchers from './voucher/List'
import ListOffers from './offer/List'
import ListBenefits from './benefit/List'
import ListConditions from './condition/List'
import ListRanges from './range/List'

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired
};


const FullWidthTabs = () => {

    const [value, setValue] = useState(0);

    useEffect(() => {
        let path = window.location.pathname;
        if (path === "/vouchers" && value !== 0) setValue(0);
        else if (path === "/offers" && value !== 1) setValue(1);
        else if (path === "/benefits" && value !== 2) setValue(2);
        else if (path === "/conditions" && value !== 3) setValue(3);
        else if (path === "/ranges" && value !== 4) setValue(4);
    }, [value])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <BrowserRouter>
            <Container maxWidth="xl">
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="vouchers" component={Link} to="/vouchers" />
                        <Tab label="offers" component={Link} to="/offers" />
                        <Tab label="benefits" component={Link} to="/benefits" />
                        <Tab label="conditions" component={Link} to="/conditions" />
                        <Tab label="ranges" component={Link} to="/ranges" />
                    </Tabs>
                </AppBar>

                <Switch>
                    <Route path="/vouchers" component={PageShell(Voucher)} />
                    <Route path="/offers" component={PageShell(Offer)} />
                    <Route path="/benefits" component={PageShell(Benefit)} />
                    <Route path="/conditions" component={PageShell(Condition)} />
                    <Route path="/ranges" component={PageShell(Range)} />
                </Switch>
            </Container>
        </BrowserRouter>
    );
}

FullWidthTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};

function Voucher() {
    return (
        <ListVouchers />
    );
}

function Offer() {
    return (
        <ListOffers />
    );
}

function Benefit() {
    return (
        <ListBenefits />
    );
}

function Condition() {
    return (
        <ListConditions />
    );
}

function Range() {
    return (
        <ListRanges />
    );
}

const PageShell = (Page, previous) => {
    return props => (
        <Page {...props} />
    );
};

export default withStyles(null, { withTheme: true })(FullWidthTabs);