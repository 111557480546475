import React from 'react';
import { Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import useStyles from '../../../assets/jss/components/material/card'

const CustomCard = (props) => {
    const classes = useStyles();

    const { header, media, content, action } = props

    return (
        <Card className={classes.root}>
            {header &&
                <CardHeader
                    avatar={header.avatar &&
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {header.avatar}
                        </Avatar>
                    }
                    action={header.action &&
                        <IconButton aria-label="settings">
                            <MoreVert />
                        </IconButton>
                    }
                    title={header.title}
                    subheader={header.subHeader}
                />
            }
            {media &&
                <CardMedia
                    className={classes.media}
                    image={media.image}
                    title={media.title}
                />
            }
            {content &&
                <CardContent className={classes.content}>
                    {content}
                </CardContent>
            }
            {action &&
                <CardActions>
                    {action}
                </CardActions>
            }
        </Card>
    );
}

export default CustomCard