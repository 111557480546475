import {
    CONDITIONS_FOUND, CONDITIONS_NOT_FOUND,
    GET_CONDITION, REMOVE_CONDITION,
    DELETE_CONDITION
} from '../types'

const initialState = {
    conditionsList: null,
    conditionsCount: 0,
    conditionDetail: null
}

const condition = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case CONDITIONS_FOUND:
            return {
                ...state,
                conditionsList: payload.conditionsList,
                conditionsCount: payload.conditionsCount,
                conditionDetail: null
            }
        case CONDITIONS_NOT_FOUND:
            return {
                ...state,
                conditionsList: null,
                conditionsCount: 0,
                conditionDetail: null
            }
        case DELETE_CONDITION:
            let updatedList = state.conditionsList.filter((item) => item.id !== payload).map(items => items);
            let count = updatedList?.length
            return {
                ...state,
                conditionsList: updatedList,
                conditionsCount: count,
                conditionDetail: null
            }
        case GET_CONDITION:
            return {
                ...state,
                conditionDetail: payload
            }
        case REMOVE_CONDITION:
            return {
                ...state,
                conditionDetail: null
            }
        default:
            return state
    }
}

export default condition