import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTableCell from "@material-ui/core/TableCell";
import {
    themeBackground,
    blackColor,
    borderColor,
    contrastColor,
    whiteColor,
    roseColor
} from "../../colors";

const TableCell = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8'
    }
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
    tableResponsive: {
        background: themeBackground,
        marginTop: theme.spacing(1),
        borderRadius: '8px',
        border: `1px solid ${borderColor[1]}`,
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },
    table: {
        minWidth: 240,
        justifyContent: "center",
        alignSelf: 'center',
        borderRadius: '8px',
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        }
    },
    head: {
        background: borderColor[0],
    },
    tableHeadRow: {
        color: blackColor,
        display: "table-row",
        outline: "none",
        justifyContent: "center",
        alignSelf: 'center',
        textAlign: 'left',
        verticalAlign: "middle",
        opacity: 0.8,
        padding: theme.spacing(0, 2, 2, 2),
    },
    tableHeadCell: {
        justifyContent: "center",
        alignSelf: 'center',
        alignItems: "center",
        color: blackColor,
        fontWeight: 600,
        "&:hover": {
            color: roseColor,
        },
        "&, &$tableCell": {
            fontSize: "1.2em"
        },
        [theme.breakpoints.down('xs')]: {
            "&, &$tableCell": {
                fontSize: "0.7em"
            },
        }
    },
    tableCell: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        padding: theme.spacing(1),
        verticalAlign: "middle",
        justifyContent: 'center',
        fontSize: "0.9rem",
        minWidth: '10%',
        color: blackColor,
        opacity: 0.8,
        "&:hover": {
            color: roseColor,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
        }
    },
    tableBodyRow: {
        color: blackColor,
        justifyContent: "center",
        alignSelf: 'center',
        textAlign: 'left',
        outline: "none",
        cursor: 'pointer',
        "&:hover": {
            background: contrastColor,
            color: whiteColor,
        },
    },
    sortableTableCell: {
        paddingLeft: '0.8rem',
        whiteSpace: "normal",
        wordWrap: "break-word",
        verticalAlign: "middle",
        alignSelf: 'center',
        textAlign: 'left',
        justifyContent: 'center',
        fontSize: "0.9rem",
        color: blackColor,
        opacity: 0.8,
        "&:hover": {
            color: roseColor,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.6rem",
            padding: '6px',
        }
    },
    sortableTableHeadRow: {
        color: blackColor,
        outline: "none",
        justifyContent: "center",
        alignSelf: 'center',
        textAlign: 'left',
        verticalAlign: "middle",
        opacity: 0.8,
    },
    grid: {
        margin: theme.spacing(1, 0, 2, 0),
        justifyContent: "space-between",
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            whiteSpace: "nowrap",
            display: 'block',
            justifyContent: "center",
            marginBottom: theme.spacing(1),
        }
    },
    pagination: {
        margin: theme.spacing(2),
    },
}))

export {
    useStyles,
    TableCell
}