import React from 'react';
import clsx from 'clsx';
import { Link, useHistory } from "react-router-dom";
import { Drawer, List, Divider, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import logo from '../../../assets/images/drop_logo.png'
import { items } from './SideItems'
import { whiteColor, blackColor } from '../../../assets/jss/colors'
import useStyles from '../../../assets/jss/components/material/sidebar'

const SideDrawer = (props) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: true,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: true,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <IconButton>
                    <img src={logo} alt='drop' width='80' height='40' />
                </IconButton>
            </div>
            <Divider style={{ backgroundColor: whiteColor }} />
            {items && items.map((item, index) => (
                <List
                    display="flex"
                    key={index}
                    style={{
                        backgroundColor: window.location.pathname === item.link || window.location.pathname.includes(item.includes) ? blackColor : "",
                    }}
                >
                    <Link to={item.link} className={classes.navlinks}>
                        {['Orders'].map((text) => (
                            <ListItem
                                key={text}
                                button
                                onClick={() => {
                                    history.push(item?.link?.replace("/", ''))
                                }}
                                display="flex"
                            >
                                <ListItemIcon className={classes.icons}>{item.icon}</ListItemIcon>
                                <ListItemText className={classes.icons}>{item.name}</ListItemText>
                            </ListItem>
                        ))}
                    </Link>
                </List>
            ))}
        </Drawer>
    );
}

export default SideDrawer