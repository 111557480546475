import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Table, TableHead, TableRow, TableBody, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles, TableCell } from '../../../assets/jss/components/material/table';
import { LoadingScreen } from "../../../shared/common/LoadingScreen";
import { formNavigation } from '../../../functions/navigate'
import { setPage } from "../../../redux/actions/page";

const CustomTable = ({ tableHead, tableData, handleChange, checkCount, activePage, search, loading, showDialog, onClick, setPage }) => {
    const classes = useStyles();
    const history = useHistory()

    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {loading && LoadingScreen()}
                {tableHead !== undefined ? (
                    <TableHead className={classes.head}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => (
                                <TableCell align={key === 1 ? 'left' : key === 2 ? 'left' : 'center'}
                                    className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}
                                >
                                    {prop}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData !== undefined && tableData.map((prop, key) => (
                        <TableRow key={key} className={classes.tableBodyRow}
                            onClick={() => showDialog ?
                                showDialog(prop?.uid) :
                                onClick && formNavigation(history, setPage, onClick, prop.uid, 'edit')}
                        >
                            {prop.cells && prop.cells.map((cell, index) => (
                                <TableCell align={index === 1 ? 'left' : index === 2 ? 'left' : 'center'} className={classes.tableCell} key={index}>
                                    {cell}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid container className={classes.grid}>
                <Grid item>
                    {''}
                </Grid>
                <Grid item className={classes.pagination} style={{ opacity: loading ? 0.1 : 1 }}>
                    {search ?
                        ''
                        :
                        <Pagination
                            count={checkCount}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            className={classes.pagination}
                            page={activePage}
                            onChange={handleChange}
                        />
                    }
                </Grid>
            </Grid>
        </div >
    );
}

CustomTable.propTypes = {
    setPage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setPage })(CustomTable)