export const SET_PAGE = 'SET_PAGE'
export const ERROR = 'ERROR'

//auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

//dashboard
export const GET_STATS = 'GET_STATS'
export const REMOVE_STATS = 'REMOVE_STATS'

export const GET_TOP_PRODUCTS = 'GET_TOP_PRODUCTS'
export const REMOVE_TOP_PRODUCTS = 'REMOVE_TOP_PRODUCTS'

export const GET_WEEKLY_ORDERS = 'GET_WEEKLY_ORDERS'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'

//orders
export const GET_FILTERED_ORDERS = 'GET_FILTERED_ORDERS'
export const ORDERS_FOUND = 'ORDERS_FOUND'
export const ORDERS_NOT_FOUND = 'ORDERS_NOT_FOUND'

export const GET_ORDER = 'GET_ORDER'
export const REMOVE_ORDER = 'REMOVE_ORDER'

//categories
export const GET_FILTERED_CATEGORIES = 'GET_FILTERED_CATEGORIES'
export const CATEGORY_FOUND = 'CATEGORY_FOUND'
export const CATEGORY_NOT_FOUND = 'CATEGORY_NOT_FOUND'

export const GET_CATEGORY = 'GET_CATEGORY'
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY'

export const DELETE_CATEGORY = 'DELETE_CATEGORY'

//Products
export const GET_FILTERED_PRODUCTS = 'GET_FILTERED_PRODUCTS'
export const PRODUCTS_FOUND = 'PRODUCTS_FOUND'
export const PRODUCTS_NOT_FOUND = 'PRODUCTS_NOT_FOUND'

export const GET_PRODUCT = 'GET_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'

export const DELETE_PRODUCT = 'DELETE_PRODUCT'

//Modifiers
export const GET_FILTERED_MODIFIERS = 'GET_FILTERED_MODIFIERS'
export const MODIFIERS_FOUND = 'MODIFIERS_FOUND'
export const MODIFIERS_NOT_FOUND = 'MODIFIERS_NOT_FOUND'

export const GET_MODIFIER_GROUP = 'GET_MODIFIER_GROUP'
export const REMOVE_MODIFIER_GROUP = 'REMOVE_MODIFIER_GROUP'

export const GET_MODIFIER_OPTION = 'GET_MODIFIER_OPTION'
export const REMOVE_MODIFIER_OPTION = 'REMOVE_MODIFIER_OPTION'

export const DELETE_MODIFIER_GROUP = 'DELETE_MODIFIER_GROUP'

//Ranges
export const RANGE_FOUND = 'RANGE_FOUND'
export const RANGE_NOT_FOUND = 'RANGE_NOT_FOUND'

export const GET_RANGE = 'GET_RANGE'
export const REMOVE_RANGE = 'REMOVE_RANGE'

export const DELETE_RANGE = 'DELETE_RANGE'

//Conditions
export const CONDITIONS_FOUND = 'CONDITIONS_FOUND'
export const CONDITIONS_NOT_FOUND = 'CONDITIONS_NOT_FOUND'

export const GET_CONDITION = 'GET_CONDITION'
export const REMOVE_CONDITION = 'REMOVE_CONDITION'

export const DELETE_CONDITION = 'DELETE_CONDITION'

//Benefits
export const BENEFITS_FOUND = 'BENEFITS_FOUND'
export const BENEFITS_NOT_FOUND = 'BENEFITS_NOT_FOUND'

export const GET_BENEFIT = 'GET_BENEFIT'
export const REMOVE_BENEFIT = 'REMOVE_BENEFIT'

export const DELETE_BENEFIT = 'DELETE_BENEFIT'

//Offers
export const OFFERS_FOUND = 'OFFERS_FOUND'
export const OFFERS_NOT_FOUND = 'OFFERS_NOT_FOUND'

export const GET_OFFER = 'GET_OFFER'
export const REMOVE_OFFER = 'REMOVE_OFFER'

export const DELETE_OFFER = 'DELETE_OFFER'

//Vouchers
export const VOUCHERS_FOUND = 'VOUCHERS_FOUND'
export const VOUCHERS_NOT_FOUND = 'VOUCHERS_NOT_FOUND'

export const GET_VOUCHER = 'GET_VOUCHER'
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER'

export const DELETE_VOUCHER = 'DELETE_VOUCHER'