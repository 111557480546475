import { confirmAlert } from 'react-confirm-alert';
import { HighlightOff } from "@material-ui/icons/"
import { navigate } from './navigate'
import { dangerColor } from '../assets/jss/colors'

export const showAlertFunc = (message, history, setPage, name) => {
    confirmAlert({
        title: <HighlightOff fontSize='large'
            style={{
                color: dangerColor,
                fontSize: '3rem'
            }}
        />,
        message: message,
        buttons: [{
            label: 'OK',
            onClick: () => { navigate(history, setPage, name, 'add') }
        }],
        closeOnClickOutside: true,
    });
}