import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Grid, Button, Zoom } from "@material-ui/core/" //Select, MenuItem
import { Add, Search } from "@material-ui/icons/"
import { navigate } from '../../functions/navigate'
import CustomTextField from '../material/inputFields/CustomTextField'
import { useStyles, HtmlTooltip } from '../../assets/jss/components/common/header'
import { setPage } from "../../redux/actions/page";
// import { getAllCategories } from "../../redux/actions/category";

const Header = ({
    doSeacrch, search, setSearch, name, setPage, nav, title,
    page: { loadedPage },
    // category: { categoriesList }
}) => {
    const classes = useStyles()
    const history = useHistory()

    // const [filterByCategory, setFilterByCategory] = useState('')

    // useEffect(() => {
    //     if (loadedPage === 'products') {
    //         getAllCategories()
    //     }
    // }, [loadedPage])

    var res = name && name.toUpperCase();

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    // const onChange = (e) => {
    //     setFilterByCategory(e.target.value)
    // }

    return (
        <Grid container className={classes.grid} >
            <Grid item>
                <h2 className={classes.header}>{res}</h2>
            </Grid>
            <Grid item className={classes.flexItems}>
                {/* {loadedPage === 'products' &&
                    <div className={classes.spacing}>
                        <Select
                            className={classes.input2}
                            variant='outlined'
                            fullWidth
                            placeholder={'Filter By Category'}
                            name={filterByCategory}
                            onChange={e => onChange(e)}
                            required
                        >
                            {categoriesList?.map((category, index) => (
                                <MenuItem key={index} name={category.name} value={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                } */}
                {doSeacrch &&
                    <div className={classes.spacing}>
                        <CustomTextField
                            icon={<Search fontSize="small" />}
                            name={'search'}
                            value={search}
                            placeholder='Search here...'
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                }
                <div className={classes.spacing}>
                    {loadedPage !== 'orders' &&
                        <HtmlTooltip arrow title={title ? title : ''} interactive TransitionComponent={Zoom}>
                            <Button
                                onClick={() => navigate(history, setPage, nav, 'add')} className={classes.add}>
                                <Add />
                            </Button>
                        </HtmlTooltip>

                    }
                </div>
            </Grid>
        </Grid >
    )
}

Header.propTypes = {
    // getAllCategories: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    // category: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(Header)