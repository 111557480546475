export const navigate = (history, setPage, name, method) => {
    if (method) {
        history.push(`/${name}/${method}`)
        setPage(name + '-' + method)
    }
    else {
        history.push(`/${name}`)
        setPage(name)
    }

}

export const formNavigation = (history, setPage, name, id, method, subName, subId) => {
    if (subId && subName && method && id) {
        history.push(`/${name}/${id}/${subName}/${subId}/${method}`)
        setPage(name + '-' + method)
    }
    else if (!subId && subName && method && id) {
        history.push(`/${name}/${id}/${subName}/${method}`)
        setPage(name + '-' + method)
    }
    else if (!subId && !subName && method && id) {
        history.push(`/${name}/${id}/${method}`)
        setPage(name + '-' + method)
    }
    else if (id && !method) {
        history.push(`/${name}/${id}`)
        setPage(name)
    }
    else {
        navigate(history, setPage, name)
    }
}