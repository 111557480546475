import React from "react"
import Dashboard from "../pages/dashboard/Dashboard"
import ListOrders from "../pages/orders/List";

import ListProducts from "../pages/products/List"
import CreateProduct from "../pages/products/Create";
import EditProduct from "../pages/products/Edit";

import ListCategories from "../pages/categories/List"
import CreateCategory from "../pages/categories/Create";
import EditCategory from "../pages/categories/Edit";

import ListModifiers from "../pages/modifiers/groups/List"

import CreateModifierGroup from "../pages/modifiers/groups/Create";
import EditModifierGroup from "../pages/modifiers/groups/Edit";

import CreateModifierOptions from "../pages/modifiers/options/Create";
import EditModifierOptions from "../pages/modifiers/options/Edit";

import VoucherTabs from "../pages/vouchers/VoucherTabs";

import CreateRange from "../pages/vouchers/range/Create";
import EditRange from "../pages/vouchers/range/Edit";

import CreateCondition from "../pages/vouchers/condition/Create";
import EditCondition from "../pages/vouchers/condition/Edit";

import CreateBenefit from "../pages/vouchers/benefit/Create";
import EditBenefit from "../pages/vouchers/benefit/Edit";

import CreateOffer from "../pages/vouchers/offer/Create";
import EditOffer from "../pages/vouchers/offer/Edit";

import CreateVoucher from "../pages/vouchers/voucher/Create";
import EditVoucher from "../pages/vouchers/voucher/Edit";

import Settings from "../pages/settings/Settings";
import EditBusinessHours from "../pages/settings/EditBusinessHours"
import EditBusinessInfo from "../pages/settings/EditBusinessInfo"
import ForgotPassword from "../layouts/ForgotPassword";

export const routes = [
    { path: "/signin", classComponent: "" },
    { path: "/forgot-password", classComponent: <ForgotPassword /> },

    { path: "/", classComponent: <Dashboard /> },
    { path: "/orders", classComponent: <ListOrders /> },

    { path: "/products", classComponent: <ListProducts /> },
    { path: "/product/add", classComponent: <CreateProduct /> },
    { path: "/product/:uid/edit", classComponent: <EditProduct /> },

    { path: "/categories", classComponent: <ListCategories /> },
    { path: "/category/add", classComponent: <CreateCategory /> },
    { path: "/category/:uid/edit", classComponent: <EditCategory /> },

    { path: "/modifiers", classComponent: <ListModifiers /> },

    { path: "/modifier-group/add", classComponent: <CreateModifierGroup /> },
    { path: "/modifier-group/:uid/edit", classComponent: <EditModifierGroup /> },

    { path: "/modifier-group/:uid/options/add", classComponent: <CreateModifierOptions /> },
    { path: "/modifier-group/:groupId/options/:optionsId/edit", classComponent: <EditModifierOptions /> },

    { path: "/ranges", classComponent: <VoucherTabs /> },
    { path: "/range/add", classComponent: <CreateRange /> },
    { path: "/range/:id/edit", classComponent: <EditRange /> },

    { path: "/conditions", classComponent: <VoucherTabs /> },
    { path: "/condition/add", classComponent: <CreateCondition /> },
    { path: "/condition/:id/edit", classComponent: <EditCondition /> },

    { path: "/benefits", classComponent: <VoucherTabs /> },
    { path: "/benefit/add", classComponent: <CreateBenefit /> },
    { path: "/benefit/:id/edit", classComponent: <EditBenefit /> },

    { path: "/offers", classComponent: <VoucherTabs /> },
    { path: "/offer/add", classComponent: <CreateOffer /> },
    { path: "/offer/:id/edit", classComponent: <EditOffer /> },

    { path: "/vouchers", classComponent: <VoucherTabs /> },
    { path: "/voucher/add", classComponent: <CreateVoucher /> },
    { path: "/voucher/:id/edit", classComponent: <EditVoucher /> },

    { path: "/settings", classComponent: <Settings /> },
    { path: "/business-hours/edit", classComponent: <EditBusinessHours /> },
    { path: "/business-information/edit", classComponent: <EditBusinessInfo /> },

]