import React from 'react'
import { withRouter } from 'react-router-dom'
import { Pie } from 'react-chartjs-2';

const PieChart = (props) => {
    const { labels, dataSet } = props

    let customLabels = labels && labels.map((label, index) => `${label}: ${dataSet[index]}`)
    const data = {
        labels: customLabels,
        datasets: [{
            data: dataSet,
            labels: labels,
            backgroundColor: [
                '#B8E886', '#3FD4A2', '#0D7DD9', '#C39FDF'
            ],
            hoverBackgroundColor: [
                '#B8E886',
                '#3FD4A2',
                '#0D7DD9',
                '#C39FDF'
            ],
            borderWidth: 1,
        }]
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            position: 'left',
            labels: {
                boxWidth: 20
            },

        },
    }
    return (
        <Pie data={data} options={options} />
    );
}

export default withRouter(PieChart);