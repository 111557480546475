import React, { useState } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Container, Grid, Paper, Button, Typography } from "@material-ui/core/"
import { Edit, Map, Room, LocalOffer } from "@material-ui/icons/"
import Card from '../../components/material/card/Card'
import ChangePassword from './ChangePassword'
import useStyles from "../../assets/jss/pages/settings"
import { navigate } from '../../functions/navigate'
import { setPage } from "../../redux/actions/page";

const Settings = ({
    setPage,
    auth: { me },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [show, setShow] = useState(false)

    const showDialog = () => {
        setShow(true)
    }

    const hideDialog = () => {
        setShow(false)
    };

    const days = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

    const userDetail = [
        { icon: <Map className={classes.icon} />, heading: 'Post Code', value: me.address && me.address.postcode },
        { icon: <Room className={classes.icon} />, heading: 'Address', value: me.address && me.address.place },
        { icon: <LocalOffer className={classes.icon} />, heading: 'Tags', value: me && me.tags?.map((tag) => tag.name + ', ') },
        { icon: <LocalOffer className={classes.icon} />, heading: 'Description', value: me.description }
    ]

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={5} md={4}>
                    <Container component={Paper} maxWidth="xl" elevation={3} className={classes.container}>
                        <div className={classes.header}>
                            <div className={classes.info}>
                                <img src={me.image} alt='cover' className={classes.logo} />
                                <div>
                                    <Typography className={classes.name}>{me && me.name}</Typography>
                                    <Typography className={classes.change} onClick={() => showDialog()}>Change Password</Typography>
                                </div>

                            </div>
                            <Button className={classes.edit} onClick={() => navigate(history, setPage, 'business-information', 'edit')}>
                                <Edit fontSize='small' />
                            </Button>
                        </div>

                        {userDetail && userDetail.map((detail, index) => (
                            <div className={classes.flexBox} key={index}>
                                {detail.icon}
                                <div>
                                    <Typography className={classes.heading}>{detail.heading}</Typography>
                                    <Typography className={classes.detail}>{detail.value}</Typography>
                                </div>
                            </div>
                        ))}

                    </Container>
                </Grid>
                <Grid item xs={12} sm={7} md={8}>
                    <Container component={Paper} maxWidth="xl" elevation={3} className={classes.container}>
                        <div className={classes.header}>
                            <h4>Business Hours</h4>
                            <Button className={classes.edit} onClick={() => navigate(history, setPage, 'business-hours', 'edit')}>
                                <Edit fontSize='small' />
                            </Button>
                        </div>
                        <Grid container spacing={2}>
                            {me?.opening_hours?.map((hours, index) => (
                                <Grid item xs={12} sm={4} key={index}>
                                    <Card
                                        style={{ background: '#F1F3F6' }}
                                        content={
                                            <div>
                                                <br />
                                                <Typography className={classes.heading}>{hours.weekday && days[hours.weekday]}</Typography>
                                                <Typography className={classes.detail}>{hours.start} - {hours.end}</Typography>
                                            </div>
                                        } />
                                </Grid>
                            )
                            )}
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            <ChangePassword show={show} hideOrderDialog={hideDialog} me={me} />
        </Container >
    )
}
Settings.propTypes = {
    setPage: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(Settings)