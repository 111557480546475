import { makeStyles } from '@material-ui/core/styles';
import { whiteColor } from '../../colors';

const useStyles = makeStyles(theme => ({
    label: {
        padding: theme.spacing(2, 0.5, 1, 0.5),
    },
    input: {
        background: whiteColor,
        width: '100%',
        fontSize: '15px',
        padding: theme.spacing(2),
        border: '1px solid #c5c5c5',
        borderRadius: '2px',
        "&:hover, &:focus": {
            border: '1px solid #192462',
        },
    },
    input2: {
        background: whiteColor,
        width: '100%',
        fontSize: '15px',
    },
    chip: {
        margin: theme.spacing(0.5),
        textDecoration: 'none',
        listStyle: 'none'
    },
}));

export default useStyles
