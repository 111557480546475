import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import CrudHeader from '../../../components/common/CrudHeader'
import ContentChecker from '../../../shared/common/ContentChecker'
import RangeForm from './Form'
import { navigate } from '../../../functions/navigate'
import { addRange } from '../../../redux/actions/range'
import { getAllCategories } from '../../../redux/actions/category'
import { setPage } from '../../../redux/actions/page'
import { initialState } from './State'

const CreateRange = ({
    addRange, getAllCategories, setPage,
    category: { categoriesList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();

    useEffect(() => {
        getAllCategories()
    }, [getAllCategories])

    const [formData, setFormData] = useState(initialState)

    const onSubmit = e => {
        e.preventDefault()

        formData.is_public = formData?.is_public === true ? true : false
        formData.includes_all_products = formData?.includes_all_products === true ? true : false

        addRange(formData, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Range added successfully`, { type: "success" })
                    navigate(history, setPage, 'ranges')
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='range' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {categoriesList ?
                    <RangeForm
                        formData={formData}
                        setFormData={setFormData}
                        onSubmit={onSubmit}
                    />
                    :
                    <ContentChecker reducerName={categoriesList} />
                }
            </Container>
        </Container>
    )
}

CreateRange.propTypes = {
    addRange: PropTypes.func.isRequired,
    getAllCategories: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { addRange, getAllCategories, setPage })(CreateRange)