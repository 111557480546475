import React from "react"
import { Select, MenuItem, InputLabel } from "@material-ui/core/"
import useStyles from '../../../assets/jss/components/material/inputFields'

const SelectField = (props) => {
    const classes = useStyles()
    const { name, label, value, onChange, required, options } = props

    return (
        <>
            <InputLabel className={classes.label}>{label}</InputLabel>
            <Select
                className={classes.input2}
                variant='outlined'
                fullWidth
                placeholder={label}
                name={name}
                defaultValue={value}
                onChange={onChange}
                required={required ? true : false}
            >
                {options?.map((option, index) => (
                    <MenuItem key={index} name={name} value={option.id}>{option.title || option.name}</MenuItem>
                ))}
            </Select>
        </>
    )
}

export default SelectField