import {
    GET_FILTERED_ORDERS, ORDERS_FOUND, ORDERS_NOT_FOUND, GET_ORDER, REMOVE_ORDER,
} from '../types'

const initialState = {
    filteredOrder: null,
    ordersList: null,
    ordersCount: 0,
    orderDetail: null,
}

const orders = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_FILTERED_ORDERS:
            return {
                ...state,
                filteredOrder: payload.filteredOrder,
                ordersCount: payload.ordersCount,
                orderDetail: null,
            }
        case ORDERS_FOUND:
            return {
                ...state,
                ordersList: payload.ordersList,
                ordersCount: payload.ordersCount,
                orderDetail: null,
            }
        case ORDERS_NOT_FOUND:
            return {
                ordersList: null,
                ordersCount: 0
            }
        case GET_ORDER:
            return {
                ...state,
                orderDetail: payload
            }
        case REMOVE_ORDER:
            return {
                ...state,
                orderDetail: null
            }
        default:
            return state;
    }
}

export default orders