import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core/"
import { useStyles } from '../../assets/jss/components/common/header'

const CrudHeader = ({
    name,
    page: { loadedPage }
}) => {
    const classes = useStyles()

    var res = name && name.toUpperCase();

    return (
        <Grid container className={classes.grid}>
            <Grid item>
                <h2 className={classes.header}>{loadedPage?.includes('add') ? 'ADD ' : 'EDIT '}{res}</h2>
            </Grid>
        </Grid >

    )
}

CrudHeader.propTypes = {
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(CrudHeader)