import React from "react"
import { Typography } from "@material-ui/core/"

const CustomerDetails = (props) => {
    const { orderDetail } = props

    return (
        orderDetail && orderDetail.shipping_address && orderDetail.shipping_address.notes ?
            <Typography>{orderDetail && orderDetail.shipping_address && orderDetail.shipping_address.notes}</Typography>
            :
            <Typography>No notes</Typography>
    )
}

export default CustomerDetails