import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import ContentChecker from '../../../shared/common/ContentChecker'
import CrudHeader from '../../../components/common/CrudHeader'
import ConditionForm from './Form'
import { showAlertFunc } from '../../../functions/showAlert';
import { setPage } from '../../../redux/actions/page'
import { getAllRanges } from '../../../redux/actions/range'
import { getConditionDetail, updateCondition } from '../../../redux/actions/condition'
import { initialState } from './State'

const EditCondition = ({
    getConditionDetail, updateCondition, getAllRanges, setPage,
    condition: { conditionDetail },
    range: { rangesList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let id = window.location.pathname.split('/')[2]

    useEffect(() => {
        getAllRanges()
        if (id) {
            getConditionDetail(id)
        }
    }, [getAllRanges, getConditionDetail, id])

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (conditionDetail) {
            setFormData({
                ...formData,
                id: conditionDetail?.id,
                type: conditionDetail?.type,
                value: conditionDetail?.value,
                range: conditionDetail?.range,
            })
        }
        // eslint-disable-next-line
    }, [conditionDetail])

    const onSubmit = e => {
        e.preventDefault()

        updateCondition(formData, conditionDetail && conditionDetail.id)
            .then((success) => {
                if (success) {
                    alert.show(`Condition updated successfully`, { type: "success" })
                }
                else {
                    alert.show('Cannot update condition. Please try again later', { type: "error" })
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='condition' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {rangesList ?
                    (formData?.id && id && conditionDetail) ?
                        <ConditionForm
                            formData={formData}
                            setFormData={setFormData}
                            onSubmit={onSubmit}
                        />
                        :
                        <ContentChecker reducerName={conditionDetail} />
                    :
                    <ContentChecker reducerName={rangesList} />
                }
                {rangesList === 0 && showAlertFunc('Do add range before adding conditions', history, setPage, 'range')}
            </Container>
        </Container>
    )
}

EditCondition.propTypes = {
    getConditionDetail: PropTypes.func.isRequired,
    updateCondition: PropTypes.func.isRequired,
    getAllRanges: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    condition: PropTypes.object.isRequired,
    range: PropTypes.object.isRequired,

};

export default connect(mapStateToProps, { getConditionDetail, updateCondition, getAllRanges, setPage })(EditCondition)