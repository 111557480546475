import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import StatCards from './StatCards'
import MainCards from './MainCards'
import useStyles from '../../assets/jss/pages/dashboard'
import { getStats, getTopProducts, getWeeklyOrders } from '../../redux/actions/stats'
// import { getNotifications } from '../../redux/actions/notification'

const Dashboard = ({ getStats, getTopProducts, getWeeklyOrders, auth: { me } }) => {
    const classes = useStyles()

    useEffect(() => {
        getStats()
        getTopProducts()
        getWeeklyOrders()
        // getNotifications(me && me.id)
    }, [getStats, getTopProducts, getWeeklyOrders, me])

    return (
        <Container maxWidth="xl" className={classes.root}>
            <StatCards />
            <MainCards />
        </Container>
    )
}

Dashboard.propTypes = {
    getStats: PropTypes.func.isRequired,
    getTopProducts: PropTypes.func.isRequired,
    getWeeklyOrders: PropTypes.func.isRequired,
    // getNotifications: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getStats, getTopProducts, getWeeklyOrders })(Dashboard)