import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    noData: {
        justifyContent: 'center',
        alignSelf: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(15),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(7),
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
        },
        fontWeight: 400,
        textAlign: 'center',
        color: '#323232',
        fontSize: '30px',
    },
    emptybox: {
        color: '#AAAAAA',
        fontSize: '20rem'
    },
}));

export default useStyles
