import {
    VOUCHERS_FOUND, VOUCHERS_NOT_FOUND,
    GET_VOUCHER, REMOVE_VOUCHER,
    DELETE_VOUCHER
} from '../types'

const initialState = {
    vouchersList: null,
    vouchersCount: null,
    voucherDetail: null
}

const voucher = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case VOUCHERS_FOUND:
            return {
                ...state,
                vouchersList: payload.vouchersList,
                vouchersCount: payload.vouchersCount,
                voucherDetail: null
            }
        case VOUCHERS_NOT_FOUND:
            return {
                ...state,
                vouchersList: null,
                vouchersCount: 0
            }
        case DELETE_VOUCHER:
            let updatedList = state.vouchersList.filter((item) => item.id !== payload).map(items => items);
            let count = updatedList?.length
            return {
                ...state,
                vouchersList: updatedList,
                vouchersCount: count,
                voucherDetail: null
            }
        case GET_VOUCHER:
            return {
                ...state,
                voucherDetail: payload
            }
        case REMOVE_VOUCHER:
            return {
                ...state,
                voucherDetail: null
            }
        default:
            return state
    }
}

export default voucher