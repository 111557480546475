import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core"
import SelectField from "../../../components/material/inputFields/SelectField";
import CheckBoxField from "../../../components/material/inputFields/CheckBoxField";
import InputField from '../../../components/material/inputFields/InputField'
import Buttons from '../../../components/common/Buttons'
import { onChange } from '../../../functions/onChange'
import { navigate } from '../../../functions/navigate'
import { setPage } from '../../../redux/actions/page'

const OfferForm = ({
    formData, setFormData, onSubmit, setPage,
    benefit: { benefitsList },
    condition: { conditionsList },
    page: { loadedPage },
}) => {
    const history = useHistory()

    const onCancel = () => {
        navigate(history, setPage, 'offers')
    }

    let opt = [
        { id: 'Site', name: `Site offer - available to all users` },
        { id: 'Voucher', name: `Voucher offer - only available after entering the appropriate voucher code` },
        { id: 'User', name: 'User offer - available to certain types of user' },
        { id: 'Session', name: `Session offer - temporary offer, available for a user for the duration of their session` },
    ]

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='name'
                        label='Name'
                        value={formData?.name}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='text'
                        disabled={loadedPage === 'edit-offer' ? true : false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='description'
                        label='Description'
                        value={formData?.description}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        type='text'
                    />
                </Grid>

                <Grid item xs={12}>
                    <SelectField
                        name='offer_type'
                        label='Offer Type'
                        value={formData?.offer_type}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={opt}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SelectField
                        name='condition'
                        label='Condition'
                        value={formData?.condition}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={conditionsList}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SelectField
                        name='benefit'
                        label='Benefit'
                        value={formData?.benefit}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={benefitsList}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CheckBoxField
                        name='exclusive'
                        label='Exclusive offers cannot be combined on the same items'
                        value={formData?.exclusive}
                        onChange={(e) => onChange(e, formData, setFormData)}
                    />
                </Grid>
            </Grid>
            <br />
            <Buttons onCancel={onCancel} />

        </form>
    )
}

OfferForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    benefit: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(OfferForm)