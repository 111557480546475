import axios from 'axios';
import { apiUrl } from "../../shared/utils/fetcher";
import {
    VOUCHERS_FOUND, VOUCHERS_NOT_FOUND,
    GET_VOUCHER, REMOVE_VOUCHER,
    DELETE_VOUCHER
} from '../types'
import { formatVouchers, formatSingleVoucher } from '../../shared/formatter/voucherFormatter'

let voucherUrl = `${apiUrl}/me/vouchers`

export const getVouchersList = (page) => async dispatch => {
    try {
        const res = await axios.get(`${voucherUrl}/?page=${page ? page : 1}`)
        let data = formatVouchers(res.data.results)
        dispatch({
            type: VOUCHERS_FOUND,
            payload: {
                vouchersList: data && data.length > 0 ? data : 0,
                vouchersCount: res.data.count
            }
        });
        return true;
    } catch {
        dispatch({
            type: VOUCHERS_NOT_FOUND,
        });
        return false;
    }
}

export const getVoucherDetail = (id) => async dispatch => {
    try {
        const res = await axios.get(`${voucherUrl}/${id}/`)
        let data = formatSingleVoucher(res.data)
        dispatch({
            type: GET_VOUCHER,
            payload: data
        });
        return true;
    } catch {
        dispatch({
            type: REMOVE_VOUCHER,
        });
        return false;
    }
}

export const addVoucher = (formData, alert) => async dispatch => {
    let body = formData
    try {
        await axios.post(`${voucherUrl}/`, body)
        return true;
    } catch (error) {
        let err = error.response
        if (err?.status === 500) {
            alert.show('Voucher creation failed. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
        return false;
    }
}

export const updateVoucher = (formData, id, alert) => async dispatch => {
    let body = formData
    try {
        await axios.put(`${voucherUrl}/${id}/`, body)
        return true;
    } catch (error) {
        let err = error.response
        console.log(err)
        if (err?.status === 500) {
            alert.show('Cannot update voucher. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
    }
}

export const deleteVoucher = (id) => async dispatch => {
    try {
        await axios.delete(`${voucherUrl}/${id}/`)
        dispatch({
            type: DELETE_VOUCHER,
            payload: id
        })
        return true;
    } catch {
        return false
    }
}
