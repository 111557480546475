import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/main.css";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import { Provider } from "react-redux";
import store from "./redux/store";

import Routes from "./routing/Routes";

const options = {
    position: positions.BOTTOM_RIGHT,
    timeout: 5000,
    offset: "30px",
    transition: transitions.FADE,
};

const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AlertProvider template={AlertTemplate} {...options}>
                    <Routes />
                </AlertProvider>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
