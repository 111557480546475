import { makeStyles } from '@material-ui/core/styles';
import {
    grayColor,
    primaryColor,
    borderColor
} from "../../colors";

const useStyles = makeStyles(theme => ({
    icon: {
        alignSelf: 'center',
        color: primaryColor
    },
    field: {
        textAlign: 'center',
        margin: theme.spacing(0.5)
    },
    paper: {
        background: 'transparent !important',
        border: `1px solid ${borderColor[1]}`,
        padding: theme.spacing(0.1, 1.5),
        display: 'flex',
        borderRadius: '6px'
    },
    icon2: {
        alignSelf: 'center',
        justifyContent: 'center',
        color: grayColor,
        borderRight: `1px solid ${borderColor[1]}`,
        padding: theme.spacing(1, 1, 0.3, 0.3),
    },
    field2: {
        textAlign: 'center',
        margin: theme.spacing(0.25, 1),
        padding: theme.spacing(0.5, 1, 0.25, 1)
    },
}));

export default useStyles
