const infoColor = '#9c27b0';
const warningColor = '#ff9800';
const dangerColor = 'rgb(208 103 103)';
const successColor = '#4caf50';
const primaryColor = '#0D94BC';
const roseColor = '#e91e63';
const grayColor = '#999';
const shadowColor = '#00000029';
const blackColor = '#000000';
const darkGreyShades = ["#000000", "#1b1b1b", "#373737", "#494949", "#707070", "#8d8d8d", "#373737", "#bcbcbc", "#c2c2c2", "#c7c7c7"];
const lightGreyShades = ["#484848", "#6d6d6d", "#8e8e8e", "#a4a4a4", "#cfcfcf", "#efefef", "#ffffff"];
const mediumGreyShades = ["#212121", "#424242", "#616161", "#757575", "#9e9e9e", "#bdbdbd", "#e0e0e0", "#eeeeee", "#f5f5f5", "#fafafa"];
const whiteColor = '#FFFFFF';
const themeBackground = '#F1F3F6';
const themeGradient = 'linear-gradient(30deg, #80d8f3,  #85c1d3, #0E9AC4, #2A2A2A)';
const sideBarColor = '#2A2A2A';
const contrastColor = '#45BEE2';
const headerText = ['#393A39', '#545454', '#8293A6', '#989898', '#595959', '#3FD4A2'];
const borderColor = ['#DEDEDE', '#D2D2D2']

function randomColors() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  shadowColor,
  blackColor,
  darkGreyShades,
  mediumGreyShades,
  lightGreyShades,
  whiteColor,
  themeBackground,
  themeGradient,
  sideBarColor,
  contrastColor,
  headerText,
  borderColor,
  randomColors
};
