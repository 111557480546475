import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LoadingScreen } from "../shared/common/LoadingScreen"
import ForgotPassword from "../layouts/ForgotPassword";
import Signin from "../layouts/Signin";
import { useAlert } from "react-alert";

import store from "../redux/store";

import { verifyUser } from "../redux/actions/auth";
import { setPage } from "../redux/actions/page";

import { path } from '../functions/path'

const CustomRoute = ({
    component: Component,
    auth: { isAuthenticated },
    ...rest
}) => {
    const alert = useAlert()
    const [authCheck, setAuthCheck] = useState(false);

    useEffect(() => {
        store.dispatch(verifyUser(alert)).then((success) => {
            console.debug("Verifying user")
            if (success) {
                console.debug("User verified")
                let currentPage = path(window.location.pathname);
                store.dispatch(setPage(currentPage))
            }
            else if (window.location.pathname.split('/')[1] === 'forgot-password') {
                console.debug("Forgot password")
                store.dispatch(setPage("/forgot-password"));
            }
            else {
                console.debug("User verification failed")
                store.dispatch(setPage("/signin"));
            }
            setAuthCheck(true);
        });
    }, [alert]);

    if (!authCheck) {
        return <Route {...rest} render={(props) => <LoadingScreen />} />;
    } else {
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? <Component {...props} /> :
                        window.location.pathname.split('/')[1] === 'forgot-password' ?
                            <ForgotPassword /> : <Signin />
                }
            />
        );
    }
};

CustomRoute.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(CustomRoute);