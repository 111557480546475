import {
    GET_NOTIFICATIONS
} from '../types'

const initialState = {
    notifications: null,
}

const offer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload
            }
        default:
            return state
    }
}

export default offer