import { GET_FILTERED_CATEGORIES, CATEGORY_FOUND, CATEGORY_NOT_FOUND, GET_CATEGORY, REMOVE_CATEGORY, DELETE_CATEGORY } from '../types'

const initialState = {
    filteredCategory: null,
    categoriesList: null,
    categoryDetail: null,
}

const category = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_FILTERED_CATEGORIES:
            return {
                ...state,
                filteredCategory: payload.filteredCategory,
                categoryDetail: null,
            }
        case CATEGORY_FOUND:
            return {
                ...state,
                categoriesList: payload.categoriesList,
                categoryDetail: null,
            }
        case CATEGORY_NOT_FOUND:
            return {
                ...state,
                categoriesList: null,
                categoryDetail: null,
            }
        case DELETE_CATEGORY:
            let updatedList = state.categoriesList.filter((item) => item.uid !== payload).map(items => items);
            return {
                ...state,
                categoriesList: updatedList,
                categoryDetail: null,
            }
        case GET_CATEGORY:
            return {
                ...state,
                categoryDetail: payload
            }
        case REMOVE_CATEGORY:
            return {
                ...state,
                categoryDetail: null,
            }
        default:
            return state;
    }
}

export default category