import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { Container } from "@material-ui/core/"
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import ProductForm from './Form'
import CrudHeader from '../../components/common/CrudHeader'
import { useStyles } from "../../assets/jss/pages/products"
import { navigate } from '../../functions/navigate'
import { addProduct } from "../../redux/actions/product";
import { getAllCategories } from "../../redux/actions/category"
import { getAllModifiers } from "../../redux/actions/modifier"
import { setPage } from "../../redux/actions/page"
import { initialState } from './State'

const CreateProduct = ({
    addProduct, getAllModifiers, getAllCategories, setPage,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        getAllCategories()
        getAllModifiers()
    }, [getAllModifiers, getAllCategories])

    const onSubmit = e => {
        e.preventDefault()

        formData.is_public = formData?.is_public === true ? true : false

        addProduct(formData)
            .then((success) => {
                if (success) {
                    alert.show('Product added successfully', { type: "success" })
                    navigate(history, setPage, 'products')
                }
                else {
                    alert.show('Product creation failed. Please try again later', { type: "error" })
                }
            });
    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='product' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                <ProductForm
                    formData={formData}
                    setFormData={setFormData}
                    onSubmit={onSubmit}
                />
            </Container>
        </Container>
    )
}

CreateProduct.propTypes = {
    addProduct: PropTypes.func.isRequired,
    getAllModifiers: PropTypes.func.isRequired,
    getAllCategories: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
    addProduct, getAllModifiers, getAllCategories, setPage
})(CreateProduct)