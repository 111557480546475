import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Container, Chip, Tooltip } from "@material-ui/core/"
import { loader } from "../../shared/common/LoadingScreen";
import ContentChecker from '../../shared/common/ContentChecker'
import CustomTable from "../../components/material/table/Table";
import ShowOrder from './Show'
import { getList } from '../../functions/search'
import Header from '../../components/common/Header'
import { useStyles } from "../../assets/jss/pages/order"
import { getFilteredOrders, getAllOrders, getOrder } from "../../redux/actions/order";

const ListOrders = ({
    getFilteredOrders, getAllOrders, getOrder,
    order: { filteredOrder, ordersList, ordersCount, orderDetail },
}) => {
    const classes = useStyles()
    const timer = useRef();
    let limit = 20
    let count = ordersCount ? ordersCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchOrder, setSearchOrder] = useState('');
    let currentList = searchOrder ? filteredOrder : ordersList

    useEffect(() => {
        getList(searchOrder, getFilteredOrders, getAllOrders)
    }, [getAllOrders, getFilteredOrders, searchOrder]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getAllOrders(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    const showOrderDialog = (uid) => {
        getOrder(uid)
        setShow(true)
    }

    const hideOrderDialog = () => {
        setShow(false)
    };

    const orderStatus = (order) => (
        <Tooltip title={order.status}>
            <Chip style={{
                width: '40% ',
                background: 'white',
                color:
                    order.status === "Cancelled" || order.status === "Vendor Cancelled" || order.status === "Delivery Cancelled" || order.status === "Timed Out"
                        ? "#AA2B2B"
                        : order.status === "Ready For Delivery"
                            ? "#85B300"
                            : order.status === "Pending"
                                ? "#EFBC02"
                                : order.status === "Vendor Confirmed"
                                    ? "#BEAF13"
                                    : order.status === "Delivery Confirmed"
                                        ? "#9E7700"
                                        : "#B37200",
                border: `1px solid ${order.status === "Cancelled" || order.status === "Vendor Cancelled" || order.status === "Delivery Cancelled" || order.status === "Timed Out"
                    ? "#AA2B2B"
                    : order.status === "Ready For Delivery"
                        ? "#85B300"
                        : order.status === "Pending"
                            ? "#EFBC02"
                            : order.status === "Vendor Confirmed"
                                ? "#BEAF13"
                                : order.status === "Delivery Confirmed"
                                    ? "#9E7700"
                                    : "#B37200"
                    }`
            }}
                label={order.status}
            />
        </Tooltip>
    )

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header doSeacrch={true} search={searchOrder} setSearch={setSearchOrder} name='orders' />

            {currentList ?
                <CustomTable
                    tableHead={['Order #', 'Post Code', 'Name', 'Date-Time', 'Items', 'Amount', 'Status']}
                    tableData={currentList.map(order => (
                        {
                            cells: [
                                order.number,
                                order.postcode,
                                order.full_name,
                                order.date_placed,
                                order.lines && order.lines.length,
                                <span className={classes.primaryText}>{order.total_incl_tax}</span>,
                                orderStatus(order)

                            ], uid: order.uid, url: order.url
                        }
                    ))}
                    showDialog={showOrderDialog}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    search={searchOrder}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={searchOrder ? filteredOrder : ordersList} />
            }
            <ShowOrder show={show} hideOrderDialog={hideOrderDialog} orderDetail={orderDetail} />
        </Container>
    )
}

ListOrders.propTypes = {
    getFilteredOrders: PropTypes.func.isRequired,
    getAllOrders: PropTypes.func.isRequired,
    getOrder: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getFilteredOrders, getAllOrders, getOrder })(ListOrders)