import { makeStyles, Tooltip, withStyles } from '@material-ui/core';
import { headerText, primaryColor, whiteColor, borderColor } from '../../colors'

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        color: whiteColor,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(1),
        border: `2px solid ${borderColor[1]}`,
    },
}))(Tooltip);

export const useStyles = makeStyles(theme => ({
    grid: {
        margin: theme.spacing(1, 0, 2, 0),
        justifyContent: "space-between",
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            whiteSpace: "nowrap",
            display: 'block',
            justifyContent: "center",
            textAlign: 'center',
            marginBottom: theme.spacing(1),
        }
    },
    header: {
        color: headerText[1],
        fontSize: '18px'
    },
    flexItems: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            whiteSpace: "wrap",
            display: 'block',
            justifyContent: "center",
            marginBottom: theme.spacing(1),
        }
    },
    spacing: {
        margin: theme.spacing(0, 0.5),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1, 0.5),
            justifyContent: 'center',
            alignSelf: 'center'
        }
    },
    add: {
        padding: theme.spacing(1.2, 1.2, 1.4, 1.2),
        backgroundColor: primaryColor,
        color: whiteColor,
        "&:hover": {
            backgroundColor: primaryColor,
            color: whiteColor,
            opacity: 0.7,
            cursor: 'pointer'
        },
    },
    input2: {
        background: whiteColor,
        width: '100%',
        fontSize: '15px',
    },
}));
