import { makeStyles } from '@material-ui/core'
import { primaryColor, dangerColor, whiteColor, themeBackground, borderColor, warningColor } from '../colors'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    container: {
        background: themeBackground,
        marginTop: theme.spacing(1),
        borderRadius: '8px',
        border: `1px solid ${borderColor[1]}`,
        overflowX: "auto",
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },
    primaryText: {
        color: primaryColor
    },
    cancel: {
        cursor: 'pointer',
        justifyContent: 'center',
        alignSelf: 'center',
        color: dangerColor,
        "&:hover": {
            background: whiteColor,
            color: dangerColor,
            opacity: 0.7,
            cursor: 'pointer'
        },
    },
    add: {
        color: warningColor,
        cursor: 'pointer'
    },
    edit: {
        color: primaryColor,
        cursor: 'pointer'
    },
    delete: {
        color: dangerColor,
        cursor: 'pointer'
    },
    chip: {
        border: `1px solid ${primaryColor}`,
        background: whiteColor,
        margin: theme.spacing(0.5)
    }
}))

export { useStyles }
