import {
    GET_FILTERED_MODIFIERS, MODIFIERS_FOUND, MODIFIERS_NOT_FOUND,
    GET_MODIFIER_GROUP, REMOVE_MODIFIER_GROUP, GET_MODIFIER_OPTION, REMOVE_MODIFIER_OPTION,
    DELETE_MODIFIER_GROUP
} from '../types'

const initialState = {
    filteredModifier: null,
    modifiersList: null,
    modifiersCount: 0,
    modifierGroup: null,
    modifierOptions: null,
}

const modifier = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_FILTERED_MODIFIERS:
            return {
                ...state,
                filteredModifier: payload.filteredModifier,
                modifiersCount: payload.modifiersCount,
                modifierGroup: null,
                modifierOptions: null,
            }
        case MODIFIERS_FOUND:
            return {
                ...state,
                modifiersList: payload.modifiersList,
                modifiersCount: payload.modifiersCount,
                modifierGroup: null,
                modifierOptions: null,
            }
        case DELETE_MODIFIER_GROUP:
            let updatedList = state.modifiersList.filter((item) => item.uid !== payload).map(items => items);
            let count = updatedList?.length
            return {
                ...state,
                modifiersList: updatedList,
                modifiersCount: count,
                modifierGroup: null,
                modifierOptions: null,
            }
        case MODIFIERS_NOT_FOUND:
            return {
                ...state,
                modifiersList: null,
            }
        case GET_MODIFIER_GROUP:
            return {
                ...state,
                modifierGroup: payload,
            }
        case REMOVE_MODIFIER_GROUP:
            return {
                ...state,
                modifierGroup: null,
            }
        case GET_MODIFIER_OPTION:
            return {
                ...state,
                modifierOptions: payload,
            }
        case REMOVE_MODIFIER_OPTION:
            return {
                ...state,
                modifierOptions: null,
            }
        default:
            return state
    }

}

export default modifier