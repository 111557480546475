import { makeStyles } from '@material-ui/core/styles';
import { sideBarColor, whiteColor } from '../../colors'

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
    drawer: {
        backgroundColor: sideBarColor,
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        backgroundColor: sideBarColor,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        zIndex: 0,
        backgroundColor: sideBarColor,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    navlinks: {
        textDecoration: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    icons: {
        color: whiteColor,
        paddingLeft: theme.spacing(1)
    }
}));

export default useStyles
