import React, { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { Container } from "@material-ui/core/"
import { MoreVert, Edit, Delete, Check, Clear } from "@material-ui/icons/"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import ContentChecker from '../../../shared/common/ContentChecker'
import useStyles from '../../../assets/jss/pages/voucher'
import Header from '../../../components/common/Header'
import { formNavigation } from '../../../functions/navigate'
import CustomTable from "../../../components/material/table/Table";
import CustomMenu from '../../../components/material/menu/Menu'
import { loader } from "../../../shared/common/LoadingScreen";
import { deleteFunc } from '../../../functions/delete'
import { setPage } from "../../../redux/actions/page";
import { getRangesList, deleteRange } from '../../../redux/actions/range'

const ListRanges = ({
    getRangesList, deleteRange, setPage,
    range: { rangesList, rangesCount }
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const timer = useRef();
    let limit = 20
    let count = rangesCount ? rangesCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [loading, setLoading] = useState(false)

    const [disableClick, setDisableClick] = useState(false);

    useEffect(() => {
        getRangesList(1)
    }, [getRangesList]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getRangesList(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header name='ranges' nav='range' />
            {rangesList ?
                <CustomTable
                    onClick={disableClick === false ? 'range' : false}
                    tableHead={['Number', 'Name', 'Is Public?', 'Valid for All Products?', 'Actions']}
                    tableData={rangesList.map(range => (
                        {
                            cells: [
                                range.id,
                                range.name,
                                range.is_public === true ? <Check /> : <Clear />,
                                range.includes_all_products === true ? <Check /> : <Clear />,
                                <CustomMenu
                                    stateSetter={setDisableClick}
                                    button={<MoreVert />}
                                    menuItems={[
                                        {
                                            name: <Edit className={classes.edit} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                formNavigation(history, setPage, 'range', range.id, 'edit')
                                            }
                                        },
                                        {
                                            name: <Delete className={classes.delete} />,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                deleteFunc(deleteRange, range.id, alert)
                                            }
                                        }
                                    ]}
                                />
                            ],
                            uid: range.id
                        }
                    ))}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={rangesList} />
            }
        </Container>
    )
}

ListRanges.propTypes = {
    getRangesList: PropTypes.func.isRequired,
    deleteRange: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    range: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getRangesList, deleteRange, setPage })(ListRanges)