import React, { useEffect, useState } from "react"
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Grid, Typography, Button, Table, TableRow, TableCell, TableBody } from "@material-ui/core"
import { Delete, DragHandle } from "@material-ui/icons/"
import ImageField from "../../components/material/inputFields/ImageField";
import InputField from '../../components/material/inputFields/InputField'
import AutocompleteField from '../../components/material/inputFields/AutocompleteField'
import CheckBoxField from '../../components/material/inputFields/CheckBoxField'
import CustomTable from "../../components/material/table/SortableTable";
import Buttons from '../../components/common/Buttons'
import { deleteFunc } from '../../functions/delete'
import { onChange, filterById } from '../../functions/onChange'
import { navigate } from '../../functions/navigate'
import { useStyles } from "../../assets/jss/pages/products"
import { getProduct, deleteImage, sortProductModifiers } from "../../redux/actions/product";
import { setPage } from '../../redux/actions/page'

const ProductForm = ({
    formData, setFormData, onSubmit, onDrop,
    getProduct, deleteImage, sortProductModifiers, setPage,
    product: { productDetail },
    category: { categoriesList },
    modifier: { modifiersList },
    page: { loadedPage },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let { uid } = useParams();
    let name = 'products'

    const [nameUsingId, setNameUsingId] = useState({
        modifiers: []
    })

    useEffect(() => {
        if (productDetail?.modifier_groups) {
            let modifierArray = []
            let modifierGroup = productDetail?.modifier_groups?.map(modifierId => modifierId)

            modifiersList && modifiersList?.filter((modifier) => {
                if (modifierGroup?.includes(modifier.id)) {
                    modifierArray.push({ id: modifier.id, name: modifier.name })
                }
                return modifierArray
            })

            setNameUsingId(prevState => ({ ...prevState, modifiers: modifierArray }));
        }
    }, [productDetail, modifiersList])


    const onCancel = () => {
        navigate(history, setPage, name)
    }

    let modifierArray = []
    let modifierGroup = modifiersList?.filter((modifier) => {
        if (formData?.modifier_groups?.includes(modifier.id)) {
            modifierArray.push(modifier.name)
        }
        return modifierArray
    })

    let categoryArray = []
    let categoryGroup = categoriesList?.filter((category) => {
        if (formData?.categories?.includes(category.id)) {
            categoryArray.push(category.name)
        }
        return categoryArray
    })



    const sortProductModifier = (data) => {
        sortProductModifiers(data)
    }

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='title'
                        label='Title'
                        value={formData?.title}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='text'
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InputField
                        name='description'
                        label='Description'
                        value={formData?.description}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        type='text'
                    />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <InputField
                        name='price'
                        label='Price'
                        value={formData?.price}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='number'
                    />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <InputField
                        name='sequence'
                        label='Sequence'
                        value={formData?.sequence}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        type='number'
                    />
                </Grid>

                <Grid item xs={12} sm={6} style={{ padding: '2.2rem 0rem 0rem 1rem' }}>
                    <CheckBoxField
                        name='is_public' label='Sell Individually?' value={formData?.is_public}
                        onChange={(e) => onChange(e, formData, setFormData)}
                    />
                    <br />
                    <small style={{ color: 'red' }}>If not enabled, you can sell this product as a part of a modifer group only.</small>
                </Grid>

                <Grid item xs={12} sm={6}>
                    {categoriesList && categoryGroup && (
                        <AutocompleteField
                            name='categories'
                            label='Categories'
                            options={categoriesList?.map(category => category.name) ?? []}
                            value={categoryArray?.map(val => val) ?? []}
                            onChange={(event, newValue) => {
                                setFormData({
                                    ...formData, 'categories':
                                        filterById(newValue, categoriesList)
                                })
                            }}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {modifiersList && modifierGroup && (
                        <AutocompleteField
                            name='modifier_groups'
                            label='Modifier Group'
                            options={modifiersList?.map(modifier => modifier.name) ?? []}
                            value={modifierArray?.map(val => val) ?? []}
                            onChange={(event, newValue) => {
                                setFormData({
                                    ...formData, 'modifier_groups':
                                        filterById(newValue, modifiersList)
                                })
                            }}
                        />
                    )}
                </Grid>

                <Grid item xs={12}>
                    {loadedPage === 'product-edit' && productDetail.modifier_groups?.length > 0 &&
                        <CustomTable
                            onClick={false}
                            tableHead={[
                                { size: 1, name: <DragHandle /> },
                                { size: 1, name: 'Number' },
                                { size: 10, name: 'Modifier Group' },

                            ]}
                            tableData={nameUsingId?.modifiers?.map((modifier) => (
                                {
                                    id: modifier.id,
                                    cells: [
                                        { size: 1, name: modifier.id },
                                        { size: 10, name: modifier.name },
                                    ]
                                }
                            ))}
                            sort={sortProductModifier}
                        />
                    }
                </Grid>

                <Grid item xs={12}>
                    {productDetail?.images?.length > 0 &&
                        <>
                            <Typography style={{ color: '#707070' }}>Current Image</Typography>
                            <Table>
                                <TableBody>
                                    {productDetail?.images.map((img, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='left'>
                                                <img src={img.original} alt={productDetail?.title} style={{ width: 120, height: 120 }} />
                                            </TableCell>
                                            <TableCell align='right'>
                                                <Button onClick={() => deleteFunc(deleteImage, productDetail?.uid, alert, getProduct, uid)} className={classes.cancel}>
                                                    <Delete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    }
                </Grid>
            </Grid>
            {loadedPage === 'product-edit' && productDetail?.images?.length === 0 &&
                <ImageField onDrop={onDrop} single={true} />
            }
            <br />
            <Buttons onCancel={onCancel} />
        </form>
    )
}

ProductForm.propTypes = {
    getProduct: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    sortProductModifiers: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
    modifier: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getProduct, deleteImage, sortProductModifiers, setPage })(ProductForm)