import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { Container } from "@material-ui/core/"
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import ProductForm from './Form'
import ContentChecker from '../../shared/common/ContentChecker'
import CrudHeader from '../../components/common/CrudHeader'
import { useStyles } from "../../assets/jss/pages/products"
import { getProduct, updateProduct } from "../../redux/actions/product";
import { getAllCategories } from "../../redux/actions/category"
import { getAllModifiers } from "../../redux/actions/modifier"
import { initialState } from './State'

const EditProduct = ({
    getProduct, updateProduct, getAllModifiers, getAllCategories,
    product: { productDetail },
}) => {
    const classes = useStyles()
    const alert = useAlert();
    let { uid } = useParams();

    const [formData, setFormData] = useState(initialState)

    const [pictures, setPictures] = useState()

    const onDrop = picture => {
        setPictures(picture);
    };

    useEffect(() => {
        if (uid) {
            getProduct(uid)
        }
        getAllCategories()
        getAllModifiers()
    }, [getAllModifiers, getAllCategories, getProduct, uid])

    useEffect(() => {
        if (productDetail) {
            setFormData({
                ...formData,
                uid: productDetail?.uid,
                title: productDetail?.title,
                description: productDetail?.description,
                price: productDetail?.price,
                categories: productDetail?.categories ? productDetail?.categories?.map(category => category) : [],
                modifier_groups: productDetail?.modifier_groups ? productDetail?.modifier_groups?.map(modifierId => modifierId) : [],
                is_public: productDetail?.is_public,
                available: productDetail?.available,
                sequence: productDetail?.sequence,
            })
        }
        // eslint-disable-next-line
    }, [productDetail])

    const onSubmit = e => {
        e.preventDefault()

        formData.is_public = formData?.is_public === true ? true : false

        updateProduct(formData, pictures)
            .then((success) => {
                if (success) {
                    alert.show('Product updated successfully', { type: "success" })
                    getProduct(formData?.uid)
                }
                else {
                    alert.show('Cannot update the product. Please try again later', { type: "error" })
                }
            });
    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='product' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {(formData?.uid && uid && productDetail) ?
                    <ProductForm
                        formData={formData}
                        setFormData={setFormData}
                        onSubmit={onSubmit}
                        onDrop={onDrop}
                    />
                    :
                    <ContentChecker reducerName={productDetail} />
                }
            </Container>
        </Container>
    )
}

EditProduct.propTypes = {
    getProduct: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    getAllModifiers: PropTypes.func.isRequired,
    getAllCategories: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    getProduct, updateProduct, getAllModifiers, getAllCategories
})(EditProduct)