import { makeStyles } from '@material-ui/core/styles'
import { grayColor, blackColor, whiteColor, contrastColor } from '../colors'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    content: {
        marginTop: theme.spacing(-10),
        [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(-7),
        },
    },
    title: {
        color: blackColor,
        fontWeight: 800,
        fontSize: '35px',
        lineHeight: '1.25rem',
        alignSelf: 'center',
    },
    small: {
        fontSize: '12px'
    },
    revenueTitle: {
        color: blackColor,
        fontWeight: 600,
        fontSize: '25px',
        lineHeight: '1.25rem'
    },
    subHeader: {
        color: grayColor,
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '1rem',
        alignSelf: 'center',
    },
    cardHeight: {
        background: whiteColor
    },
    totalRevenue: {
        textAlign: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    list: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(-1, 0.5, 1, 1.25),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(-1, 0.5, 1, 0.5),
        },
    },
    listItem: {
        padding: theme.spacing(1.5, 0)
    },
    more: {
        color: contrastColor,
        padding: theme.spacing(2, 0)
    },
    cards: {
        margin: theme.spacing(0.2, 1.5)
    }

}))

export default useStyles
