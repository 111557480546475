import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from "../../assets/jss/pages/settings"
import ContentChecker from '../../shared/common/ContentChecker'
import BusinessHoursForm from './BusinessHoursForm'
import { verifyUser } from "../../redux/actions/auth";

const EditBusinessHours = ({
    verifyUser,
    auth: { me }
}) => {
    const classes = useStyles()

    useEffect(() => {
        verifyUser()
    }, [verifyUser])

    return (
        <Container maxWidth="xl" className={classes.container}>
            <h2 className={classes.headers}> Edit Business Hours </h2>
            <Container maxWidth="xl" elevation={3} className={classes.formContainer}>
                {me ?
                    <BusinessHoursForm />
                    :
                    <ContentChecker reducerName={me} />
                }
            </Container>

        </Container>
    )
}

EditBusinessHours.propTypes = {
    verifyUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { verifyUser })(EditBusinessHours)