import { formatPrice } from './priceFormatter'

export const formatSingleModifierItem = (item) => {
    item.price = formatPrice(item.price)
    return item
}

export const formatModifiersItems = (modifiers) => {
    for (let i=0; i<modifiers.length; i++) {
        modifiers[i] = formatSingleModifierItem(modifiers[i])
    }
    return modifiers
}

export const formatSingleModifier = (item) => {
    item.modifiers = formatModifiersItems(item.modifiers)
    return item
}

export const formatModifiers = (modifiers) => {
    for (let i = 0; i < modifiers.length; i++) {
        modifiers[i] = formatSingleModifier(modifiers[i])
    }
    return modifiers
}

