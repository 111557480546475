import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import ContentChecker from '../../../shared/common/ContentChecker'
import VoucherForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { navigate } from '../../../functions/navigate'
import { showAlertFunc } from '../../../functions/showAlert';
import { setPage } from '../../../redux/actions/page'
import { addVoucher } from '../../../redux/actions/voucher'
import { getAllOffers } from '../../../redux/actions/offer'
import { initialState } from './State'

const CreateVoucher = ({
    addVoucher, getAllOffers, setPage,
    offer: { offersList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();

    useEffect(() => {
        getAllOffers()
    }, [getAllOffers])

    const [formData, setFormData] = useState(initialState)

    const onSubmit = e => {
        e.preventDefault()
        addVoucher(formData, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Voucher added successfully`, { type: "success" })
                    navigate(history, setPage, 'vouchers')
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='voucher' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {offersList ?
                    <VoucherForm
                        formData={formData}
                        setFormData={setFormData}
                        onSubmit={onSubmit}
                    />
                    :
                    <ContentChecker reducerName={offersList} />
                }
                {offersList === 0 && showAlertFunc('Do add an offer before adding a voucher', history, setPage, 'offer')}
            </Container>
        </Container>
    )
}

CreateVoucher.propTypes = {
    addVoucher: PropTypes.func.isRequired,
    getAllOffers: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    voucher: PropTypes.object.isRequired,
    offer: PropTypes.object.isRequired,

};

export default connect(mapStateToProps, { addVoucher, getAllOffers, setPage, })(CreateVoucher)