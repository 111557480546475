import React, { useEffect, useState, useRef } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container, Tooltip, Chip } from "@material-ui/core/"
import { MoreVert, Add, Edit, Delete } from "@material-ui/icons/"
import { useStyles } from "../../../assets/jss/pages/modifiers"
import { loader } from "../../../shared/common/LoadingScreen";
import ContentChecker from '../../../shared/common/ContentChecker'
import { getList } from '../../../functions/search'
import CustomTable from "../../../components/material/table/Table";
import CustomMenu from '../../../components/material/menu/Menu'
import Header from '../../../components/common/Header'
import { deleteFunc } from '../../../functions/delete'
import { formNavigation } from '../../../functions/navigate'
import {
    getFilteredModifiers, getModifiersList, deleteModifierGroup, getModifierGroup,
} from "../../../redux/actions/modifier"
import { setPage } from "../../../redux/actions/page";

const ListModifiers = ({
    getFilteredModifiers, getModifiersList, deleteModifierGroup, getModifierGroup, setPage,
    modifier: { modifiersList, filteredModifier, modifiersCount },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    const timer = useRef();
    let limit = 20
    let count = modifiersCount ? modifiersCount : 0
    const [activePage, setCurrentPage] = useState(1);
    const [checkCount, setCheckCount] = useState(1)
    const [loading, setLoading] = useState(false)
    const [searchModifier, setSearchModifier] = useState('');
    const [disableClick, setDisableClick] = useState(false);

    let currentList = searchModifier ? filteredModifier : modifiersList

    useEffect(() => {
        getList(searchModifier, getFilteredModifiers, getModifiersList)
    }, [searchModifier, getFilteredModifiers, getModifiersList]);

    useEffect(() => {
        let x;
        x = Math.ceil(count / limit)
        setCheckCount(x)
    }, [setCheckCount, count, limit])

    const handleChange = (event, value) => {
        getModifiersList(value)
        setCurrentPage(value);
        loader(loading, setLoading, timer)
    };

    const addOptions = (idx) => {
        getModifierGroup(idx)
        formNavigation(history, setPage, 'modifier-group', idx, 'add', 'options')
    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Header doSeacrch={true} search={searchModifier} setSearch={setSearchModifier} name='modifiers' nav='modifier-group' />
            {currentList ?
                <CustomTable
                    onClick={disableClick === false ? 'modifier-group' : false}
                    tableHead={['No.', 'Modifier', 'Options', 'Actions']}
                    tableData={currentList.map(modifier => (
                        {
                            id: modifier.id,
                            cells: [
                                modifier.id,
                                modifier.name,
                                <div style={{ maxWidth: '40rem' }}>
                                    {modifier.modifiers && modifier.modifiers.map((modify, index) =>
                                        <Chip
                                            key={index}
                                            className={classes.chip}
                                            label={modify.name}
                                        />
                                    )}
                                </div>,
                                <CustomMenu
                                    stateSetter={setDisableClick}
                                    button={<MoreVert />}
                                    menuItems={[
                                        {
                                            name: <Tooltip title="Add Modifier Option"><Add className={classes.add} /></Tooltip>,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                addOptions(modifier.uid)
                                            }
                                        },
                                        {
                                            name: <Tooltip title="Edit Modifier Group"><Edit className={classes.edit} /></Tooltip>,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                formNavigation(history, setPage, 'modifier-group', modifier.uid, 'edit')
                                            }
                                        },
                                        {
                                            name: <Tooltip title="Delete Modifier Group"><Delete className={classes.delete} /></Tooltip>,
                                            handleItemClick: (event) => {
                                                event.stopPropagation()
                                                deleteFunc(deleteModifierGroup, modifier.uid, alert)
                                            }
                                        }
                                    ]}
                                />

                            ]
                        }
                    ))}
                    handleChange={handleChange}
                    checkCount={checkCount}
                    activePage={activePage}
                    search={searchModifier}
                    loading={loading}
                />
                :
                <ContentChecker reducerName={searchModifier ? filteredModifier : modifiersList} />
            }
        </Container>
    )
}

ListModifiers.propTypes = {
    getFilteredModifiers: PropTypes.func.isRequired,
    getModifiersList: PropTypes.func.isRequired,
    deleteModifierGroup: PropTypes.func.isRequired,
    getModifierGroup: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    modifier: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    getFilteredModifiers, getModifiersList, deleteModifierGroup, getModifierGroup, setPage
})(ListModifiers)