import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useAlert } from "react-alert";
import { Box, Button, Grid, Paper } from '@material-ui/core'
import { Email } from '@material-ui/icons'
import { mapStateToProps } from '../shared/common/MapStateToProps'
import { forgotPassword } from '../redux/actions/auth'
import { onChange } from '../functions/onChange'
import { loader } from '../shared/common/LoadingScreen'
import CustomTextField from '../components/material/inputFields/CustomTextField'
import useStyles from '../assets/jss/layouts/signin'
import logo from '../assets/images/logo2.png'

const ForgotPassword = ({ forgotPassword, auth }) => {
    const classes = useStyles()
    const alert = useAlert();
    const timer = useRef()
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({
        email: '',
    });

    const { email } = formData;

    const onSubmit = async e => {
        e.preventDefault()
        loader(loading, setLoading, timer)
        forgotPassword({ email }).then((success) => {
            if (success) {
                alert.show("Email sent successfully.", { type: "success" });
                setTimeout(() => { window.location = "/" }, 1000);
            } else {
                alert.show("Couldn't send email. Please try again later", { type: "error" });
            }
        });
    };

    const fields = [
        { label: 'Email', name: 'email', id: 'email', placeholder: 'email', type: 'email', icon: <Email /> },
    ]

    if (auth && auth.isAuthenticated) {
        window.location = "/";
        return <></>;
    }

    return (
        <Grid container>
            <Grid item xs={false} sm={5} md={6}>
                <div className={classes.bg}>
                    <img src={logo} alt='logo' className={classes.logo} />
                </div>
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
                <Box className={classes.box}>
                    <form onSubmit={e => onSubmit(e)} className={classes.form}>
                        <h1 className={classes.header}>Welcome Back!</h1>
                        <h4 className={classes.subHeader}>Login to your account</h4>
                        <br />
                        <Box className={classes.body}>
                            {fields && fields.map((field, index) => (
                                <Paper component="form" className={classes.paper} key={index}>
                                    <CustomTextField
                                        icon={field.icon}
                                        type={field.type}
                                        name={field.name}
                                        data-testid={field.name}
                                        placeholder={field.placeholder}
                                        onChange={e => onChange(e, formData, setFormData)}
                                    />
                                </Paper>
                            ))}
                            <br />
                            <Box className={classes.subBody}>
                                <div className={classes.extra}>
                                </div>
                                <a href='/signin' className={classes.label}>Remember Password?</a>
                            </Box>
                            <br />
                            <Button
                                type="submit"
                                data-testid="submit"
                                variant="contained"
                                className={classes.submit}
                            >
                                Login
                        </Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    )
}

ForgotPassword.propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword)