import React from "react"
import { InputLabel } from "@material-ui/core/"
import useStyles from '../../../assets/jss/components/material/inputFields'

const InputField = (props) => {
    const classes = useStyles()
    const { name, label, value, onChange, type, required, disabled } = props

    return (
        <>
            <InputLabel className={classes.label}>{label}</InputLabel>
            <input
                className={classes.input}
                name={name}
                id={name}
                placeholder={label}
                variant='outlined'
                type={type}
                step={type === 'number' && name === 'price' ? '0.01' : undefined}
                defaultValue={value}
                onChange={onChange}
                required={required ? true : false}
                disabled={disabled ? true : false}
            />
        </>
    )
}

export default InputField