export const mapStateToProps = state => ({
    auth: state.auth,
    stats: state.stats,
    notification: state.notification,
    order: state.order,
    product: state.product,
    category: state.category,
    modifier: state.modifier,
    range: state.range,
    condition: state.condition,
    benefit: state.benefit,
    offer: state.offer,
    voucher: state.voucher,
    error: state.error,
    page: state.page
})