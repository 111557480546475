import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CssBaseline, } from '@material-ui/core';
import { mapStateToProps } from '../shared/common/MapStateToProps'
import useStyles from '../assets/jss/layouts/layout'
import Appbar from '../components/material/appbar/Appbar'
import SideDrawer from '../components/material/sidebar/SideDrawer'
import { logout } from '../redux/actions/auth'
import { routes } from '../routing/RouteComponents'

import { path } from '../functions/path'

const Layout = ({ page: { loadedPage } }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Appbar />
            <SideDrawer
            />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {routes && routes.filter((route) =>
                    path(route.path) === loadedPage
                ).map(rout => rout.classComponent)}
            </main>
        </div>
    )
}

Layout.propTypes = {
    logout: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { logout })(Layout)