import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core/"
import Card from '../../components/material/card/Card'
import useStyles from '../../assets/jss/pages/dashboard'
import Pie from '../../components/charts/Pie'
// import Line from '../../components/charts/Line'

const MainCards = ({
    stats: { topProducts, stats, weeklyOrders },
    notification: { notifications }
}) => {
    const classes = useStyles()

    // let weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    // const list = [
    //     'A new order has been placed by ahmed ali for Vegi Spaghetti.',
    //     'Ahmed ali order has been converted into Order and total amount of order is 400$.',
    //     'Ahmed ali order is booked and will arrive at 05/05/20021.',
    //     'Ahmed Ali order is shipped and will be delivered On 25/05/2021.',
    //     'Ahmed Ali order is mark complete by Kamran khan.',
    //     'A new quotation has been generated for Arslan Ali by Kamran khan.',
    //     'A new quotation has been generated for Kamal Khan by Kamran khan.',
    //     'Ahmed Ali order is shipped and will be delivered On 30/05/2021.',
    //     'Zain Ali order is mark complete by Kamran khan.',
    //     'Ahmed Ali order is mark complete by Kamran khan.',
    // ]

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                {/* sm={8} md={9} */}
                <div>
                    <Grid container className={classes.cardHeight}>
                        <Grid item xs={12} md={8} xl={9}>
                            <Card
                                header={{
                                    title: <h1 className={classes.revenueTitle}>Revenue Overview
                                    <small className={classes.small}>(Top 4 most Selling Products)</small>
                                    </h1>,
                                    subHeader: <h3 className={classes.subHeader}>Total Revenue</h3>
                                }}
                                content={
                                    <div className={classes.pie}>
                                        <Pie
                                            labels={topProducts && topProducts.map(pro => pro.product)}
                                            dataSet={topProducts && topProducts.map(pro => pro.num_purchases)}
                                        />
                                    </div>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={classes.totalRevenue}>
                                <Card
                                    header={{
                                        title: <h1 className={classes.title} style={{ paddingTop: '5rem' }}>£{stats && stats.total_revenue ? stats && stats.total_revenue : '0.00'}</h1>,
                                        subHeader: <h3 className={classes.subHeader} style={{ paddingBottom: '5.5rem' }}>Total Revenue</h3>
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    {/* <Grid container className={classes.root}>
                        <Grid item xs={12} >
                            <Card
                                header={{
                                    title: <h1 className={classes.revenueTitle}>Weekly Analysis </h1>
                                }}
                                content={
                                    <div className={classes.line}>
                                        <Line
                                            colors={{ border: 'rgb(69, 190, 226, 0.8)', background: 'rgb(69, 190, 226, 0.2)' }}
                                            dataSet={weeklyOrders && weeklyOrders.map(order => order.count)}
                                            labels={weeklyOrders && weeklyOrders.map(order => weekday[new Date(order.date_placed__date).getDay()])}
                                        />
                                    </div>
                                }
                            />
                        </Grid>
                    </Grid> */}
                </div>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={3}>
                <Card
                    header={{
                        title: <h1 className={classes.revenueTitle}>Notifications</h1>
                    }}
                    content={
                        <div className={classes.list}>
                            <ul>
                                {list && list.map((item, index) => (
                                    <li className={classes.listItem} key={index}>{item}</li>
                                ))}
                            </ul>
                            <a href='#!' className={classes.more}>Load More</a>
                            <br />
                        </div>
                    }
                />
            </Grid> */}
        </Grid>
    )
}

MainCards.propTypes = {
    stats: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(MainCards)