import axios from 'axios';
import { apiUrl } from "../../shared/utils/fetcher";
import {
    CONDITIONS_FOUND, CONDITIONS_NOT_FOUND,
    GET_CONDITION, REMOVE_CONDITION,
    DELETE_CONDITION
} from '../types'
import { formatConditions, formatSingleCondition } from '../../shared/formatter/conditionFormatter'

let conditionUrl = `${apiUrl}/me/condition`

export const getAllConditions = () => async dispatch => {
    try {
        const res = await axios.get(`${conditionUrl}/?limit=200`)
        let data = formatConditions(res.data.results)
        dispatch({
            type: CONDITIONS_FOUND,
            payload: {
                conditionsList: data && data.length > 0 ? data : 0,
                conditionsCount: res.data.count
            }
        });
        return true;
    } catch {
        dispatch({
            type: CONDITIONS_NOT_FOUND,
        });
        return false;
    }
}

export const getConditionsList = (page) => async dispatch => {
    try {
        const res = await axios.get(`${conditionUrl}/?page=${page ? page : 1}`)
        let data = formatConditions(res.data.results)
        dispatch({
            type: CONDITIONS_FOUND,
            payload: {
                conditionsList: data && data.length > 0 ? data : 0,
                conditionsCount: res.data.count
            }
        });
        return true;
    } catch {
        dispatch({
            type: CONDITIONS_NOT_FOUND,
        });
        return false;
    }
}

export const getConditionDetail = (id) => async dispatch => {
    try {
        const res = await axios.get(`${conditionUrl}/${id}/`)
        let data = formatSingleCondition(res.data)
        dispatch({
            type: GET_CONDITION,
            payload: data
        });
        return true;
    } catch {
        dispatch({
            type: REMOVE_CONDITION,
        });
        return false;
    }
}

export const addCondition = (formData) => async dispatch => {
    let body = formData
    try {
        await axios.post(`${conditionUrl}/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const updateCondition = (formData, id) => async dispatch => {
    let body = formData
    try {
        await axios.put(`${conditionUrl}/${id}/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const deleteCondition = (id) => async dispatch => {
    try {
        await axios.delete(`${conditionUrl}/${id}/`)
        dispatch({
            type: DELETE_CONDITION,
            payload: id
        })
        return true;
    } catch {
        return false
    }
}