import { makeStyles, withStyles } from '@material-ui/core/styles'
import { borderColor, grayColor, primaryColor, whiteColor, headerText, dangerColor } from '../colors'
import MuiTableCell from "@material-ui/core/TableCell";

const TableCell = withStyles({
    root: {
        borderBottom: 'none'
    }
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    primaryText: {
        color: primaryColor
    },
    content: {
        marginBottom: theme.spacing(3),
    },
    header: {
        display: 'flex',
        flexGrow: 1
    },
    iconBox: {
        border: `1px solid ${borderColor[1]}`,
        background: '#F6F6F67F',
        padding: theme.spacing(1, 1, 0, 1),
        marginRight: theme.spacing(1),
        borderRadius: '6px',
    },
    icon: {
        color: grayColor,
        justifyContent: 'center',
        alignSelf: 'center',
        textAlign: 'center'
    },
    head: {
        color: headerText[2],
        fontSize: '14px'
    },
    value: {
        color: headerText[1],
        fontSize: '16px'
    },
    tableHeader: {
        background: primaryColor,
        borderRadius: '8px'
    },
    cell: {
        color: whiteColor
    },
    divider: {
        border: `2px solid ${primaryColor}`
    },
    danger: {
        backgroundColor: dangerColor,
        color: whiteColor,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: dangerColor,
            color: whiteColor,
            opacity: 0.7,
        },
    },
    head1: {
        color: headerText[2],
        fontSize: '14px',
        flexGrow: 1
    },
    value1: {
        color: headerText[1],
        fontSize: '14px',
        justifyContent: 'flex-start'
    },
}))

export { useStyles, TableCell }
