import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { Paper, Container, AppBar, Tab, Tabs, Box } from "@material-ui/core";

import useStyles from "../../../assets/jss/components/material/swipeableTabs";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const SwipeableTabs = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { tabData } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" color="inherit">
                <Tabs
                    className={classes.tabs}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {tabData.map((tab, index) => {
                        return <Tab label={tab.label} {...a11yProps(index)} key={index} />;
                    })}
                </Tabs>
            </AppBar>
            {tabData.map((tab, index) => (
                <TabPanel
                    value={value}
                    index={index}
                    dir={theme.direction}
                    key={index}
                >
                    <Container component={Paper} maxWidth='sm' className={classes.paper}>
                        {tab.component}
                    </Container>
                </TabPanel>
            ))}
        </div>
    );
};

SwipeableTabs.propTypes = {
    tabData: PropTypes.arrayOf(PropTypes.object),
};

export default SwipeableTabs;