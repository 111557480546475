import { formatPrice } from './priceFormatter'
import { fromatDescription } from './descriptionFormatter'

export const formatProduct = (product) => {
    product.description = fromatDescription(product.description)
    return product
}

export const formatSingleProduct = (product) => {
    product.price = formatPrice(product.price)
    product.description = fromatDescription(product.description)
    return product
}

export const formatProducts = (products) => {
    for (let i = 0; i < products.length; i++) {
        products[i] = formatSingleProduct(products[i])
    }
    return products
}