import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import ContentChecker from '../../../shared/common/ContentChecker'
import OfferForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { navigate } from '../../../functions/navigate'
import { showAlertFunc } from '../../../functions/showAlert';
import { setPage } from '../../../redux/actions/page'
import { addOffer } from '../../../redux/actions/offer'
import { getAllBenefits } from '../../../redux/actions/benefit'
import { getAllConditions } from '../../../redux/actions/condition'
import { getAllRanges } from '../../../redux/actions/range'
import { initialState } from './State'

const CreateOffer = ({
    addOffer, getAllBenefits, getAllConditions, getAllRanges, setPage,
    benefit: { benefitsList },
    condition: { conditionsList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();

    useEffect(() => {
        getAllRanges()
        getAllConditions()
        getAllBenefits()
    }, [getAllBenefits, getAllConditions, getAllRanges])

    const [formData, setFormData] = useState(initialState)

    const onSubmit = e => {
        e.preventDefault()

        formData.exclusive = formData.exclusive === true ? true : false

        addOffer(formData, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Offer added successfully`, { type: "success" })
                    navigate(history, setPage, 'offers')
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='offer' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {conditionsList ?
                    benefitsList ?
                        <OfferForm
                            formData={formData}
                            setFormData={setFormData}
                            onSubmit={onSubmit}
                        />
                        :
                        <ContentChecker reducerName={benefitsList} />
                    :
                    <ContentChecker reducerName={conditionsList} />
                }
                {conditionsList === 0 ?
                    showAlertFunc('Do add condition before adding an offer', history, setPage, 'condition')
                    :
                    benefitsList === 0 ? showAlertFunc('Do add benefits before adding an offer', history, setPage, 'benefit')
                        :
                        null
                }
            </Container>
        </Container>
    )
}

CreateOffer.propTypes = {
    addOffer: PropTypes.func.isRequired,
    getAllBenefits: PropTypes.func.isRequired,
    getAllConditions: PropTypes.func.isRequired,
    getAllRanges: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    benefit: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,

};

export default connect(mapStateToProps, { addOffer, getAllBenefits, getAllConditions, getAllRanges, setPage })(CreateOffer)