import React from "react"
import { FormControlLabel, Checkbox } from "@material-ui/core/"

const CheckBoxField = (props) => {
    const { name, onChange, value, label, required } = props

    return (
        <FormControlLabel
            control={
                <Checkbox
                    required={required ? true : false}
                    name={name}
                    onChange={onChange}
                    color="primary"
                    checked={value === true ? true : false}
                />
            }
            label={label}
        />
    )
}

export default CheckBoxField