import {
    GET_FILTERED_PRODUCTS, PRODUCTS_FOUND, PRODUCTS_NOT_FOUND, GET_PRODUCT, REMOVE_PRODUCT, DELETE_PRODUCT
} from '../types'

const initialState = {
    filteredProduct: null,
    productsList: null,
    productsCount: 0,
    productDetail: null,
}

const product = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_FILTERED_PRODUCTS:
            return {
                ...state,
                filteredProduct: payload.filteredProduct,
                productsCount: payload.productsCount,
                productDetail: null,
            }
        case PRODUCTS_FOUND:
            return {
                ...state,
                productsList: payload.productsList,
                productsCount: payload.productsCount,
                productDetail: null,
            }
        case DELETE_PRODUCT:
            let updatedList = state.productsList.filter((item) => item.uid !== payload).map(items => items);
            let count = updatedList?.length
            return {
                ...state,
                productsList: updatedList,
                productsCount: count,
                productDetail: null,
            }
        case PRODUCTS_NOT_FOUND:
            return {
                ...state,
                productsList: null,
                productsCount: 0,
            }
        case GET_PRODUCT:
            return {
                ...state,
                productDetail: payload,
            }
        case REMOVE_PRODUCT:
            return {
                ...state,
                productDetail: null,
            }
        default:
            return state;
    }
}

export default product