import axios from 'axios';
import { apiUrl } from "../../shared/utils/fetcher";
import {
    GET_FILTERED_CATEGORIES, CATEGORY_FOUND, CATEGORY_NOT_FOUND, GET_CATEGORY, REMOVE_CATEGORY, DELETE_CATEGORY
} from '../types';
import { formatCategories, formatSingleCategory } from '../../shared/formatter/categoryFormatter'

export const sortCategories = (items) => async dispatch => {
    try {
        const resp = await axios.post(`${apiUrl}category-sequence/`, { sequence: items })
        if (resp) {
            const res = await axios.get(`${apiUrl}categories/`)
            let data = formatCategories(res.data.results)
            dispatch({
                type: CATEGORY_FOUND,
                payload: {
                    categoriesList: data && data.length > 0 ? data : 0,
                },
            });
            return true;
        }
    } catch {
        dispatch({
            type: CATEGORY_NOT_FOUND,
        });
        return false;
    }
}

export const getAllCategories = () => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}categories/?limit=250`)
        let data = formatCategories(res.data.results)
        dispatch({
            type: CATEGORY_FOUND,
            payload: {
                categoriesList: data && data.length > 0 ? data : 0,
            },
        });
        return true;
    } catch {
        dispatch({
            type: CATEGORY_NOT_FOUND,
        });
        return false;
    }
}

export const getCategoriesList = () => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}categories/?limit=250`)
        let data = formatCategories(res.data.results)
        dispatch({
            type: CATEGORY_FOUND,
            payload: {
                categoriesList: data && data.length > 0 ? data : 0,
            },
        });
        return true;
    } catch {
        dispatch({
            type: CATEGORY_NOT_FOUND,
        });
        return false;
    }
}

export const getFilteredCategories = (query) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}categories/?search=${query}`)
        let data = formatCategories(res.data.results)
        dispatch({
            type: GET_FILTERED_CATEGORIES,
            payload: {
                filteredCategory: data && data.length > 0 ? data : 0,
            },
        });
        return true;
    } catch {
        dispatch({
            type: CATEGORY_NOT_FOUND,
        });
        return false;
    }
}

export const getCategory = (id) => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl}categories/${id}/`)
        let data = formatSingleCategory(res.data)
        dispatch({
            type: GET_CATEGORY,
            payload: data
        });
        return true;
    } catch {
        dispatch({
            type: REMOVE_CATEGORY,
        });
        return false;
    }
}

export const updateCategory = (formData) => async () => {
    // let data = new FormData();

    // if (pictures) {
    //     formData.image = pictures[0]
    //     console.log(formData.image);
    // } else {
    //     delete formData.image
    // }

    // for (var field in formData) {
    //     data.set(field, formData[field]);
    // }

    try {
        await axios.put(`${apiUrl}categories/${formData.uid}/`, formData);
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const addCategory = (formData) => async () => {
    let body = formData
    try {
        await axios.post(`${apiUrl}categories/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const deleteCategory = (id) => async dispatch => {
    try {
        await axios.delete(`${apiUrl}categories/${id}/`);
        dispatch({
            type: DELETE_CATEGORY,
            payload: id
        })
        return true;
    } catch {
        return false;
    }
}