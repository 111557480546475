import {
    ERROR
} from '../types'

const initialState = {
    errors: null,
}

const page = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case ERROR:
            return {
                ...state,
                errors: payload
            }
        default:
            return state
    }

}

export default page