import React from "react"

import dashbaord from '../../../assets/images/dashboard.png'
import order from '../../../assets/images/order.png'
import category from '../../../assets/images/category.png'
import product from '../../../assets/images/product.png'
import modifier from '../../../assets/images/modifier.png'
import voucher from '../../../assets/images/voucher.png'
import setting from '../../../assets/images/setting.png'

export const items = [
    {
        link: '/', name: 'Dashboard',
        icon: <img src={dashbaord} alt='dashbaord' />,
    },
    {
        link: '/categories', name: 'Categories', includes: 'category',
        icon: <img src={category} alt='category' />,
    },
    {
        link: '/products', name: 'Products', includes: 'product',
        icon: <img src={product} alt='product' />,
    },
    {
        link: '/modifiers', name: 'Modifiers', includes: 'modifier',
        icon: <img src={modifier} alt='modifier' />,
    },
    {
        link: '/orders', name: 'Orders',
        icon: <img src={order} alt='order' />,
    },
    {
        link: '/vouchers', name: 'Vouchers', includes: ('voucher' || 'offer' || 'benefit' || 'condition' || 'range'),
        icon: <img src={voucher} alt='voucher' />,
    },
    {
        link: '/settings', name: 'Settings', includes: 'edit-business',
        icon: <img src={setting} alt='setting' />,
    },
]