import axios from 'axios';
import { apiUrl } from "../../shared/utils/fetcher";
import {
    BENEFITS_FOUND, BENEFITS_NOT_FOUND,
    GET_BENEFIT, REMOVE_BENEFIT,
    DELETE_BENEFIT
} from '../types'
import { formatBenefits, formatSingleBenefit } from '../../shared/formatter/benefitFormatter'

let benefitUrl = `${apiUrl}/me/benefit`

export const getAllBenefits = () => async dispatch => {
    try {
        const res = await axios.get(`${benefitUrl}/?limit=200`)
        let data = formatBenefits(res.data.results)
        dispatch({
            type: BENEFITS_FOUND,
            payload: {
                benefitsList: data && data.length > 0 ? data : 0,
                benefitsCount: res.data.count
            }
        });
        return true;
    } catch {
        dispatch({
            type: BENEFITS_NOT_FOUND,
        });
        return false;
    }
}

export const getBenefitsList = (page) => async dispatch => {
    try {
        const res = await axios.get(`${benefitUrl}/?page=${page ? page : 1}`)
        let data = formatBenefits(res.data.results)
        dispatch({
            type: BENEFITS_FOUND,
            payload: {
                benefitsList: data && data.length > 0 ? data : 0,
                benefitsCount: res.data.count
            }
        });
        return true;
    } catch {
        dispatch({
            type: BENEFITS_NOT_FOUND,
        });
        return false;
    }
}

export const getBenefitDetail = (id) => async dispatch => {
    try {
        const res = await axios.get(`${benefitUrl}/${id}/`)
        let data = formatSingleBenefit(res.data)
        dispatch({
            type: GET_BENEFIT,
            payload: data
        });
        return true;
    } catch {
        dispatch({
            type: REMOVE_BENEFIT,
        });
        return false;
    }
}

export const addBenefit = (formData) => async dispatch => {
    let body = formData
    try {
        await axios.post(`${benefitUrl}/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const updateBenefit = (formData, id) => async dispatch => {
    let body = formData
    try {
        await axios.put(`${benefitUrl}/${id}/`, body)
        return true;
    } catch (error) {
        console.debug(error)
        return false;
    }
}

export const deleteBenefit = (id) => async dispatch => {
    try {
        await axios.delete(`${benefitUrl}/${id}/`)
        dispatch({
            type: DELETE_BENEFIT,
            payload: id
        })
        return true;
    } catch {
        return false
    }
}


