import React, { useEffect, useState } from "react"
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import OptionsForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { useStyles } from "../../../assets/jss/pages/modifiers"
import ContentChecker from '../../../shared/common/ContentChecker'
import { formNavigation } from '../../../functions/navigate'
import { updateModifierOptions, getModifierGroup, getModifierOption } from "../../../redux/actions/modifier";
import { getAllProducts } from "../../../redux/actions/product";
import { setPage } from '../../../redux/actions/page'
import { initialState } from "./State"

const EditModifierOption = ({
    updateModifierOptions, getModifierGroup, getModifierOption, getAllProducts, setPage,
    modifier: { modifierOptions },
    product: { productsList }
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let { groupId, optionsId } = useParams();

    useEffect(() => {
        if (optionsId) {
            getModifierOption(optionsId)
        }
        // eslint-disable-next-line
    }, [getModifierOption])

    const [formData, setFormData] = useState(initialState)
    let group = modifierOptions ? modifierOptions?.group_id : groupId

    useEffect(() => {
        getAllProducts()
    }, [getAllProducts])

    useEffect(() => {
        if (groupId) {
            getModifierGroup(groupId)
        }
        // eslint-disable-next-line
    }, [getModifierGroup, groupId])

    useEffect(() => {
        if (modifierOptions) {
            setFormData({
                ...formData,
                uid: modifierOptions?.uid,
                group: parseInt(modifierOptions ? modifierOptions?.group_id : groupId),
                product: modifierOptions?.name,
                price: modifierOptions?.price,
            })
        }
        // eslint-disable-next-line
    }, [modifierOptions])

    const onSubmit = e => {
        e.preventDefault()

        let productIdString = productsList?.filter(product => product.title === formData.product)
        formData.product = productIdString?.length > 0 && productIdString[0]?.uid

        updateModifierOptions(formData, alert)
            .then((success) => {
                if (success) {
                    alert.show(`Modifier options updated successfully`, { type: "success" })
                    formNavigation(history, setPage, 'modifier-group', group, 'edit')
                }
            })
    }

    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='modifier options' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {productsList ?
                    (formData.uid && optionsId && modifierOptions) ?
                        <OptionsForm formData={formData} setFormData={setFormData} onSubmit={onSubmit} />
                        :
                        <ContentChecker reducerName={modifierOptions} />
                    :
                    <ContentChecker reducerName={productsList} />
                }
            </Container>
        </Container>
    )
}

EditModifierOption.propTypes = {
    updateModifierOptions: PropTypes.func.isRequired,
    getModifierGroup: PropTypes.func.isRequired,
    getModifierOption: PropTypes.func.isRequired,
    getAllProducts: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    modifier: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    updateModifierOptions, getModifierGroup, getModifierOption, getAllProducts, setPage
})(EditModifierOption)
