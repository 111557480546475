import axios from 'axios';
import { apiUrl } from "../../shared/utils/fetcher";
import {
    RANGE_FOUND, RANGE_NOT_FOUND,
    GET_RANGE, REMOVE_RANGE,
    DELETE_RANGE
} from '../types'

let rangeUrl = `${apiUrl}/me/range`

export const getAllRanges = () => async dispatch => {
    try {
        const res = await axios.get(`${rangeUrl}/?limit=200`)
        let data = res.data.results
        dispatch({
            type: RANGE_FOUND,
            payload: {
                rangesList: data && data.length > 0 ? data : 0,
                rangesCount: res.data.count
            }
        });
        return true;
    } catch {
        dispatch({
            type: RANGE_NOT_FOUND,
        });
        return false;
    }
}

export const getRangesList = (page) => async dispatch => {
    try {
        const res = await axios.get(`${rangeUrl}/?page=${page ? page : 1}`)
        let data = res.data.results
        dispatch({
            type: RANGE_FOUND,
            payload: {
                rangesList: data && data.length > 0 ? data : 0,
                rangesCount: res.data.count
            }
        });
        return true;
    } catch {
        dispatch({
            type: RANGE_NOT_FOUND,
        });
        return false;
    }
}

export const getRangeDetail = (id) => async dispatch => {
    try {
        const res = await axios.get(`${rangeUrl}/${id}/`)
        let data = res.data
        dispatch({
            type: GET_RANGE,
            payload: data
        });
        return true;
    } catch {
        dispatch({
            type: REMOVE_RANGE,
        });
        return false;
    }
}

export const addRange = (formData, alert) => async dispatch => {
    let body = formData
    try {
        await axios.post(`${rangeUrl}/`, body)
        return true;
    } catch (error) {
        let err = error.response
        if (err?.status === 500) {
            alert.show('Cannot update range. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
        return false;
    }
}

export const updateRange = (formData, id, alert) => async dispatch => {
    let body = formData
    try {
        await axios.put(`${rangeUrl}/${id}/`, body)
        return true;
    } catch (error) {
        let err = error.response
        if (err?.status === 500) {
            alert.show('Cannot update range. Please try again later', { type: "error" })
        }
        else {
            alert.show(`${err?.data?.non_field_errors}`, { type: "error" })
        }
        return false;
    }
}

export const deleteRange = (id) => async dispatch => {
    try {
        await axios.delete(`${rangeUrl}/${id}/`)
        dispatch({
            type: DELETE_RANGE,
            payload: id
        })
        return true;
    } catch {
        return false
    }
}

