import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import useStyles from '../../../assets/jss/pages/voucher'
import ContentChecker from '../../../shared/common/ContentChecker'
import BenefitForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { showAlertFunc } from '../../../functions/showAlert';
import { setPage } from '../../../redux/actions/page'
import { getAllRanges } from '../../../redux/actions/range'
import { getBenefitDetail, updateBenefit } from '../../../redux/actions/benefit'
import { initialState } from './State'

const EditBenefit = ({
    getBenefitDetail, updateBenefit, getAllRanges, setPage,
    benefit: { benefitDetail },
    range: { rangesList },
}) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert();
    let id = window.location.pathname.split('/')[2]

    useEffect(() => {
        getAllRanges(1)
        if (id) {
            getBenefitDetail(id)
        }
    }, [getAllRanges, getBenefitDetail, id])

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (benefitDetail) {
            setFormData({
                ...formData,
                id: benefitDetail?.id,
                type: benefitDetail?.type,
                value: benefitDetail?.value,
                range: benefitDetail?.range,
            })
        }
        // eslint-disable-next-line
    }, [benefitDetail])

    const onSubmit = e => {
        e.preventDefault()

        updateBenefit(formData, benefitDetail && benefitDetail.id)
            .then((success) => {
                if (success) {
                    alert.show(`Benefit updated successfully`, { type: "success" })
                }
                else {
                    alert.show('Cannot update benefit. Please try again later', { type: "error" })
                }
            });
    }

    return (
        <Container maxWidth="xl">
            <CrudHeader name='benefit' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {rangesList ?
                    (formData?.id && id && benefitDetail) ?
                        <BenefitForm
                            formData={formData}
                            setFormData={setFormData}
                            onSubmit={onSubmit}
                        />
                        :
                        <ContentChecker reducerName={benefitDetail} />
                    :
                    <ContentChecker reducerName={rangesList} />
                }
                {rangesList === 0 && showAlertFunc('Do add range before adding benefits', history, setPage, 'range')}
            </Container>
        </Container>
    )
}

EditBenefit.propTypes = {
    getAllRanges: PropTypes.func.isRequired,
    getBenefitDetail: PropTypes.func.isRequired,
    updateBenefit: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    benefit: PropTypes.object.isRequired,
    range: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getBenefitDetail, updateBenefit, getAllRanges, setPage })(EditBenefit)