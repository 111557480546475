import { makeStyles } from '@material-ui/core/styles';
import { dangerColor, contrastColor, whiteColor } from '../../colors'

const useStyles = makeStyles(theme => ({
    edit: {
        backgroundColor: contrastColor,
        color: whiteColor,
        padding: theme.spacing(1, 2.5),
        margin: theme.spacing(1, 0.5),
        "&:hover": {
            backgroundColor: contrastColor,
            color: whiteColor,
            opacity: 0.7,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0.5),
            margin: theme.spacing(0.1),
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(1),
            margin: theme.spacing(0.1),
        },
    },
    cancel: {
        padding: theme.spacing(1, 2.5),
        backgroundColor: dangerColor,
        color: whiteColor,
        margin: theme.spacing(1, 0.5),
        "&:hover": {
            backgroundColor: dangerColor,
            color: whiteColor,
            opacity: 0.7,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0.5),
            margin: theme.spacing(0.1),
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(1),
            margin: theme.spacing(0.1),
        },
    },
}));

export default useStyles
