import React from "react";
import CustomRoute from "../routing/CustomRoute";
import { Switch } from "react-router-dom";
import { routes } from './RouteComponents'
import Signin from "../layouts/Signin";
import MainApp from "../layouts/Layout";

const Routes = () => {
	return (
		<Switch>
			{routes && routes.map((route, index) => (
				<CustomRoute exact path={route.path} key={index} component={route.path === "/signin" ? Signin : MainApp} />
			))}
		</Switch>
	)
};

export default Routes;