import {
    SET_PAGE
} from '../types'

const initialState = {
    loadedPage: null,
}

const page = (state = initialState, action) => {
    const { type, name } = action

    switch (type) {
        case SET_PAGE:
            return {
                ...state,
                loadedPage: name ? name.replace('/', '') : window.location.pathname.replace('/', '')
            }
        default:
            return state
    }

}

export default page