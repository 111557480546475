import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useAlert } from "react-alert"
import { mapStateToProps } from "../../shared/common/MapStateToProps"
import { Grid, InputLabel, TextField } from "@material-ui/core/"
import Buttons from "../../components/common/Buttons"
import ImageUploader from "react-images-upload"
import Autocomplete from "react-google-autocomplete"
import { onChange } from "../../functions/onChange"
import useStyles from "../../assets/jss/components/material/inputFields"
import { navigate } from "../../functions/navigate"
import { setPage } from "../../redux/actions/page"
import { updateBusinessInfo } from "../../redux/actions/settings"
import { verifyUser } from "../../redux/actions/auth"

const BusinessHoursForm = ({ verifyUser, updateBusinessInfo, setPage, auth: { me } }) => {
	const classes = useStyles()
	const history = useHistory()
	const alert = useAlert()

	const [pictures, setPictures] = useState()

	const onDrop = (picture) => {
		setPictures(picture) // && picture[0] && picture[0].name
	}

	const [formData, setFormData] = useState({
		name: me && me.name ? me.name : "",
		description: me && me.description ? me.description : "",
		address: {
			place: me && me.address ? me.address.place : "",
			place_id: me && me.address ? me.address.place_id : "",
			postcode: me && me.address ? me.address.postcode : "",
			location: {
				longitude: me && me.address ? me.address.location.longitude : "",
				latitude: me && me.address ? me.address.location.latitude : "",
			},
		},
		image: me && me.image ? me.image : "",
		tags: me && me.tags,
	})
	let storeAddress
	let postal_code

	const { name, description, address } = formData

	useEffect(() => {
		verifyUser()
	}, [verifyUser])

	const onPlaceSelected = (place, address) => {
		console.debug("address", place)
		place.address_components.map((data) => {
			if (data.types[0] === "postal_code") {
				postal_code = data.long_name ? data.long_name : me && me.address.postcode
				return postal_code
			}
			return postal_code
		})
		storeAddress = {
			place: place.formatted_address,
			place_id: place.place_id,
			latitude: place.geometry.location.lat(),
			longitude: place.geometry.location.lng(),
			postcode: postal_code,
		}
		if (address && storeAddress) {
			address.place = storeAddress.place ? storeAddress.place : me && me.address.place
			address.place_id = storeAddress.place_id ? storeAddress.place_id : me && me.address.place_id
			address.location.latitude = storeAddress.latitude ? storeAddress.latitude : me && me.address.location.latitude
			address.location.longitude = storeAddress.longitude ? storeAddress.longitude : me && me.address.location.longitude
			address.postcode = storeAddress.postcode ? storeAddress.postcode : me && me.address.postcode
		}
		console.debug("address", storeAddress)
		return storeAddress
	}

	const onCancel = () => {
		navigate(history, setPage, "settings")
	}

	const onSubmit = async (e) => {
		e.preventDefault()
		let data = new FormData()
		if (pictures) {
			formData.image = pictures[0]
			console.log(formData.image)
		} else {
			delete formData.image
		}

		console.log("FORM DATA: ", formData)

		for (var field in formData) {
			data.set(field, formData[field])
		}

		updateBusinessInfo(data).then((success) => {
			if (success) {
				alert.show("Form updated successfully", { type: "success" })
				history.push("/settings")
				setPage("settings")
				verifyUser()
			} else {
				alert.show("Could not update the business information. Please try again later", { type: "error" })
			}
		})
	}

	const fields = [
		{ label: "Restaurant Name", name: "name", value: name, type: "text", required: true },
		{ label: "Short Description", name: "description", value: description, type: "text", multiline: true },
	]

	return (
		<form onSubmit={(e) => onSubmit(e)}>
			<Grid container spacing={5}>
				<Grid item xs={12} sm={8} md={9}>
					{fields &&
						fields.map((field, index) => (
							<div key={index}>
								<InputLabel className={classes.label}>{field.label}</InputLabel>
								<TextField
									variant="outlined"
									style={{ background: "#fff" }}
									fullWidth
									type={field.type ? field.type : "text"}
									name={field.name}
									value={field.value}
									placeholder={field.label}
									onChange={(e) => onChange(e, formData, setFormData)}
									required
									// InputProps={{ disableUnderline: true }}
									multiline={field.multiline ? true : false}
								/>
							</div>
						))}
				</Grid>
				<Grid item xs={12} sm={4} md={3}>
					<center>
						{me && me.image && (
							<img
								width="124px"
								height="124px"
								src={me && me.image}
								alt="Logo"
								style={{ borderRadius: "50%", alignSelf: "center", marginTop: "12%" }}
							/>
						)}
					</center>
				</Grid>
				<Grid item xs={12}>
					<InputLabel className={classes.label}>Address</InputLabel>
					<Autocomplete
						style={{
							border: "1px solid #c5c5c5",
							width: "100%",
							padding: "1rem",
							lineHeight: "19px",
							fontSize: "16px",
							fontFamily: "Helvetica",
							"&:hover": {
								border: "1px solid #c5c5c5",
							},
							"&:active": {
								border: "1px solid #c5c5c5",
							},
							background: "#FFFFFF",
						}}
						label="Address"
						placeholder={me && me.address ? me.address.place : address.place}
						onPlaceSelected={(place) => onPlaceSelected(place, address)}
						defaultValue={storeAddress ? storeAddress : me && me.address.place}
						types={["(regions)"]}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<ImageUploader
						className={classes.input}
						withIcon={true}
						onChange={onDrop}
						imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
						maxFileSize={5242880}
						withPreview={true}
						accept="accept=image/*"
						singleImage={true}
						required
						errorClass={!pictures ? "This field is required" : ""}
					/>
				</Grid>

				<br />
			</Grid>
			<Buttons onCancel={onCancel} />
		</form>
	)
}

BusinessHoursForm.propTypes = {
	updateBusinessInfo: PropTypes.func.isRequired,
	verifyUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { updateBusinessInfo, verifyUser, setPage })(BusinessHoursForm)
