import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Typography, TextField, Grid } from "@material-ui/core/"
import Buttons from '../../components/common/Buttons'
import useStyles from '../../assets/jss/pages/settings'
import { navigate } from '../../functions/navigate'
import { onChange } from '../../functions/onChange'
import { setPage } from "../../redux/actions/page";
import { updateBusinessHours } from '../../redux/actions/settings'
import { verifyUser } from "../../redux/actions/auth";

const BusinessHoursForm = ({
    verifyUser, updateBusinessHours, setPage,
    auth: { me }
}) => {
    const classes = useStyles()
    const alert = useAlert()
    const history = useHistory();

    let timer = (day) => {
        let val;
        me && me.opening_hours && me.opening_hours.filter((key) => {
            if (key.weekday === day) {
                val = key
            }
            return val
        })
        return val
    }

    let monday = timer(1) ? timer(1) : []
    let tuesday = timer(2) ? timer(2) : []
    let wednesday = timer(3) ? timer(3) : []
    let thursday = timer(4) ? timer(4) : []
    let friday = timer(5) ? timer(5) : []
    let saturday = timer(6) ? timer(6) : []
    let sunday = timer(7) ? timer(7) : []

    const [formData, setFormData] = useState({
        partner: me && me.id,
        monStart: monday.start ? monday.start : '',
        monEnd: monday.end ? monday.end : '',
        tuesStart: tuesday.start ? tuesday.start : '',
        tuesEnd: tuesday.end ? tuesday.end : '',
        wedStart: wednesday.start ? wednesday.start : '',
        wedEnd: wednesday.end ? wednesday.end : '',
        thursStart: thursday.start ? thursday.start : '',
        thursEnd: thursday.end ? thursday.end : '',
        friStart: friday.start ? friday.start : '',
        friEnd: friday.end ? friday.end : '',
        satStart: saturday.start ? saturday.start : '',
        satEnd: saturday.end ? saturday.end : '',
        sunStart: sunday.start ? sunday.start : '',
        sunEnd: sunday.end ? sunday.end : '',
    });

    const { monStart, monEnd, tuesStart, tuesEnd, wedStart, wedEnd, thursStart, thursEnd, friStart, friEnd, satStart, satEnd, sunStart, sunEnd } = formData;

    const days = [
        { day: 'Monday', valueStart: monStart, nameStart: 'monStart', valueEnd: monEnd, nameEnd: 'monEnd' },
        { day: 'Tuesday', valueStart: tuesStart, nameStart: 'tuesStart', valueEnd: tuesEnd, nameEnd: 'tuesEnd' },
        { day: 'Wednesday', valueStart: wedStart, nameStart: 'wedStart', valueEnd: wedEnd, nameEnd: 'wedEnd' },
        { day: 'Thursday', valueStart: thursStart, nameStart: 'thursStart', valueEnd: thursEnd, nameEnd: 'thursEnd' },
        { day: 'Friday', valueStart: friStart, nameStart: 'friStart', valueEnd: friEnd, nameEnd: 'friEnd' },
        { day: 'Saturday', valueStart: satStart, nameStart: 'satStart', valueEnd: satEnd, nameEnd: 'satEnd' },
        { day: 'Sunday', valueStart: sunStart, nameStart: 'sunStart', valueEnd: sunEnd, nameEnd: 'sunEnd' },
    ]

    useEffect(() => {
        verifyUser()
    }, [verifyUser])

    const onCancel = () => {
        navigate(history, setPage, 'settings')
    }

    const onSubmit = e => {
        e.preventDefault()
        let body = {
            timings: [
                {
                    weekday: 1,
                    start: formData && formData.monStart && formData.monStart.length === 8 ? formData.monStart : formData.monStart + ':00',
                    end: formData && formData.monEnd && formData.monEnd.length === 8 ? formData.monEnd : formData.monEnd + ':00'
                },
                {
                    weekday: 2,
                    start: formData && formData.tuesStart && formData.tuesStart.length === 8 ? formData.tuesStart : formData.tuesStart + ':00',
                    end: formData && formData.tuesEnd && formData.tuesEnd.length === 8 ? formData.tuesEnd : formData.tuesEnd + ':00'
                },
                {
                    weekday: 3,
                    start: formData && formData.wedStart && formData.wedStart.length === 8 ? formData.wedStart : formData.wedStart + ':00',
                    end: formData && formData.wedEnd && formData.wedEnd.length === 8 ? formData.wedEnd : formData.wedEnd + ':00'
                },
                {
                    weekday: 4,
                    start: formData && formData.thursStart && formData.thursStart.length === 8 ? formData.thursStart : formData.thursStart + ':00',
                    end: formData && formData.thursEnd && formData.thursEnd.length === 8 ? formData.thursEnd : formData.thursEnd + ':00'
                },
                {
                    weekday: 5,
                    start: formData && formData.friStart && formData.friStart.length === 8 ? formData.friStart : formData.friStart + ':00',
                    end: formData && formData.friEnd && formData.friEnd.length === 8 ? formData.friEnd : formData.friEnd + ':00'
                },
                {
                    weekday: 6,
                    start: formData && formData.satStart && formData.satStart.length === 8 ? formData.satStart : formData.satStart + ':00',
                    end: formData && formData.satEnd && formData.satEnd.length === 8 ? formData.satEnd : formData.satEnd + ':00'
                },
                {
                    weekday: 7,
                    start: formData && formData.sunStart && formData.sunStart.length === 8 ? formData.sunStart : formData.sunStart + ':00',
                    end: formData && formData.sunEnd && formData.sunEnd.length === 8 ? formData.sunEnd : formData.sunEnd + ':00'
                },
            ]
        }
        updateBusinessHours(body)
            .then(success => {
                if (success) {
                    alert.show('Form updated successfully', { type: "success" })
                    history.push('/settings')
                    setPage("settings")
                    verifyUser()
                }
                else {
                    alert.show('Could not update the business hours. Please try again later', { type: "error" })
                }
            })
    }


    return (
        <form onSubmit={(e) => onSubmit(e)}>
            {days && days.map((day, index) => (
                <Grid container spacing={2} key={index}>
                    <Grid item xs={12} sm={3}>
                        <Typography className={classes.day}>{day.day}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label=" "
                            variant="filled"
                            fullWidth
                            defaultValue={day.valueStart}
                            name={day.nameStart}
                            onChange={(e) => onChange(e, formData, setFormData)}
                            type="time"
                            style={{ background: 'white' }}
                            error={!day.valueStart}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Typography className={classes.to}>to</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label=" "
                            variant="filled"
                            fullWidth
                            style={{ background: '#ffffff' }}
                            value={day.valueEnd}
                            name={day.nameEnd}
                            onChange={(e) => onChange(e, formData, setFormData)}
                            type="time"
                            error={!day.valueEnd}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <hr />
                    </Grid>
                </Grid>
            ))}
            <br />
            <Buttons onCancel={onCancel} />
        </form>
    )
}

BusinessHoursForm.propTypes = {
    updateBusinessHours: PropTypes.func.isRequired,
    verifyUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { updateBusinessHours, verifyUser, setPage })(BusinessHoursForm)