import { makeStyles } from '@material-ui/core/styles';
import { blackColor, primaryColor, whiteColor, contrastColor } from '../../colors';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        maxHeight: '56px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    bg: {
        marginLeft: theme.spacing(-5),
        background: contrastColor,
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(-4),
        }
    },
    menuButton: {
        color: primaryColor,
        alignSelf: 'center',
        margin: theme.spacing(-0.3, 0, 0, 2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(-0.5, 0, 0, 1),
        }
    },
    hide: {
        display: 'none',
    },
    menuOpen: {
        color: primaryColor,
        alignSelf: 'center',
        margin: theme.spacing(0, 0, 0, 2),
    },
    avatar: {
        fontSize: '10px',
        borderRadius: '50%',
        marginRight: theme.spacing(1),
        width: '30px',
        height: '30px',
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(0.5),
        },
    },
    text: {
        clear: 'both',
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        justifyContent: 'flex-end',
        fontSize: '14px',
        color: blackColor,
        opacity: 0.8
    },
    icon: {
        color: blackColor,
        opacity: 0.8
    },
    dropdownItem: {
        listStyle: 'none',
        content: "",
        "&:hover": {
            background: contrastColor,
            color: whiteColor
        },
    }
}));

export default useStyles
