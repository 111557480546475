import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAlert } from "react-alert"
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Container } from "@material-ui/core/"
import GroupForm from './Form'
import CrudHeader from '../../../components/common/CrudHeader'
import { useStyles } from "../../../assets/jss/pages/modifiers"
import ContentChecker from '../../../shared/common/ContentChecker'
import {
    getModifierGroup, updateModifierGroup
} from "../../../redux/actions/modifier";
import { initialState } from "./State";

const EditModifierGroup = ({
    getModifierGroup, updateModifierGroup,
    modifier: { modifierGroup },
}) => {
    const classes = useStyles()
    const alert = useAlert();
    let { uid } = useParams();

    useEffect(() => {
        if (uid) {
            getModifierGroup(uid)
        }
    }, [getModifierGroup, uid])

    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (modifierGroup) {
            setFormData({
                ...formData,
                uid: modifierGroup?.uid,
                name: modifierGroup?.name,
                limit: modifierGroup?.limit,
                required: modifierGroup?.required,
                type: modifierGroup?.type === "CHECKBOX" ? true : false,
            })
        }
        // eslint-disable-next-line
    }, [modifierGroup])

    const onSubmit = (e) => {
        e.preventDefault()

        formData.required = formData.required === true ? true : false
        formData.type = formData.type === true ? "CHECKBOX" : "RADIO"

        updateModifierGroup(formData)
            .then((success) => {
                if (success) {
                    alert.show('Modifier group updated successfully', { type: "success" })
                }
                else {
                    alert.show('Could not update the modifier group. Please try again later', { type: "error" })
                }
            });
    }


    return (
        <Container maxWidth="xl" className={classes.root}>
            <CrudHeader name='modifier group' />
            <Container maxWidth="xl" elevation={3} className={classes.container}>
                {
                    (formData.uid && uid && modifierGroup) ?
                        <GroupForm formData={formData} setFormData={setFormData} onSubmit={onSubmit} />
                        :
                        <ContentChecker reducerName={modifierGroup} />
                }
            </Container>
        </Container>
    )
}

EditModifierGroup.propTypes = {
    getModifierGroup: PropTypes.func.isRequired,
    updateModifierGroup: PropTypes.func.isRequired,
    modifier: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getModifierGroup, updateModifierGroup })(EditModifierGroup)