import React from "react"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, InputLabel } from "@material-ui/core/"
import useStyles from '../../../assets/jss/components/material/inputFields'

const AutocompleteField = (props) => {
    const classes = useStyles()
    const { name, label, single, options, value, required, disabled, onChange } = props

    return (
        <>
            <InputLabel className={classes.label}>{label}</InputLabel>
            <Autocomplete
                multiple={single === true ? false : true}
                className={classes.input2}
                name={name}
                options={options}
                defaultValue={value ? value : []}
                required={required ? true : false}
                filterSelectedOptions
                onChange={onChange}
                disabled={disabled ? true : false}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        name={name}
                        placeholder={label}
                    />
                )}
            />
        </>
    )
}

export default AutocompleteField