import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core"
import { navigate } from '../../functions/navigate'
// import ImageField from "../../components/material/inputFields/ImageField";
import InputField from '../../components/material/inputFields/InputField'
import { onChange } from '../../functions/onChange'
import Buttons from '../../components/common/Buttons'
import { setPage } from '../../redux/actions/page'

const CategoryForm = ({
    formData, setFormData, onSubmit, setPage,
}) => {
    const history = useHistory()

    const onCancel = () => {
        navigate(history, setPage, 'categories')
    }

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <InputField
                        name='name'
                        label='Name'
                        value={formData?.name}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='text'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        name='description'
                        label='Description'
                        value={formData?.description}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        type='text'
                    />
                </Grid>
                {/* {categoryDetail && categoryDetail.image &&
                    <>
                        <Grid item xs={12}>
                            <Typography style={{ color: '#707070' }}>Current Image</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <center>
                                <img src={categoryDetail.image} alt={categoryDetail.name} style={{ width: 120, height: 120 }} />
                            </center>
                        </Grid>
                    </>
                }
                {loadedPage === 'edit-category' && <ImageField onDrop={onDrop} single={true} />} */}

            </Grid>
            <br />
            <Buttons onCancel={onCancel} />
        </form>
    )
}

CategoryForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    // page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(CategoryForm)