import React from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../../shared/common/MapStateToProps'
import { Grid } from "@material-ui/core"
import SelectField from "../../../components/material/inputFields/SelectField";
import InputField from '../../../components/material/inputFields/InputField'
import Buttons from '../../../components/common/Buttons'
import { onChange } from '../../../functions/onChange'
import { navigate } from '../../../functions/navigate'
import { setPage } from '../../../redux/actions/page'

const BenefitForm = ({
    formData, setFormData, onSubmit, setPage,
    range: { rangesList },
}) => {
    const history = useHistory()

    const onCancel = () => {
        navigate(history, setPage, 'benefits')
    }

    let opt = [
        { id: 'Percentage', name: `Discount is a percentage off of the product's value` },
        { id: "Absolute", name: `Discount is a fixed amount off of the product's value` },
        { id: 'Multibuy', name: 'Discount is to give the cheapest product for free' },
        { id: 'Shipping absolute', name: 'Discount is a fixed amount of the shipping cost' },
        { id: 'Shipping fixed price', name: 'Get shipping for a fixed price' },
        { id: 'Shipping percentage', name: 'Discount is a percentage off of the shipping cost' },
    ]

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <SelectField
                        name='type'
                        label='Type'
                        value={formData?.type}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={opt}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectField
                        name='range'
                        label='Range'
                        value={formData?.range}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        options={rangesList}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputField
                        name='value'
                        label='Value'
                        value={formData?.value}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        required={true}
                        type='text'
                    />
                </Grid>

            </Grid>
            <br />
            <Buttons onCancel={onCancel} />
        </form>
    )
}

BenefitForm.propTypes = {
    setPage: PropTypes.func.isRequired,
    benefit: PropTypes.object.isRequired,
    range: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setPage })(BenefitForm)