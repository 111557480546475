import axios from "axios"
import { apiUrl } from "../../shared/utils/fetcher"

export const updateBusinessInfo = (body) => async () => {
	try {
		await axios.put(`${apiUrl}me/`, body)
		return true
	} catch {
		return false
	}
}

export const updateBusinessHours = (body) => async () => {
	try {
		await axios.post(`${apiUrl}me/openinghours/`, body)
		return true
	} catch {
		return false
	}
}
